import React, {Component} from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import * as qiniu from 'qiniu-js';
import {request} from './../../../static/js/utils';
import './Editor.scss';
import apis from './../../../static/js/apis';
const api = apis.utilApis;

const getqiniuToken = '/admin/getqiniuToken'; //获取七牛token


class Editor extends Component{
    constructor(props){
        super(props);
        this.state={
            editorState:BraftEditor.createEditorState(null),
            conStyle:null,
            isFull:false,
            jsonData:null
        
        }
    }
    submit=()=>{
        const {editorState} = this.props;
        let rawJson = editorState.toRAW(true);
        const {blocks, entityMap} = rawJson;
        const jsonData = [];
        let a = 0;
        let index = 0;
        for(let i in blocks){
            let type = '';
            if(blocks[i]['type'] === "unstyled"){
                type = 'text';
            }else if(blocks[i]['type'] === "header-one"){
                type = 'head';
            }else if (blocks[i]['type'] === "atomic"){
                type = 'img'
            }
            if(blocks[i]['text']){
                jsonData[index] = {};
                jsonData[index]['content'] = blocks[i]['text'];
                jsonData[index]['type'] = type;
                index++;
            }
        }
        for(let i=0;i<jsonData.length;i++){
            if(jsonData[i].type === "img"){
                jsonData[i]['content'] = entityMap[a].data.url;
                a++;
            }
        }
        this.props.onGet(JSON.stringify(rawJson),JSON.stringify(jsonData));
    }
    handleChange=(editorState)=>{
        // let rawJson = editorState.toRAW(true);
        this.props.onChange(editorState);
    }
    myUploadFn= async (params) =>{
        const {file} = params;
        const {data} = await request(getqiniuToken, {filename:file.name}, 'post');
        const {filename, token} = data;
        const blob = new Blob([file]);
        const observable = qiniu.upload(blob,filename,token,{fname:file.name,params:{},mimeType:null}, {useCdnDomain:true});
        this.subscription = observable.subscribe({
            next:(res)=>{
                const {percent} = res.total;
                params.progress(percent);
            },
            error:(res)=>{
                
            },
            complete:(res)=>{
                params.success({
                    url:'https://m.muho.tv/'+res.key
                })
            }
        });
    }
    render(){
        const { conStyle, isFull} = this.state;
        let { show, phoneData, editorState } = this.props;
        if( typeof editorState === 'string'){
            editorState = BraftEditor.createEditorState(editorState);
        }
        if (typeof phoneData === 'string' && phoneData){
            phoneData = JSON.parse(phoneData);
        }
        return(
            <div className="edit_container">
                {
                    show ? 
                    <div className="edit_wrap" style={conStyle}>
                        <div className="edit_main">
                            <div className="edit_con">
                                <BraftEditor
                                    value={editorState}
                                    placeholder="输入完成后ctrl+s即可保存文案和即时预览"
                                    className="editorSty"
                                    controls={["headings","media"]}
                                    onChange={this.handleChange}
                                    onSave={this.submit}
                                    media={{uploadFn:this.myUploadFn}}
                                    extendControls={[
                                        {key:'myB',type:'button',title:'退出富文本',className:"cancelBtn",html:'关闭',onClick:()=>{
                                            this.props.onCancel();
                                            this.setState({
                                                conStyle:null,
                                                isFull:false
                                            })
                                        }},
                                        {key:'fullscreen',type:'button',title:`${isFull ? '退出全屏' :'全屏'}`,className:"cancelBtn",text:`${isFull ? '退出全屏' :'全屏'}`,onClick:()=>{
                                            if(isFull){
                                                this.setState({
                                                    conStyle:null,
                                                    isFull:false
                                                })
                                            }else{
                                                this.setState({
                                                    conStyle:{
                                                        width:"100%",
                                                        height:"100vh"
                                                    },
                                                    isFull:true
                                                })
                                            }
                                        }}
                                    ]} 
                                    />
                            </div>
                            <div className="edit_phoneCon">
                                <div className="edit_phoneMain">
                                {
                                    phoneData ?
                                    phoneData.map((item, index)=>{
                                        return(
                                            <div key={index}>
                                                {
                                                    item.type === 'head' ? 
                                                    <div className='Album_head'>{item.content}</div> : 
                                                    item.type === 'text' ? 
                                                    <div className="Album_text">{item.content}</div> : 
                                                    item.type === 'img' ? 
                                                    <div className="Album_img">
                                                        <img src={item.content} width="100%"></img>
                                                    </div> : null
                                                }
                                            </div>
                                        )
                                    }):null
                                }
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        )
    }
}

export default Editor;
