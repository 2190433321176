import React, {Component} from 'react';
import { Table ,Modal,Button,Input ,Icon ,message, Select} from "antd";
const Option = Select.Option

class StarColumn extends Component{
    constructor(props){
        super(props);
        this.state={
            type:1,
            singleData:null
        }
    }
    static defaultProps={
        scColumnName:'请选择栏目名称'
    }

    render(){
        const {type, singleData} = this.state;
        const {visible, name, scData, columnData, scColumnName} = this.props;
        return(
            <div>
                    <Modal
                        title={`${name}栏目分类`}
                        visible={visible}
                        centered={true}
                        okText='修改'
                        cancelText='关闭'
                        onOk={this.addColumn}
                        onCancel={()=>{this.props.closeScmod();this.setState({ type: 1})}}
                        footer={false}
                    >
                        {
                            scData.length > 0 ?
                            scData.map((item, index)=>{
                                return(
                                    <Button 
                                        style={{marginLeft:'10px',marginBottom:'10px'}} 
                                        key={index}
                                        onClick={()=>{this.setState({ type : 2, singleData:item });this.props.scColumnBtn('ok',item, index)}}>
                                            {item.name}
                                    </Button>
                                )
                            }) : null
                        }
                        {
                            type == 1 ? null :
                            <div>
                                <Button type="danger" style={{marginLeft:'10px'}} onClick={()=>{this.setState({ type:1 });this.props.scColumnBtn('cancle')}}>取消</Button>
                                <span style={{fontSize:12+'px',color:'#a7a7a7',marginLeft:10+'px'}}>(点击栏目进行修改,点击取消进行新增)</span>
                            </div> 
                        }
                        <p style={{width:'100%',height:'2px',background:'#eee',margin:'20px 0'}}></p>
                        <div className="inputBox clearfix">
                            <div className="inputName">栏目名称</div>
                            <Select disabled={type == 1 ? false : true} value={scColumnName} style={{width:'50%'}} onChange={(e)=>this.props.getScColumbName(e)}>
                                {
                                    columnData.length>0?
                                    columnData.map((item,index)=>{
                                        return(
                                            <Option key={index} value={index} >{item.name}</Option>
                                        )
                                    }):null
                                }
                            </Select>
                        </div>
                        {
                            type == 1 ? null :
                            <div className="inputBox clearfix">
                                <div className="inputName">栏目状态</div>
                                <Button 
                                    onClick={()=>this.props.changeColoumnStatus(singleData)}
                                    type={singleData.status == 1 ?'primary':'danger'}>{singleData.status == 1 ?'在线':'下线'}</Button>
                        </div>
                        }
                        <p style={{width:'100%',height:'2px',background:'#eee',margin:'20px 0'}}></p>
                        <div style={{width:100+'%',height:40+'px'}}>
                            <Button type="primary"  style={{float:'right'}} onClick={()=>this.props.scColumnEvent(type)}>{type === 1 ? '添加' : '进入内容'}</Button>
                        </div>
                    </Modal>
            </div>
        )
    }
}

export default StarColumn;