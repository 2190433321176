import React, { useState } from 'react';
import './UserPermissionsItem.scss';
import { Modal, Tag, Avatar, Radio } from 'antd';
import { vipTypes, vipTime } from './../config';

const UserPermissionsItem = (props) => {
    const { data, onCancleModal, onSubmit, visible, onSendMessage } = props;
    const { mid, nickname, avatar, openid } = data;
    const [types, setTypes] = useState(0); //会员类型
    const [mb, setMb] = useState(0); //会员时间
    return (
        <Modal
            title="会员权限操作"
            width={580}
            centered={true}
            visible={visible}
            onOk={() => onSubmit(types, mb, mid)}
            onCancel={onCancleModal}
            cancelText="取消"
            okText="确认"
        >
            <main className='permItem_wrap clearfix'>
                <section className='permItem_item'>
                    <Avatar src={avatar} size={100} style={{ marginBottom: 20 }} />
                    <p><Tag color="geekblue">mid:</Tag> {mid}</p>
                    <p><Tag color="geekblue">姓名:</Tag> {nickname}</p>
                </section >
                <section className='permItem_item'>
                    <div className="perItem_box">
                        <div className="perItem_title">会员类型:</div>
                        <Radio.Group
                            buttonStyle="solid"
                            onChange={e => setTypes(e.target.value)}
                        >
                            {vipTypes.map(item => <Radio.Button key={item.id} value={item.value}>{item.name}</Radio.Button>)}
                        </Radio.Group>
                    </div>
                    <div className='perItem_box'>
                        <div className="perItem_title">会员时长:</div>
                        <Radio.Group
                            buttonStyle="solid"
                            onChange={e => setMb(e.target.value)}
                        >
                            {vipTime.map(item => <Radio.Button key={item.id} value={item.value}>{item.name}</Radio.Button>)}
                        </Radio.Group>
                    </div>
                    {/* <div className='perItem_box'>
                        <div className="perItem_title">会员到期提醒:</div>
                        <Radio.Group
                            buttonStyle="solid"
                        >
                            {vipTypes.map(item => <Radio.Button onClick={() => onSendMessage(item.id)} key={item.id} value={item.value}>{item.name}</Radio.Button>)}
                        </Radio.Group>
                    </div> */}
                </section>
            </main>

        </Modal >
    )
};

export default UserPermissionsItem;