import React, { useState, useEffect } from 'react';
import { message, Select, Input } from 'antd';
import { request } from '../../static/js/utils';
import apis from '../../static/js/apis';
import { returnData } from '../../static/js/getTagsListData';
const api = apis.getTagsApi;
const { Option} = Select;

const CascaderList = ( props ) =>{
    const { listType, onGetState, isClear, isfree } = props;
    /** 第一个下拉框数据 */
    const [firsetData, setFirsetData] = useState([]); // 列表数据
    const [firstIndex, changeFirstIndex] = useState(-1); //列表下标
    const [firstVal, changeFirstVal] = useState('请选择内容'); //输入框内容
    const [firsetLoading, changeFirsetLoading] = useState(false); //数据加载loading
    /** 第二个下拉框数据 */
    const [secondData, setSecondData] = useState([]); // 列表数据
    const [secondOpen, changeSecondOpen] = useState(false); //加载开关
    const [secondIndex, changeSecondIndex] = useState(-1);   //列表下标
    const [secondVal, changeSecondVal] = useState('');  //输入框内容
    const [secondLoading, changeSecondLoading] = useState(false); //数据加载loading
    /** 第三个下拉框数据 */
    const [thirdData, setThirdData] = useState([]); // 列表数据
    const [thirdOpen, changeThirdOpen] = useState(false);  //加载开关
    const [thirdIndex, changeThirdIndex] = useState(-1);  //列表下标
    const [thirdVal, changeThirdVal] = useState('');  //输入框内容
    const [thirdLoading, changeThirdLoading] = useState(false); //数据加载loading

    /** 第一个下拉框数据获取 */
    useEffect(()=>{
        const getData = async () =>{
            clearStatus();
            try {
                changeFirsetLoading(true);
                const {data} = await request(api[returnData(listType)['firstUrl']], {}, 'get');
                changeFirsetLoading(false);
                setFirsetData(data.data)
            } catch (error) {
                message.error('网络错误,请重试!');
            }
        };  
        getData();
    }, [listType])
    useEffect(()=>{
        if( firstIndex !== -1){
            changeSecondVal(`${
                listType === 'star' ? isfree === 1 ? '是预告' : isfree === 0 ? '是会员' : '是预告还是会员' :
                returnData(listType)['sVal']
            }`);
            changeThirdVal(returnData(listType)['tVal']);
            changeSecondIndex(-1);
            changeThirdIndex(-1);
            
            changeFirstVal(firsetData[firstIndex]['name']);
            if( returnData(listType)['sType'] ===  'two' || returnData(listType)['sType'] === 'three'){
                if( listType === 'star'){
                    let freeObj = {
                        name:'预告',
                        isfree:1,
                        playerid:firsetData[firstIndex]['playerid']
                    }, vipObj = {
                        name:'会员',
                            isfree:0,
                            playerid:firsetData[firstIndex]['playerid']
                    }, arr = [];
                    if ( isfree === 0){
                        arr = [vipObj]
                    }else if (isfree === 1){
                        arr = [freeObj]
                    }else{
                        arr = [vipObj, freeObj]
                    }
                    setSecondData(arr);
                }else{
                    changeSecondOpen(true);
                }
            }else{
                if(listType === 'hot'){
                    onGetState({
                        cid:firsetData[firstIndex]['cid']
                    })

                }
                
            }
        }
    }, [firstIndex])

    /** 第二下拉框数据加载 */
    useEffect(()=>{
        const getData = async () =>{
            if(secondOpen){
                changeSecondOpen(false);
                changeSecondLoading(true);
                changeSecondIndex(-1);
                changeSecondVal(`${
                    listType === 'star' ? isfree === 1 ? '是预告' : isfree === 0 ? '是会员' : '是预告还是会员' :
                    returnData(listType)['sVal']
                }`);
                changeThirdVal(returnData(listType)['tVal'])
                try {
                    let rdata = {};
                    rdata[returnData(listType)['twoParams']] = firsetData[firstIndex][returnData(listType)['twoParams']];
                    const {data} = await request(api[returnData(listType)['twoUrl']], rdata, 'get');
                    changeSecondLoading(false);
                    setSecondData(data.data);

                } catch (error) {
                    console.log(error);
                }
            }
        };
        getData();
    }, [secondOpen])
    useEffect(()=>{
        if(secondIndex !== -1){
            changeSecondVal(secondData[secondIndex]['name']);
            if(returnData(listType)['sType'] === 'three'){
                changeThirdOpen(true);
            }else{
                if(listType === 'paid'){
                    onGetState({
                        sid:firsetData[firstIndex]['sid'],
                        cid:secondData[secondIndex]['cid']
                    })
                }
            }
        }
    }, [secondIndex])

    /** 第三下拉框数据加载 */
    useEffect(()=>{
        const getData = async () =>{
            if(thirdOpen){
                changeThirdOpen(false);
                changeThirdLoading(true);
                changeThirdIndex(-1);
                changeThirdVal(returnData(listType)['tVal'])
                try {
                    let rdata = {};
                    if(listType === 'star'){
                        rdata['playerid'] = secondData[secondIndex]['playerid'];
                        rdata['isfree'] = secondData[secondIndex]['isfree'];
                    }else{
                        rdata['sid'] = secondData[secondIndex]['sid'];
                        rdata['type'] = firsetData[firstIndex]['type'];
                    }
                    const {data} = await request(api[returnData(listType)['threeUrl']], rdata, 'get');
                    changeThirdLoading(false);
                    setThirdData(data.data);
                } catch (error) {
                    
                }
            }
        };
        getData();
    }, [thirdOpen])
    useEffect(()=>{
        if(thirdIndex !== -1){
            changeThirdVal(thirdData[thirdIndex]['name']);
            if(listType === 'teach'){
                onGetState({
                    type:firsetData[firstIndex]['type'],
                    sid:secondData[secondIndex]['sid'],
                    cid:thirdData[thirdIndex]['cid'],
                })
            }else if (listType === 'star'){
                onGetState({
                    playerid:firsetData[firstIndex]['playerid'],
                    cid:thirdData[thirdIndex]['cid']
                })
            }
        }
    }, [thirdIndex])

    /** 初始化数据 */
    useEffect(()=>{
        if(isClear){
            clearStatus();
        }
    }, [isClear])

    const clearStatus = () =>{
        changeFirstVal(returnData(listType)['fVal']);
        changeSecondVal(`${
            listType === 'star' ? isfree === 1 ? '是预告' : isfree === 0 ? '是会员' : '是预告还是会员' :
            returnData(listType)['sVal']
        }`);
        changeThirdVal(returnData(listType)['tVal']);
        changeSecondIndex(-1);
        changeFirstIndex(-1);
        changeThirdIndex(-1);
        changeSecondLoading(false);
        changeThirdLoading(false);
        changeSecondOpen(false);
        changeThirdOpen(false);
    }
    return(
        <div className='container'>
            <div>
                <Input 
                    style={{borderColor:'#1890ff'}}
                    value={`${
                        returnData(listType)['sType'] === 'one' ? firstVal :
                        returnData(listType)['sType'] === 'two' ? `${firstVal} -- ${secondVal}` :
                        returnData(listType)['sType'] === 'three' ? `${firstVal} -- ${secondVal} -- ${thirdVal}` : null

                }`}
                />
            </div>
            <div style={{marginTop:'10px'}}>
                <div>
                    <Select 
                        style={{width:'50%'}} 
                        loading={firsetLoading}
                        value={firstVal}
                        placeholder='asdasd'
                        onChange={index=>changeFirstIndex(index)}>
                        {
                            firsetData.length > 0 ?
                            firsetData.map((item, index)=>{
                                return(
                                    <Option key={index}>{item.name}</Option>
                                )
                            }) : null
                        }
                    </Select>

                    {
                        returnData(listType)['sType'] === 'two' ||  returnData(listType)['sType'] === 'three'? 
                        <Select 
                            style={{width:'49%', marginLeft:'1%'}}
                            loading={secondLoading}
                            value={secondVal}
                            onChange={index=>changeSecondIndex(index)}
                        >
                            {
                                secondData.length > 0 ?
                                secondData.map((item, index)=>{
                                    return(
                                        <Option key={index}>{item.name}</Option>
                                    )
                                }):null
                            }
                        </Select> : null 
                    }
                </div>
                
                {
                    returnData(listType)['sType'] === 'three'? 
                    <Select 
                        style={{width:'100%'}}
                        loading={thirdLoading}
                        value={thirdVal}
                        onChange = {index => changeThirdIndex(index)}
                    >
                        {
                            thirdData.length > 0 ?
                            thirdData.map((item, index)=>{
                                return(
                                    <Option key={index}>{item.name}</Option>
                                )
                            }):null
                        }
                    </Select> : null 
                }
            </div>
        </div>
    )
};

CascaderList.defaultProps = {
    listType:'star'
}

export default CascaderList;