import publicRouter from "./publicRouter";
import loginPage from './../../../pages/loginPage/loginPage';
import starRouter from "./starRouter";
import teachRouter from "./teachRouter";
import vipRouter from "./vipRouter";

const routerConfig = [
    {
        path: '/login', component: loginPage
    },
    ...publicRouter,
    ...starRouter,
    ...teachRouter,
    ...vipRouter


];

export default routerConfig;