import React from 'react';
import { Modal } from 'antd';
import './VipCancelModal.scss';

const VipCancelModal = (props) => {
    const { userData, visible, onCancelModal, onCancelOrder, index } = props;
    const { goods_name, mid, oid, price, payway, status } = userData;
    return (
        <Modal
            visible={visible}
            title='订单取消'
            okText='信息确认'
            cancelText='取消'
            onCancel={onCancelModal}
            onOk={() => onCancelOrder(oid, index)}
        >
            <section className='vipCancel_item'>
                <p className='vipCancel_title'> mid:</p>
                <p className='vipCancel_main'>{mid}</p>
            </section>
            <section className='vipCancel_item'>
                <p className='vipCancel_title'> 商品名称:</p>
                <p className='vipCancel_main'>{goods_name}</p>
            </section>
            <section className='vipCancel_item'>
                <p className='vipCancel_title'> 订单号:</p>
                <p className='vipCancel_main'>{oid}</p>
            </section>
            <section className='vipCancel_item'>
                <p className='vipCancel_title'> 价格:</p>
                <p className='vipCancel_main'>{price}</p>
            </section>
            <section className='vipCancel_item'>
                <p className='vipCancel_title'> 支付方式:</p>
                <p className='vipCancel_main'>{payway === 1 ? '支付宝' : '微信'}</p>
            </section>
            <section className='vipCancel_item'>
                <p className='vipCancel_title'> 支付状态:</p>
                <p className='vipCancel_main'>{status === 0 ? '未支付' : status === 1 ? '已支付' : '已取消'}</p>
            </section>
        </Modal>
    )

};

export default VipCancelModal;