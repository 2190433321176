import React, {Component} from 'react';
import apis from './../../../static/js/apis';
import {request, publicGetData, clearState, setCookie, getCookie, forReplaceName, addData, returnPageType, setData} from './../../../static/js/utils'
import { Table, message ,Button,Modal,Input,Icon, Popconfirm, Radio, Cascader} from 'antd';
import AploadImg from './../../components/AploadImg/AploadImg';
import axios from 'axios';
import CascaderList from '../../../components/CascaderList/CascaderList';
const api = apis.publicApis;

class adPage extends Component{

    constructor(props){
        super(props);
        this.state={
            isloading:false,
            dataArr:[],
            modalTitle:'',
            data:'',
            pic:'',
            cascaderType:'star',
            xurl:'/starPages/pages/starViews/starViews',
            visible:false,
            page_type:1,
            isClear:false

        }
    }

    columns = [
        {
            title:'id',
            dataIndex:'id'
        },
        {
            title:'图片',
            dataIndex:'pic',
            render:text => <img src={text} style={{"width":"200px"}} alt='' />
        },
        {
            title:'小程序地址',
            dataIndex:'xurl',
        },
        {
            title:'参数',
            dataIndex:'data'
        },
        {
            title:'页面类型',
            dataIndex:'page_type',
            render:text=><p>
                {
                    text === 1 ? '球星详情' :
                    text === 2 ? '付费教学详情' : 
                    text === 3 ? '热点详情' : 
                    text === 4 ? '普通教学详情' : 
                    text === 5 ? '商品详情' : 
                    text === 6 ? '网页' : '未知类型'
                }
            </p>
        },
        {
            title:'状态',
            dataIndex:'status',
            render:(text,record)=>
                <div>
                    <Button
                        type={text === 1 ? 'primary' : 'danger'}
                        onClick={this.changeStatus.bind(this, record.id)}
                    >{text === 1 ? '在线' : '下线'}</Button>
                </div>

        },
        {
            title:'操作',
            dataIndex:'operation',
            render:(text, record, index)=>
                <Button type='primary' data-index={text} style={{"display":"block"}} onClick={()=>this.showModal(1, record, index)}>修改</Button>
        }
    ]

    componentDidMount = () =>{
        this.getData();
    }
    // 获取数据
    getData = () =>{
        publicGetData(1, this, api.getWebAD);
    }

    showModal= (type, item) =>{
        let modalTitle = '';
        if ( type === 1){
            this.setState({
                ...item,
                cascaderType:returnPageType(item['page_type'])
            });
            modalTitle = '修改广告';
        }else if ( type ===  2 ){
            modalTitle = '新增广告';
        };
        this.setState({
            modalTitle,
            visible:true,
            bType:type,
            isClear:false
        })
    }

    okBtn = () =>{
        const {bType} = this.state;
        if (bType === 1) this.upData();
        else this.addData();
    }

    // 新增数据
    addData = () =>{
        const { pic, data, page_type, xurl} = this.state;
        const rdata ={
            pic:forReplaceName(pic.split(','), 'single'),
            data,
            pagetype:page_type,
            xurl
        };
        addData(this, api.addADContent, rdata, 'post');
    }

    //修改数据
    upData = async () =>{
       const { id, data, pic, page_type, xurl } = this.state;
       const rdata = {
           id,
           data,
           pic:forReplaceName(pic.split(','), 'single'),
           pagetype:page_type,
           xurl
       };
       if( data && id && pic && xurl){
            try {
                const res = await request(api.changeADContent, rdata, 'post');
                if(res.data.data === '修改成功'){
                    message.success(res.data.data);
                    this.setState({ visible:false });
                    this.getData();
                    this.clearState();
                }
            } catch (error) {
                
            }
           
       }else {
           message.error('上传内容不能为空!')
       }

    }

    // 数据上下线
    changeStatus = async id =>{
        const {data} = await request(api.changeADStatus, {id:id}, 'post');
        if(data.data === "修改状态成功"){
            message.success(data.data);
            this.getData();
        }

    }

    closeModal = () =>{
        this.setState({
            visible:false
        });
        this.clearState();
    }

    getPicUrl = (file, url) =>{
        this.setState({
            pic:url
        })

    }

    clearState = () =>{
        this.setState({
            isClear:true,
            page_type:1,
            cascaderType:'star'
        })
        clearState(this, ['xurl', 'pic', 'data'], 'str');
    }
    render() {
        const { isloading, dataArr, modalTitle, visible, isClear } = this.state;
        const { page_type, cascaderType, xurl, data } = this.state;
        let { pic } = this.state;
        if (pic) pic = pic.split(','); else pic = [];
        return (
            <div>
                 <h1 style={{display:'inline-block'}}>广告管理</h1>
                 <Button type='primary' style={{"display":"block",'float':'right','zIndex':'999'}} onClick={()=>this.showModal(2)}>新增</Button>
                 <Table 
                    loading={isloading}
                    rowKey={record=>record.id}
                    columns = {this.columns}
                    dataSource = {dataArr}
                 />

                 <Modal 
                    title={modalTitle}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                 >
                     <div className="inputBox clearfix">
                        <div className="inputName">页面类型</div>
                        <Radio.Group 
                            style={{width:'60%'}} 
                            buttonStyle="solid" 
                            value={page_type} 
                            onChange={(e)=>this.setState({ 
                                page_type : e.target.value, 
                                cascaderType:e.target.listType,
                                xurl :  e.target.listType === 'star' ? '/starPages/pages/starViews/starViews' :
                                        e.target.listType === 'paid' ? '/pages/video/views/vipVideo/vipVideo' :
                                        e.target.listType === 'hot' ? '/pages/hot/hotMain/hotMain' :
                                        e.target.listType === 'teach' ? '/pages/video/views/TeachVideo/TeachVideo' : '',
                                data:''
                            })}
                        >
                            <Radio.Button value={1} listType='star'>球星</Radio.Button>
                            <Radio.Button value={2} listType='paid'>付费教学</Radio.Button>
                            <Radio.Button value={3} listType='hot'>热点</Radio.Button>
                            <Radio.Button value={4} listType='teach'>普通教学</Radio.Button>
                            {/* <Radio.Button value={5}>商品</Radio.Button> */}
                            <Radio.Button value={6} listType='web'>网页</Radio.Button>
                        </Radio.Group>
                    </div>
                    {
                        cascaderType === 'web' ? 
                        <div className="inputBox clearfix">
                            <div className="inputName">地址路径</div>
                            <Input style={{width:'60%'}} type="text" value={xurl} onChange={(e)=>this.setState({xurl:e.target.value})} ></Input>
                        </div> : null
                    }
                    {
                        cascaderType === 'web' ? 
                        <div className="inputBox clearfix">
                            <div className="inputName">地址参数</div>
                            <Input style={{width:'60%'}} type="text" value={data} onChange={(e)=>this.setState({data:e.target.value})} ></Input>
                        </div>
                        : 
                        <div className="inputBox clearfix">
                            <div className="inputName">内容选择</div>
                            <div style={{width:'70%'}}>
                                <CascaderList
                                    isClear={isClear}
                                    listType={cascaderType}
                                    onGetState={e=>this.setState({ data:JSON.stringify(e)})}
                                />
                            </div>
                        </div>
                    }
                    <div className="inputBox clearfix">
                        <div className="inputName">pc端图片</div>
                        <AploadImg 
                            deleImg = {()=>this.setState({pic:''})}
                            imgUrl={pic} 
                            mulType={false}
                            getImgUrl={this.getPicUrl}></AploadImg>
                    </div>
                    
                 </Modal>
            </div>
        )
    }
};

export default adPage