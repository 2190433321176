/**
 * 会员类型
 */
const vipTypes = [
    {
        id: 1, name: "球星会员", value: 1
    }, {
        id: 2, name: "教学会员", value: 2
    }
];

/**
 * 会员时间
 */
const vipTime = [
    {
        id: 1, name: '一月', value: 1
    },
    {
        id: 2, name: '一年', value: 2
    },
    {
        id: 3, name: '一季度', value: 3
    },
    {
        id: 4, name: '七天体验', value: 4
    },
];

export {
    vipTypes,
    vipTime
}