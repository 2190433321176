/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Table, Modal, Button, Input, Icon, message } from "antd";
import { WechatOutlined } from "@ant-design/icons";
import { request, publicGetData, clearState, getVideoThumb, publicData, forReplaceName } from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import AploadImg from '../../components/AploadImg/AploadImg';
import './starPage.scss';
import StarColumn from './components/StarColumn/StarColumn';
const api = apis.starApis;
const wxApi = apis.weChatApis;

class starPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...publicData,
            columnData: [],
            scData: [],
            editJson: null,
            editData: null,
            cVisi: false,
            scVis: false,
            editShow: false,
            cbType: true,
            intro: '',
            color: '#ffffff',
            colorName: '',
            symbolImgUrl: '',
            bannerImgUrl: '',
            symbolFile: '',
            bannerFile: '',
            cname: '',
            ctype: '',
            cvid: '',
            pindex: -1,
            cindex: 0
        }
    }
    columes = [
        {
            title: '球星名字',
            dataIndex: 'name',
            width: 120
        }, {
            title: '球星简介',
            dataIndex: 'intro',
            width: 120
        }, {
            title: '球星头像',
            dataIndex: 'banner',
            width: 120,
            render: text => <img src={text} style={{ "width": "100px" }} key={text}></img>
        }, {
            title: '球星头像(宽)',
            dataIndex: 'bannerpo',
            width: 120,
            render: text => <img src={text} style={{ "width": "100px" }} key={text}></img>
        }, {
            title: '球星名字图案',
            dataIndex: 'symbol',
            width: 120,
            render: text => <img src={text} style={{ "width": "100px" }} key={text}></img>
        }, {
            title: '球星颜色',
            dataIndex: 'theme',
            width: 120,
            render: text => <div style={{ background: text, width: 100 + 'px', height: 50 + 'px' }} />
        }, {
            title: '状态',
            dataIndex: 'status',
            width: 120,
            render: (text, record, index) =>
                <div>
                    <Button
                        type={record.status == 1 ? 'primary' : 'danger'}
                        onClick={this.changeStatus.bind(this, record.playerid, index, record.status)}
                    >{record.status == 1 ? '在线' : '下线'}</Button>
                </div>
        }, {
            title: '操作',
            dataIndex: 'operation',
            width: 120,
            render: (text, record) =>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button
                            type='primary'
                            style={{ "display": "block" }}
                            onClick={this.showModal.bind(this, 1, record)}>修改</Button>
                    </div>
                ) : null
        }, {
        },
        // {
        //     title:'栏目',
        //     dataIndex:'column',
        //     width: 100,
        //     render:(text, record)=>
        //         this.state.dataArr.length >= 1 ? (
        //             <div>
        //                 <Button 
        //                     type='primary'
        //                     style={{"display":"block"}} 
        //                     onClick={this.showScMod.bind(this, record)}>查看/修改</Button>
        //             </div>
        //         ) : null
        // },
        {
            title: '跳转',
            dataIndex: 'column',
            width: 120,
            render: (text, record) =>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button
                            type='primary'
                            style={{ "display": "block" }}
                            onClick={() => this.props.history.push(`starDetail?playerid=${record.playerid}`)}>查看内容</Button>
                    </div>
                ) : null
        },
        {
            title: '变化位置',
            dataIndex: 'change',
            width: 100,
            render: (text, record, index) =>
                <div>
                    {
                        index == 0 ? '' :
                            <Button type="button" pindex={index} onClick={this.upBanner}>
                                <Icon type="up"></Icon>
                            </Button>
                    }
                    {
                        index == this.state.dataArr.length - 1
                            ? ''
                            :
                            <Button type="button" pindex={index} onClick={this.downBanner}>
                                <Icon type="down"></Icon>
                            </Button>
                    }
                </div>
        }, {
            title: '入住提醒',
            dataIndex: 'push',
            width: 200,
            render: (text, item) => <Button onClick={this.pushWxMessage.bind(this, item)} style={{ background: '#62b900', color: '#fff' }}><WechatOutlined />球星入住提醒推送</Button>
        }

    ]
    componentDidMount = () => {
        // 获取初始数据
        this.getData();
        // 获取球星栏目
        this.getColumn();
    }
    getData = async (l) => {
        publicGetData(l, this, api.getVipPlayer, {});
    }
    getColumn = async () => {
        const { data } = await request(api.getPlayerColumn, null, 'get');
        if (data.message === 'SUCCESS') {
            this.setState({ columnData: data.data });
        }
    }

    // 打开修改/新增弹窗
    showModal = (type, data) => {
        let { modelTitle } = this.state;
        if (type === 1) {
            modelTitle = '修改球星数据';
            const { name, intro, banner, bannerpo, symbol, theme, playerid } = data;
            this.setState({
                bannerpo,
                name,
                intro, intro,
                bannerImgUrl: banner,
                symbolImgUrl: symbol,
                colorName: theme,
                color: theme,
                playerid,
            });
        }
        else {
            modelTitle = '新增球星数据';
            this.clearState();
        };
        this.setState({
            visible: true,
            modelTitle: modelTitle,
            bType: type
        })
    }
    // 关闭弹窗
    closeModal = () => {
        this.setState({
            visible: false,
        })
        this.clearState();
    }
    okBtn = () => {
        const { bType } = this.state;
        if (bType === 1) { this.upData() } else { this.addData() };
    }
    addData = async () => {
        const { name, intro, colorName, symbolFile, bannerFile, bannerpo } = this.state;
        const rdata = {
            name: name,
            intro: intro,
            theme: colorName.substr(1),
            banner: bannerFile,
            bannerpro: forReplaceName(bannerpo.split(','), 'single'),
            symbol: symbolFile,
        };
        console.log(rdata);
        try {
            const { data } = await request(api.addVipPlayer, rdata, 'post');
            if (data.data === '添加成功') {
                message.success('添加成功');
                this.getData();
                this.setState({ visible: false });
                this.clearState();
            } else {
                message.error('上传内容不能为空!');
            }
        } catch (error) {
            message.error('上传内容不能为空!');
        }
    }
    // 修改数据
    upData = async () => {
        const { name, intro, colorName, bannerpo, symbolImgUrl, bannerImgUrl, playerid } = this.state;
        let rdata = {
            id: playerid,
            name: name,
            intro: intro,
            theme: colorName.substr(1),
            symbol: forReplaceName(symbolImgUrl.split(','), 'single'),
            banner: forReplaceName(bannerImgUrl.split(','), 'single'),
            bannerpro: forReplaceName(bannerpo.split(','), 'single'),
        };
        const { data } = await request(api.editorPlayer, rdata, "post");
        if (data.data === "修改成功") {
            message.success(data.data);
            this.getData();
            this.setState({ visible: false });
            this.clearState();
        } else {
            message.error(data.message);
        }
    }
    // 改变状态
    changeStatus = async (id, index, status) => {
        const { dataArr } = this.state;
        const { data } = await request(api.changePyState, { id: id }, 'post');
        if (data.data === "修改成功") {
            if (status == 1) {
                dataArr[index].status = 2;
                message.success('下线成功!');
            } else {
                dataArr[index].status = 1;
                message.success('上线成功!');
            }
            this.setState({ dataArr: dataArr });
        }

    }
    // 向下移动banner
    downBanner = (e) => {
        const pindex = parseInt(e.target.getAttribute('pindex'));
        const { dataArr } = this.state;
        const a = dataArr[pindex];
        const b = dataArr[pindex + 1];
        const m_id = a.playerid;
        const s_id = b.playerid;
        dataArr.splice(pindex + 1, 1, a);
        dataArr.splice(pindex, 1, b);
        this.setState({ dataArr });
        this.transposition(m_id, s_id);
    }
    // 向上移动banner
    upBanner = (e) => {
        const pindex = parseInt(e.target.getAttribute('pindex'));
        const { dataArr } = this.state;
        const a = dataArr[pindex];
        const b = dataArr[pindex - 1];
        const m_id = a.playerid;
        const s_id = b.playerid;
        dataArr.splice(pindex - 1, 1, a);
        dataArr.splice(pindex, 1, b);
        this.setState({ dataArr });
        this.transposition(m_id, s_id);
    }

    transposition = async (m_id, s_id) => {
        await request(api.tranplayer, { m_id: m_id, s_id: s_id }, 'post');
    }

    // 添加球星栏目
    addColumn = async () => {
        const { cname, ctype, cbType, cvid, cindex, columnData } = this.state;
        const rdata = {
            name: cname,
        };
        if (cbType) {
            rdata['type'] = ctype;
            const { data } = await request(api.addPlayerColumn, rdata, 'post');
            if (data.message === 'SUCCESS') {
                message.success('添加成功!');
                const { id } = data.data;
                rdata['vid'] = id;
                columnData.push(rdata);
                this.setState({ columnData: columnData, cname: '', ctype: '', cvid: '' });
            } else {
                message.error('添加失败, 请重试!');
            }
        } else {
            rdata['vid'] = cvid;
            await request(api.revisePlayerColumn, rdata, 'post');
            columnData[cindex]['name'] = cname;
            this.setState({ columnData: columnData, cname: '', ctype: '', cvid: '', cbType: true });
        }
    }

    // 获取球星名字图片
    getSymbolImgUrl = (file, url) => {
        this.setState({ symbolImgUrl: url, symbolFile: file });
    }
    // 删除球星名字图片
    deleSymbolImg = () => {
        this.setState({ symbolImgUrl: '' });
    }
    // 获取球星头像
    getBannerImgUrl = (file, url) => {
        this.setState({ bannerImgUrl: url, bannerFile: file });
    }
    // 删除球星头像
    deleBannerImg = () => {
        this.setState({ bannerImgUrl: '' });
    }
    deleBannerproImg = () => {
        this.setState({ bannerpo: '' });
    }
    getBannerproImgUrl = (file, url) => {
        this.setState({ bannerpo: url })
    }

    /**
     * 微信订阅消息推送
     * @param {Object} item 内容
     */
    pushWxMessage = async (item) => {
        let { name, intro, playerid } = item;
        const date = new Date();
        name = JSON.stringify(name).replace(/\s*/g, "");
        name = name.slice(1, name.length - 1);
        intro = JSON.stringify(intro).replace(/\s*/g, "");
        intro = intro.slice(1, intro.length - 1);
        const json = {
            thing1: {
                value: name.length > 20 ? `${name.slice(0, 17)}...` : name,
            }, thing2: {
                value: intro.length > 20 ? `${intro.slice(0, 17)}...` : intro,
            }, time3: {
                value: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            },
        };
        const data = {
            template_id: "tC6F2TfiKwcugnn8dTegkhApPxeV0syAQqCmTKZKKdg",
            page: `/starPages/pages/starViews/starViews?playerid=${playerid}&cid=0`,
            type: "formal",
            data_json: JSON.stringify(json)
        };
        try {
            const res = await request(wxApi.sendWxMessages, data, "post");
            const { error, success } = res.data.data;
            message.success(`共推送成功${success}条,推送失败${error}条`);
        } catch (error) {
            message.error(`推送失败,${error}`);
        }
    }


    // ---- 球星栏目 ---- 
    showScMod = async (rdata) => {
        const { name, playerid } = rdata;
        const { data } = await request(api.getPlayerData, { 'playerid': playerid }, 'get');
        if (data.message === 'SUCCESS') {
            this.setState({
                name: name,
                playerid: playerid,
                scVis: true,
                scData: data.data,
            });
        }
    }
    closeScMod = () => {
        this.setState({
            scVis: false
        });
        this.clearState();
    }
    // 获取栏目内容
    getScColumbName = (e) => {
        const { columnData } = this.state;
        const { name, vid } = columnData[e];
        this.setState({
            scColumnName: name,
            scColumnVid: vid
        });
    }
    // 按钮事件
    scColumnEvent = async (type) => {
        const { scColumnVid, playerid, scData, scColumnName, name } = this.state;
        if (type === 1) {
            if (scColumnVid) {
                const { data } = await request(api.addPlayerData, { playerid: playerid, vid: scColumnVid }, 'post');
                if (data.message === 'SUCCESS') {
                    scData.push({ name: scColumnName, status: 1 });
                    message.success('添加成功!');
                    this.setState({ scData: scData, scColumnVid: '', scColumnName: '请选择栏目名称' });
                }
            } else {
                message.error('请选择要添加的栏目');
            }
        } else if (type === 2) {
            this.props.history.push(`starDetail?playerid=${playerid}&vid=${scColumnVid}&colName=${encodeURIComponent(scColumnName)}`);
        }
    }
    scColumnBtn = (type, rdata, index) => {
        if (type === 'ok') {
            const { name, id, vid } = rdata;
            this.setState({ scColumnName: name, scColNum: index, scColumnVid: vid });
        } else if (type === 'cancle') {
            this.setState({ scColumnName: '请选择栏目名称' })

        }
    }
    // 改变栏目状态
    changeColoumnStatus = async (jdata) => {
        let { id, status } = jdata;
        const { scData, scColNum } = this.state;
        if (status == 1) {
            status = 2;
        } else {
            status = 1;
        };
        const rdata = {
            id: id,
            status: status
        };
        const { data } = await request(api.revisePlayerData, rdata, 'post');
        if (data.message === "SUCCESS") {
            message.success("修改成功!");
            scData[scColNum]['status'] = status;
            this.setState({ scData: scData });
        }
    }

    // 初始化数据
    clearState = () => {
        clearState(this, ['name', 'intro', 'colorName', 'videoUrl', 'symbolImgUrl', 'thumbImgUrl', 'bannerImgUrl', ' playerid'], 'str');
        clearState(this, ['symbolFile', 'thumbFile', 'bannerFile', 'bannerpo', 'color'], 'str');
        clearState(this, ['scColumnName', 'scColumnVid'], 'str')
        clearState(this, ['scData'], 'arr');
    }
    render() {
        const { isloading, total, dataArr, modelTitle, visible, cVisi, columnData, cbType, scVis } = this.state;
        const { name, color, colorName, intro, videoUrl, complete, cname, ctype, scData } = this.state;

        // 单个球星栏目数据
        const { scColumnName } = this.state;
        let { symbolImgUrl, bannerImgUrl, bannerpo } = this.state;
        if (symbolImgUrl) { symbolImgUrl = symbolImgUrl.split(','); } else { symbolImgUrl = [] };
        if (bannerImgUrl) { bannerImgUrl = bannerImgUrl.split(','); } else { bannerImgUrl = [] };
        if (bannerpo) { bannerpo = bannerpo.split(','); } else { bannerpo = [] };
        return (
            <div>
                <h1 style={{ display: 'inline-block' }}>球星后台</h1>
                <Button type='primary' style={{ "display": "block", 'float': 'right', 'zIndex': '999' }} onClick={this.showModal.bind(this, 2)}>新增球星</Button>
                {/* <Button type="primary" style={{float:'right',marginRight:'10px'}} onClick={()=>this.setState({ cVisi: true })}>新增球星栏目分类</Button> */}
                <Table
                    isloading={isloading}
                    rowKey={record => record.playerid}
                    columns={this.columes}
                    dataSource={dataArr}
                    scroll={{ x: 1200 }}
                    pagination={{
                        total: total,
                        defaultPageSize: 20,
                        onChange: this.getData
                    }}
                ></Table>
                <Modal
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">球星名字</div>
                        <Input style={{ width: '70%' }} id="title" type="text" placeholder="请输入球星名字" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">球星简介</div>
                        <Input style={{ width: '70%' }} id="intro" type="text" placeholder="请输入球星简介" value={intro} onChange={(e) => this.setState({ intro: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">球星头像</div>
                        <AploadImg
                            deleImg={this.deleBannerImg}
                            imgUrl={bannerImgUrl}
                            mulType={false}
                            getImgUrl={this.getBannerImgUrl}
                        />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">球星头像(宽)</div>
                        <AploadImg
                            deleImg={this.deleBannerproImg}
                            imgUrl={bannerpo}
                            mulType={false}
                            getImgUrl={this.getBannerproImgUrl}
                        />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">球星名字图片</div>
                        <AploadImg
                            deleImg={this.deleSymbolImg}
                            imgUrl={symbolImgUrl}
                            mulType={false}
                            getImgUrl={this.getSymbolImgUrl}
                        />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">球星颜色</div>
                        <Input style={{ width: '15%' }} type="color" value={color} onChange={(e) => this.setState({ color: e.target.value, colorName: e.target.value })} />
                        <Input style={{ width: '30%' }} type="text" placeholder="颜色输入" value={colorName} onPressEnter={(e) => this.setState({ color: e.target.value })} onChange={(e) => this.setState({ colorName: e.target.value })} />
                        <div style={{ background: colorName, width: 30 + '%', height: 32 + 'px' }} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">球星头像预览</div>
                        <div className="starThumb">
                            <div className="starAvaThumb">
                                <div className="starAva" style={{ border: `4px solid ${color || 'transparent'}` }}>
                                    <img src={bannerImgUrl[0]} alt="" />
                                </div>
                                <div className="starSymbol">
                                    <img src={symbolImgUrl[0]} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="球星栏目分类"
                    visible={cVisi}
                    centered={true}
                    okText={cbType ? '添加' : '修改'}
                    cancelText='关闭'
                    onOk={this.addColumn}
                    onCancel={() => this.setState({ cname: '', ctype: '', cvid: '', cbType: true, cVisi: false })}
                >
                    {
                        columnData.length > 0 ?
                            columnData.map((item, index) => {
                                return (
                                    <Button style={{ marginLeft: '10px', marginBottom: '10px' }} key={index} onClick={() => this.setState({ cname: item.name, ctype: item.type, cvid: item.vid, cindex: index, cbType: false })}>{item.name}</Button>
                                )
                            }) : null
                    }
                    <Button type="danger" style={{ marginLeft: '10px' }} onClick={() => this.setState({ cname: '', ctype: '', cvid: '', cbType: true })}>取消</Button>
                    <span style={{ fontSize: 12 + 'px', color: '#a7a7a7', marginLeft: 10 + 'px' }}>(点击栏目进行修改,点击取消进行新增)</span>
                    <p style={{ width: '100%', height: '2px', background: '#eee', margin: '20px 0' }}></p>
                    <div className="inputBox clearfix">
                        <div className="inputName">栏目名称</div>
                        <Input style={{ width: '40%' }} id="title" type="text" placeholder="请输入栏目名称" value={cname} onChange={(e) => this.setState({ cname: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">栏目类型</div>
                        <Input style={{ width: '40%' }} id="title" type="text" placeholder="请输入栏目类型" value={ctype} onChange={(e) => this.setState({ ctype: e.target.value })} />
                    </div>
                </Modal>

                <StarColumn
                    visible={scVis}
                    name={name}
                    scColumnName={scColumnName}
                    scData={scData}
                    columnData={columnData}
                    closeScmod={this.closeScMod}
                    getScColumbName={this.getScColumbName}
                    scColumnEvent={this.scColumnEvent}
                    scColumnBtn={this.scColumnBtn}
                    changeColoumnStatus={this.changeColoumnStatus} />


            </div>
        )
    }
}

export default starPage;