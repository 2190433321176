import React from 'react';
import './VipUserOrderComp.scss';
import { Modal, Result, Table } from 'antd';

const VipUserOrderComp = (props) => {
    const { visible, userOrderData, onCancelModal } = props;
    const columes = [
        {
            title: '商品名称',
            dataIndex: 'goods_name'
        }, {
            title: '订单号',
            dataIndex: 'oid'
        }, {
            title:' 下单时间',
            dataIndex: 'addtime'
        },{
            title: '订单类型',
            dataIndex: 'oid_type',
            width: 100,
            render: text => <p>{text === 1 ? '会员' : '专辑'}</p>
        }, {
            title: '支付类型',
            dataIndex: 'payway',
            render: text => <p>{text === 1 ? '支付宝' : '微信'}</p>
        }, {
            title: '价格',
            dataIndex: 'price'
        }, {
            title: '状态',
            dataIndex: 'status',
            render: text => <p>{text === 0 ? '未支付' : text === 1 ? '已支付' : text === 4 ? '已取消' : '未知状态'}</p>
        }
    ]
    return (
        <Modal
            width={1000}
            centered={true}
            destroyOnClose={true}
            visible={visible}
            title="用户订单查询"
            cancelText='关闭'
            okText='确认'
            onOk={onCancelModal}
            onCancel={onCancelModal}
        >
            {
                userOrderData.length > 0 ?
                    <Table
                        rowKey={(item, index) => index}
                        columns={columes}
                        dataSource={userOrderData}
                        scroll={{ x: 1200 }}
                    /> : <Result title='当前订单为空' />
            }
        </Modal>
    )
};

export default VipUserOrderComp;