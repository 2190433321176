import React, { Component } from 'react';
import apis from './../../../static/js/apis';
import { request, publicGetData, clearState, setCookie, getCookie, forReplaceName, addData, returnPageType } from './../../../static/js/utils'
import { Table, message, Button, Modal, Input, Icon, Popconfirm, Radio, Cascader } from 'antd';
import AploadImg from './../../components/AploadImg/AploadImg';
import axios from 'axios';
import CascaderList from '../../../components/CascaderList/CascaderList';
import getTypeList from '../../../static/js/getTagsTypeData';
const api = apis.publicApis;

class bannerPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataArr: [],
            modelTitle: '',
            title: '',
            url: '',
            type: '',
            imgUrl: '',
            uploadImgArr: '',
            bType: 0,
            business_type: 1,
            page_type: 1,
            id: 0,
            pindex: -1,
            dataName: '',
            isloading: true,
            visible: false,
            record: null,
            isClear: false,
            cascaderType: 'star',
            xurl: '/starPages/pages/starViews/starViews'
        }
    }
    // table渲染数据 input输入框渲染数据
    columns = [
        {
            title: 'id',
            dataIndex: 'id',
            sorter: (a, b) => parseInt(a.id) - parseInt(b.id)
        }, {
            title: 'banner类型',
            dataIndex: 'business_type',
            render: text => <p>{text === 1 ? '首页' : '商城'}</p>
        }, {
            title: '标题',
            dataIndex: 'title',
        }, {
            title: '移动端图片',
            dataIndex: 'mpic',
            render: text => <img src={text} style={{ "width": "200px" }} alt='' key={text} />
        }, {
            title: 'pc端图片',
            dataIndex: 'ppic',
            render: text => <img src={text} style={{ "width": "200px" }} alt='' key={text}></img>
        }, {
            title: '小程序地址',
            dataIndex: 'xurl'
        }, {
            title: '页面类型',
            dataIndex: 'page_type',
            render: text => <p>
                {
                    text === 1 ? '球星详情' :
                        text === 2 ? '付费教学详情' :
                            text === 3 ? '热点详情' :
                                text === 4 ? '普通教学详情' :
                                    text === 5 ? '商品详情' :
                                        text === 6 ? '网页' : '未知类型'
                }
            </p>
        }, {
            title: '地址参数',
            dataIndex: 'data'
        }, {
            title: '操作',
            dataIndex: 'operation',
            render: (text, record, index) =>
                this.state.dataArr.length >= 1 ? (
                    <div style={{ display: 'flex' }}>
                        <div>
                            <Button type='primary' data-index={text} style={{ "display": "block" }} onClick={this.showModal.bind(this, 1, record, index)}>修改</Button>
                            <Popconfirm
                                title="您确定要删除这条数据嘛?"
                                onConfirm={this.deleteBanner.bind(this, record.id)}
                                okText="确定"
                                cancelText="取消">
                                <Button type='danger'>删除</Button>
                            </Popconfirm>
                        </div>
                        <div>
                            {
                                index == 0 ? '' :
                                    <Button type="button" pindex={index} onClick={this.upBanner}>
                                        <Icon type="up"></Icon>
                                    </Button>
                            }
                            {
                                index == this.state.dataArr.length - 1
                                    ? ''
                                    :
                                    <Button type="button" pindex={index} onClick={this.downBanner}>
                                        <Icon type="down"></Icon>
                                    </Button>
                            }
                        </div>
                    </div>
                ) : null
        }
    ]
    inpDate = [
        { id: 1, title: '标题', tid: 'title', },
    ]

    componentDidMount = async () => {
        this.getData();
    }
    getData = () => {
        publicGetData(1, this, api.banner);
    }

    // 打开修改/新增弹窗
    showModal = (type, record, index) => {
        let { modelTitle } = this.state;
        if (type === 1) {
            modelTitle = '修改banner';
            const { id, title, mpic, ppic, xurl, business_type, page_type, data } = record;
            this.setState({
                id: id,
                title: title,
                mpic: mpic,
                ppic: ppic,
                xurl: xurl,
                business_type: business_type,
                page_type: page_type,
                data: data,
                dataName: data,
                pindex: index,
                cascaderType: returnPageType(page_type)
            })
            // getTypeList({self:this, type:returnPageType(page_type), data:data});
        } else {
            modelTitle = '新增banner';
            this.clearState();
        };
        this.setState({
            visible: true,
            isClear: false,
            modelTitle: modelTitle,
            bType: type
        });
    }

    // 点击弹窗确定
    /**
     *  通过type判断 
     *  1为修改当前的banner数据
     *  2位添加一条新的banner数据
     */
    okBtn = () => {
        const { bType } = this.state;
        if (bType === 1) {
            this.upDate();
        } else {
            this.addDate();
        };
    }
    // 修改当前的数据
    upDate = async () => {
        const { id, title, mpic, business_type, page_type, xurl, data, dataArr } = this.state;
        const rdata = {
            id: id,
            title: title,
            ppic: forReplaceName(mpic.split(','), 'single'),
            mpic: forReplaceName(mpic.split(','), 'single'),
            business_type: business_type,
            page_type: page_type,
            xurl: xurl,
            data: data
        };
        try {
            const { data } = await request(api.reviseBanner, rdata, 'post');
            if (data.data === "修改成功") {
                this.getData();
                this.setState({ visible: false, dataArr: dataArr });
                message.success('修改成功!');
            } else {
                message.error('修改失败,请重试!');
            }
        } catch (error) {
            message.error('修改失败,请重试!');
        }
    }
    // 添加一条新的数据
    addDate = async () => {
        const { title, mpic, business_type, page_type, xurl, data } = this.state;
        const rdata = {
            title: title,
            ppic: forReplaceName(mpic.split(','), 'single'),
            mpic: forReplaceName(mpic.split(','), 'single'),
            business_type: business_type,
            page_type: page_type,
            xurl: xurl,
            data: data
        };
        addData(this, api.addBanner, rdata);

    }
    // 向下移动banner
    downBanner = (e) => {
        const pindex = parseInt(e.target.getAttribute('pindex'));
        const { dataArr } = this.state;
        const a = dataArr[pindex];
        const b = dataArr[pindex + 1];
        const m_id = a.id;
        const s_id = b.id;
        dataArr.splice(pindex + 1, 1, a);
        dataArr.splice(pindex, 1, b);
        this.setState({ dataArr });
        this.transposition(m_id, s_id);
    }
    // 向上移动banner
    upBanner = (e) => {
        const pindex = parseInt(e.target.getAttribute('pindex'));
        const { dataArr } = this.state;
        const a = dataArr[pindex];
        const b = dataArr[pindex - 1];
        const m_id = a.id;
        const s_id = b.id;
        dataArr.splice(pindex - 1, 1, a);
        dataArr.splice(pindex, 1, b);
        this.setState({ dataArr });
        this.transposition(m_id, s_id);
    }
    // 修改banner图顺序
    transposition = async (m_id, s_id) => {
        const { data } = await request(api.transposition, { m_id: m_id, s_id: s_id }, 'post');
        if (data.data === "互换成功") {
            message.success(data.data);
        }
    }
    // 关闭修改/新增弹窗
    closeModal = () => {
        // 清空当前数据
        this.setState({
            visible: false,
            record: null,
        });
        this.clearState();
    }


    // 删除banner图
    deleteBanner = async (id) => {
        const { data } = await request(api.deleteBanner, { id: id }, 'post');
        if (data.data === '删除成功') {
            message.success('删除成功!');
            this.setState({ dataArr: this.state.dataArr.filter(item => item.id !== id) })
        } else {
            message.error('删除失败,请重试!')
        }
    }

    // 获取输入框输入的内容
    /**
     * 通过id来判断所输入的框
     * 1 为标题
     * 2 为地址
     * 3 为地址参数
     */
    getVal = (id, e) => {
        if (id === 1) {
            this.setState({ title: e.target.value })
        } else if (id === 2) {
            this.setState({ xurl: e.target.value })
        } else if (id === 3) {
            this.setState({ data: e.target.value })
        }
    }

    // 获取上传图片数据
    getPpicUrl = (file, url) => {
        this.setState({ ppic: url });
    }
    getMpicUrl = (file, url) => {
        this.setState({ mpic: url });
    }

    // 数据初始化
    clearState = () => {
        clearState(this, ['title', 'ppic', 'mpic', 'data', 'dataName'], 'str');
        clearState(this, ['id'], 'num');
        this.setState({
            business_type: 1,
            page_type: 1,
            cascaderType: 'star',
            isClear: true,
            xurl: '/starPages/pages/starViews/starViews'
        })
    }
    render() {
        const { dataArr, isloading, visible, modelTitle, bType, record, title, cascaderType, isClear } = this.state;
        const { business_type, page_type, xurl, data, dataName } = this.state;
        let { mpic, ppic } = this.state;
        if (mpic) { mpic = mpic.split(','); } else { mpic = []; };
        if (ppic) { ppic = ppic.split(','); } else { ppic = []; };
        return (
            <div>
                <h1 style={{ display: 'inline-block' }}>轮播图</h1>
                <Button type='primary' style={{ "display": "block", 'float': 'right', 'zIndex': '999' }} onClick={this.showModal.bind(this, 2)}>新增</Button>
                <Table
                    loading={isloading}
                    rowKey={record => record.id}
                    columns={this.columns}
                    scroll={{x:2000}}
                    dataSource={dataArr}>
                </Table>

                <Modal
                    width='35%'
                    title={modelTitle}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    {
                        bType === 1 && record ?
                            <div className="inputBox clearfix">
                                <div className="inputName">id</div>
                                <Input id="cid" style={{ width: '15%' }} type="text" value={record.id} disabled />
                            </div> : null
                    }
                    <div className="inputBox clearfix">
                        <div className="inputName">标题</div>
                        <Input style={{ width: '60%' }} type="text" placeholder='请输入banner标题' value={title} onChange={(e) => this.setState({ title: e.target.value })} ></Input>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">页面类型</div>
                        <Radio.Group
                            style={{ width: '60%' }}
                            buttonStyle="solid"
                            value={page_type}
                            onChange={(e) => this.setState({
                                page_type: e.target.value,
                                cascaderType: e.target.listType,
                                xurl: e.target.listType === 'star' ? '/starPages/pages/starViews/starViews' :
                                    e.target.listType === 'paid' ? '/pages/video/views/vipVideo/vipVideo' :
                                        e.target.listType === 'hot' ? '/pages/hot/hotMain/hotMain' :
                                            e.target.listType === 'teach' ? '/pages/video/views/TeachVideo/TeachVideo' : '',
                                data: ''
                            })}
                        >
                            <Radio.Button value={1} listType='star'>球星</Radio.Button>
                            <Radio.Button value={2} listType='paid'>付费教学</Radio.Button>
                            <Radio.Button value={3} listType='hot'>热点</Radio.Button>
                            <Radio.Button value={4} listType='teach'>普通教学</Radio.Button>
                            {/* <Radio.Button value={5}>商品</Radio.Button> */}
                            <Radio.Button value={6} listType='web'>网页</Radio.Button>
                        </Radio.Group>
                    </div>
                    {
                        cascaderType === 'web' ?
                            <div className="inputBox clearfix">
                                <div className="inputName">地址路径</div>
                                <Input style={{ width: '60%' }} placeholder='小程序需要跳转的网页地址' type="text" value={xurl} onChange={(e) => this.setState({ xurl: e.target.value })} ></Input>
                            </div> : null
                    }
                    {
                        cascaderType === 'web' ?
                            <div className="inputBox clearfix">
                                <div className="inputName">地址参数</div>
                                <Input style={{ width: '60%' }} placeholder='需要跳转的网页地址的参数' type="text" value={data} onChange={(e) => this.setState({ data: e.target.value })} ></Input>
                            </div>
                            :
                            <div className="inputBox clearfix">
                                <div className="inputName">内容选择</div>
                                <div style={{ width: '80%' }}>
                                    <CascaderList
                                        dataName={dataName}
                                        isClear={isClear}
                                        listType={cascaderType}
                                        onGetState={e => this.setState({ data: JSON.stringify(e) })}
                                    />
                                </div>
                            </div>
                    }
                    {/* <div className="inputBox clearfix">
                        <div className="inputName">pc端图片</div>
                        <AploadImg 
                            deleImg = {()=>this.setState({ppic:''})}
                            imgUrl={ppic} 
                            mulType={false}
                            getImgUrl={this.getPpicUrl}></AploadImg>
                    </div>  */}
                    <div className="inputBox clearfix">
                        <div className="inputName">图片</div>
                        <AploadImg
                            deleImg={() => this.setState({ mpic: '' })}
                            imgUrl={mpic}
                            mulType={false}
                            getImgUrl={this.getMpicUrl}></AploadImg>
                    </div>
                </Modal>
            </div>
        )
    }
}


export default bannerPage;