import publicApis from './publicApis';
import activeApis from './activeApis';
import utilApis from './utilApis';
import starApis from './starApis';
import teachApis from './teachApis';
import shopApis from './shopApis';
import getTagsApi from './getTagsApi';
import vipApis from './vipApis';
import weChatApis from './wechatApis';

export default {
    publicApis,
    activeApis,
    utilApis,
    starApis,
    teachApis,
    shopApis,
    getTagsApi,
    vipApis,
    weChatApis
};