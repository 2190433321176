// 登陆接口
const login = "/admin/login"; //登陆接口
// banner后台接口
const banner = "/admin/getNewBanner"  //获取banner图
const deleteBanner = "/admin/deleteNewBanner" //删除banner图
const addBanner =  "/admin/addNewBanner" //新增banner图
const reviseBanner = "/admin/reviseNewBanner" //修改banner图
const transposition = "/admin/transposition" //修改banner图顺序

// 热点视频后台接口
const getHotspot = "/admin/getHotspot"  //获取热点信息
const uploadHotspot = "/admin/uploadHotspot" //上传热点信息
const deleteHotspot =  "/admin/deleteHotspot" //删除热点信息
const reviseHotspot = "/admin/reviseHotspot" //修改热点信息

// 教学视频后台接口
const getEducation =  "/admin/getEducation" //获取教学
const getSkill = "/admin/getSkill" //获取技术分类
const getSeason =  "/admin/getSeason" //获取季度分类
const getEducationName = "/admin/getEducationName" //获取季度和技巧名
const getPrivateWorkoutTags = "/admin/getPrivateWorkoutTags" //获取私人训练标签
const deleteEducation =  "/admin/deleteEducation" //删除教学视频
const uploadEducation = "/admin/uploadEducation" //上传新的教学
const reviseEducation = "/admin/reviseEducation" //修改教学

// 私人训练视频后台接口
const getPrivateWorkouts = "/getPrivateWorkouts" //获取私人训练视频
const addPrivateWorkoutsTags = "/admin/addPrivateWorkoutsTags" //新增私人训练标签
const deletePrivateWorkoutsTags =  "/admin/deletePrivateWorkoutsTags" //删除私人训练标签

// 研究所视频后台接口
const getResearch = "/admin/getResearch" //获取研究所分类

// 集锦视频后台接口
const getHomeVideoMore = "/getHomeVideoMore" //获取集锦视频

// 跟拍视频后台接口
const getFollowOrEvents = "/admin/getFollowOrEvents" //获取跟拍视频

// 评论列表后台接口
const getComments = '/admin/getComments' //获取用户评论 
const reviseCommentStatus = "/admin/reviseCommentStatus" //改变评论状态

// 广告列表后台接口
const getWebAD = "/admin/getWebAD"; //获取广告列表
const addADContent = "/admin/addADContent"; //添加广告
const changeADContent = "/admin/changeADContent"; //修改广告
const changeADStatus = "/admin/changeADStatus"; //广告上下线


export default {
    //登陆接口
    login,
    // banner后台接口
    banner:banner,
    deleteBanner:deleteBanner,
    addBanner,
    reviseBanner,
    transposition,
    
    // 热点视频后台接口
    getHotspot:getHotspot,  
    uploadHotspot,
    deleteHotspot,
    reviseHotspot,

    // 教学视频后台接口
    getEducation:getEducation,
    getSkill:getSkill,
    getSeason:getSeason,
    getEducationName:getEducationName,
    getPrivateWorkoutTags:getPrivateWorkoutTags,
    deleteEducation:deleteEducation,
    uploadEducation:uploadEducation,
    reviseEducation:reviseEducation,

    // 私人训练后台接口
    getPrivateWorkouts:getPrivateWorkouts,
    addPrivateWorkoutsTags:addPrivateWorkoutsTags,
    deletePrivateWorkoutsTags:deletePrivateWorkoutsTags,

    // 研究所视频后台接口
    getResearch:getResearch,

    // 集锦视频后台接口
    getHomeVideoMore:getHomeVideoMore,

    // 跟拍视频后台接口
    getFollowOrEvents:getFollowOrEvents,

    // 评论列表后台接口
    getComments:getComments,
    reviseCommentStatus:reviseCommentStatus,

    // 广告后台接口
    getWebAD,
    addADContent,
    changeADContent,
    changeADStatus
}