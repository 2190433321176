import React, {Component} from 'react';
import { Table ,Modal,Button,Input ,message, Tag, Radio, Select } from "antd";
import { request, publicGetData, clearState, getVideoThumb, publicData, getParams, addData, returnUpdateParams } from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import AploadImg from '../../components/AploadImg/AploadImg';
import AploadVideo from '../../components/AploadVideo/AploadVideo';

const { TextArea } = Input, Option = Select.Option, api = apis.starApis, papi = apis.publicApis, tApi = apis.getTagsApi;

class starDetailPage extends Component{
    constructor(props){
        super(props);
        this.state={
            ...publicData,
            isUpDateHot:true,
            colName:'',
            tagsArr:[],
            vipList:[],
            playerArr:[{name:'全部', playerid:0}],

        }
    }

    columes = [
        {
            title:'cid',
            dataIndex:'cid',
        },
        {
            title:'标题',
            dataIndex:'title',
            render(text,record){
                return(
                    <a href={record.video} target="_blank">{text}</a>
                )
            }
        },
        {
            title:'封面',
            dataIndex:'thumb',
            render:text=><img src={text} style={{"width":"150px"}} alt="thumb" key={text}></img>
        },{
            title:'标签',
            dataIndex:'tags',
            render(text,record){
                const list = text.split(',')
                return(
                    <div>
                        {
                            list.map((item,index)=>{
                                return <Tag key={index} color="purple">{item}</Tag>
                            })
                        }
                    </div>
                )
            }
        },{
            title:'预告/完整',
            dataIndex:'isfree',
            render:(text, record, index)=>
                <Button
                    icon = {text === 1 ? 'fire': 'crown'}
                    type='default'
                    onClick={()=>this.changePyCFree(record.cid, index)}
                >{text === 1 ? '预告' : '完整'}</Button>

        },
        {
            title:'状态',
            dataIndex:'status',
            render:(text,record, index)=>
                <div>
                    <Button
                        type={text === 1 ? 'primary' : 'danger'}
                        onClick={this.changeStatus.bind(this, record.cid, index, record.status)}
                    >{text === 1 ? '在线' : '下线'}</Button>
                </div>

        },{
            title:'操作',
            dataIndex:'operation',
            render:(text, record, index)=>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button type='primary'  style={{"display":"block"}} onClick={this.showModal.bind(this, '1', record, index)}>修改</Button>
                        <Button type='danger'  style={{"display":"block", marginTop:'10px'}} onClick={()=>this.deleData(record.cid, index)}>删除</Button>
                    </div>
                ) : null
        }
    ]

    componentDidMount= async ()=>{
        let {colName, playerid, vid} = getParams(this);
        colName = decodeURIComponent(colName);
        await this.setState({
            colName:colName === 'undefined' ? '独家' : colName,
            playerid:playerid,
            vid:vid
        });
        this.getData();
        this.getPlayerList();
    }
    // 获取球星列表
    getPlayerList= async ()=>{
        let { playerid, playerArr} = this.state;
        try {
            const {data} = await request(api.getPlayerListTag, {}, 'get');
            if(data.stateCode === 200){
                playerArr = playerArr.concat(data.data)
                let name = '';
                if(playerid){
                    for(let i = 0; i<playerArr.length; i++){
                        if(playerArr[i]['playerid'] == playerid){
                            name = playerArr[i]['name']
                        } 
                    }
                }else{
                    name = playerArr[0]['name']
                }
                this.setState({ playerArr, starName:name})
            }
        } catch (error) {
            
        }
    }
    // 获取完整版列表
    getVipList = async(playerid)=>{
        if(playerid){
            const {data} = await request(tApi.getPlayerContentCid, {playerid:playerid, isfree:0}, 'get');
            if(data.data.length > 0){
                let vipList = [{cid:0,name:'暂不关联'}];
                vipList = vipList.concat(data.data);
                this.setState({
                    vipList
                })
            }
        }
    }
    // 获取数据
    getData=()=>{
        const {playerid, l} = this.state;
        this.getVipList(playerid);
        publicGetData(l, this, api.getClubPlayerContent, {playerid:playerid ? playerid : 0});
    }
    // 分页
    changePage = async (l) =>{
        await this.setState({ l });
        this.getData();
    }

    // 打开修改/新增弹窗
    showModal=(bType, record, index)=>{
        let {modelTitle, vipList} = this.state;
        if(bType === '1'){ 
            modelTitle = '修改视频数据';
            const {thumb, title, tags, video, cid, isfree, link_cid} = record;
            this.setState({
                pindex:index,
                thumbImgUrl:thumb,
                videoUrl:video,
                title,
                tagsArr: tags ? tags.split(',') : [],
                cid,
                isfree,
                vcid:link_cid
            });
            if(vipList.length > 0){
                for(let i=0;i<vipList.length;i++){
                    if(link_cid === vipList[i]['cid']){
                        this.setState({
                            vName:vipList[i]['name']
                        })
                    } 
                }
            }
        }
        else{ 
            modelTitle = '新增视频数据';
            this.clearState();
        };
        this.setState({
            visible : true,
            modelTitle:modelTitle,
            bType:bType
        })
    }
    okBtn=()=>{
        const {bType} = this.state;
        if(bType === '1'){ this.upData()}else{this.addData()};
    }
    // 添加数据
    addData=()=>{
        const {thumbFile, playerid, tagsArr, videoFileUrl, title, isfree, isUpDateHot, vcid } = this.state;
        const rdata = {
            playerid:playerid,
            title:title,
            media:videoFileUrl,
            thumb:thumbFile,
            tags:tagsArr.join(),
            isfree:isfree,
            link_cid:vcid
        };
        if( isUpDateHot && isfree === 1 ){
            const hotData = {
                c_type : 3,
                sub_type: 0,
                tags:tagsArr.join(),
                title:title,
                video:videoFileUrl,
                photo:thumbFile,
                thumb:thumbFile,
                pagedata : '{}',
                pagedata2 : '{}',
                pagetype : 0,
                pagetext : '点击查看完整版'
            }
            addData(this, papi.uploadHotspot, hotData, true)
        }
        // if(isfree === 0){
        //     const data = {
        //         playerid:playerid,
        //         media:videoFileUrl,
        //         thumb:thumbFile
        //     }
        //     addData(this, api.addPlayerMedias, data, true)
        // }
        addData(this,api.addClubContent,rdata);
    }
    //修改数据
    upData= async ()=>{
        const {cid ,thumbFile, videoFileUrl, pindex, dataArr, title, tagsArr, vcid} = this.state;
        const rdata = returnUpdateParams(dataArr[pindex], {},{
            title:title,
            tags:tagsArr.join(),
            media:videoFileUrl,
            thumb:thumbFile,
        });
        rdata['link_cid'] = vcid;
        rdata['cid'] = cid;
        if(rdata['cid']){
            const {data} = await request(api.changeClubPContent, rdata, 'post');
            if(data.message === 'SUCCESS'){
               message.success('修改成功!');
               this.setState({ visible:false, dataArr:dataArr });
               this.getData();
               this.clearState();
            }
        }else{
            message.warn('请输入需要修改的内容!')
        }
    }
    //删除数据
    deleData=(cid)=>{
        Modal.confirm({
            title: '删除球星内容',
            content: '确定要删除这条内容嘛?',
            okText: '确认',
            cancelText: '取消',
            onOk:async()=>{
                try {
                    const {data} = await request(api.deleteClubPlayercontent, {cid:cid}, 'post');
                    if( data.data === '删除成功' ){
                        message.success('删除成功!');
                        this.setState({dataArr:this.state.dataArr.filter(item => item.cid !== cid)});
                    }else{
                        message.error('删除失败, 请重试!');
                    }
                } catch (error) {
                    message.error('删除失败, 请重试!');
                }
            }
        })

    }
    // 交换位置
    tranClubContent = async () =>{
        const {m_cid, s_cid} = this.state;
        if( m_cid && s_cid){
            const {data} = await request(api.tranClubContent, {m_cid, s_cid}, 'post');
            if(data.data === '互换成功'){
                this.getData();
            }
        }else{
            message.warn('请输入需要交换内容的cid');
        }
    }
    // 改变状态
    changeStatus=async(cid, index, status)=>{
        const {dataArr} = this.state;
        const rdata = {
            cid:cid
        };
        if(status == 1){
            rdata['status'] = 2;
        }else{
            rdata['status'] = 1;
        };
        await request(api.changePyCState, rdata, 'post');
        message.success('修改成功!');
        dataArr[index]['status'] = rdata['status'];
        this.setState({ dataArr: dataArr});
    }
    // 修改预告/完整
    changePyCFree = async ( cid, index ) =>{
        try {
            const {data} = await request(api.changePyCFree, {cid:cid}, 'post');
            const { stateCode } = data;
            if( stateCode === 200){
                message.success('修改成功!');
                this.getData();
            }
        } catch (error) {
            
        }
    }
    // 关闭弹窗
    closeModal=()=>{
        this.setState({
            visible:false,
        })
        this.clearState();
    }
    // 获取上传图片数据
    getImgUrl=(file, url)=>{
        this.setState({thumbFile:file, thumbImgUrl:url});
    }
     // 删除图片
    deleThumbImg=()=>{
        this.setState({thumbImgUrl:'', thumbFile:''});
    }
    // 获取视频地址
    getVidUrl=(file, url)=>{
        this.setState({
            videoUrl:url,
            videoFileUrl:file
        })
        getVideoThumb(file, this);
    }
    // 删除视频
    deleVideo=()=>{
        this.setState({
            videoUrl:'',
            videoFileUrl:''
        })
    }

    // 添加标签
    addTags=(e)=>{
        let {tagsArr} = this.state;
        const tag = e.target.value;
        if(tag && tagsArr.indexOf(tag) === -1){
            tagsArr = tagsArr.concat(tag);
        };
        this.setState({ tagsArr:tagsArr, tags:''});
    }
    // 删除标签
    deleTags=(e)=>{
        this.setState({ tagsArr:this.state.tagsArr.filter(item => item !== e) });
    }

    clearState=()=>{
        this.setState({
            isUpDateHot:true,
            isfree:0,
            vName:'请选择需要关联的完整版'
        })
        clearState(this, ['videoUrl', 'videoFileUrl', 'thumbImgUrl', 'thumbFile', 'title'], 'str');
        clearState(this, ['tagsArr'], 'arr');
        clearState(this, ['cid', 'vcid'], 'num');
    }

    render(){
        const {starName,colName, isloading, dataArr,playerid, total, modelTitle, visible, complete, title, tags, tagsArr, isfree, bType, playerArr, l, isUpDateHot, vipList, vName} = this.state;
        const {videoUrl} = this.state;
        let {thumbImgUrl} = this.state;
        if(thumbImgUrl){  thumbImgUrl = thumbImgUrl.split(','); }else{ thumbImgUrl = []};
        return(
            <div>
                {
                    playerid ? 
                    <Button type='primary' style={{"display":"block",'float':'right','zIndex':'999'}} onClick={this.showModal.bind(this, 2)}>新增</Button> : null
                }
                <div style={{display:'flex', alignItems:'center'}}>
                    <Select value={starName} style={{width:'10%'}} onChange={async (e) =>{
                        await this.setState({
                            starName:playerArr[e]['name'],
                            playerid:playerArr[e]['playerid'],
                            l:1 
                        });
                        this.getData();
                    }}>
                        {
                            playerArr.length > 0 ?
                            playerArr.map((item, index)=>{
                                return(
                                    <Option key={index} title={item.name}>{item.name}</Option>
                                )
                            }) : null
                        }
                    </Select> 
                    <h1 style={{marginBottom:0, marginLeft:'10px'}}>{` -- ${colName}`}</h1>
                </div>
                    <Table
                        isloading={isloading}
                        rowKey={(record, index)=>index}
                        columns={this.columes}
                        dataSource={dataArr}
                        components={this.components}
                        scroll={{x:1000}}
                        pagination={{
                            total:total,
                            defaultPageSize:12,
                            onChange:this.changePage,
                            current:l
                        }}
                    />
                <Modal
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">标题</div>
                        <TextArea style={{ width: '70%' }} id="title" type="text" placeholder="请输入标题" value={title} onChange={(e)=>this.setState({ title: e.target.value})}/>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">标签</div>
                        <div style={{width:'40%'}}>
                            <Input  
                                style={{display:'block'}}
                                id="tags" 
                                type="text" 
                                autoComplete="off"
                                value={tags}
                                placeholder="请选择标签确认则回车"
                                onChange={(e)=>this.setState({tags:e.target.value})}
                                onPressEnter={this.addTags} />
                            <div>
                                {
                                    tagsArr ? 
                                    tagsArr.map((item, index)=>{
                                        return(
                                            <Tag key={index} closable onClose={(e)=>{e.preventDefault();this.deleTags(item);}}>{item}</Tag>
                                        )
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        bType === '1' ? null : 
                        <div className="inputBox clearfix">
                            <div className="inputName">状态</div>
                            <Radio.Group buttonStyle="solid" value={isfree} onChange={(e)=>this.setState({ isfree : e.target.value })}>
                                <Radio.Button value={0}>完整</Radio.Button>
                                <Radio.Button value={1}>预告</Radio.Button>
                            </Radio.Group>
                        </div>
                    }
                    {
                        isfree === 1 ? 
                        <div className="inputBox clearfix">
                            <div className="inputName">关联的完整版</div>
                            <Select value={vName} style={{width:'70%'}} placeholder='请选择需要关联的完整版' onChange={(e)=>this.setState({
                                vcid : vipList[e].cid,
                                vName : vipList[e].name
                            })}>
                                {
                                    vipList.length > 0 ?
                                    vipList.map((item, index)=>{
                                        return(
                                            <Option key={index}>
                                                {item.name}
                                            </Option>
                                        )
                                    }) : null 
                                }
                            </Select>
                        </div> : null
                    }
                    {
                        isfree === 1 && bType === 2? 
                        <div className="inputBox clearfix">
                            <div className="inputName">是否同步上传到热点</div>
                            <Radio.Group buttonStyle="solid" value={isUpDateHot} onChange={(e)=>this.setState({ isUpDateHot : e.target.value })}>
                                <Radio.Button value={true}>是</Radio.Button>
                                <Radio.Button value={false}>否</Radio.Button>
                            </Radio.Group>
                            <p className='starInfo'>1,只有当选择是预告的时候才会显示是否上传热点选项</p>
                            <p className='starInfo'>2,当前只会上传无类型的热点类型(无法跳转的类型),如果该热点内容需要进行跳转请前往热点后台进行修改</p>
                        </div> : null
                        
                    }
                    <div className="inputBox clearfix">
                        <div className="inputName">上传视频</div>
                        <AploadVideo
                            videoUrl = {videoUrl}
                            deleVideo = {this.deleVideo}
                            getVidUrl={this.getVidUrl}
                        ></AploadVideo>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">
                            <div>
                                <p> 视频封面 </p>
                                <p> (自动截取视频封面,可自行选择) </p>
                            </div>
                            <p>上传封面</p>
                        </div>
                        <AploadImg 
                            deleImg = {this.deleThumbImg}
                            imgUrl={thumbImgUrl} 
                            mulType={false}
                            getImgUrl={this.getImgUrl} 
                            completet = {complete}
                            />
                    </div>
                </Modal>
            </div>
        )
    }
}

export default starDetailPage;