import adPage from "../../../pages/publicPage/adPage/adPage";
import bannerPage from "../../../pages/publicPage/bannerPage/bannerPage";
import contentPage from "../../../pages/publicPage/contentPage/contentPage";
import followPage from "../../../pages/publicPage/followPage/followPage";
import hotVideoPage from "../../../pages/publicPage/hotVideoPage/hotVideoPage";
import matchPage from "../../../pages/publicPage/matchPage/matchPage";
import privatePage from "../../../pages/publicPage/privatePage/privatePage";
import researchPage from "../../../pages/publicPage/researchPage/researchPage";
import teachPage from "../../../pages/publicPage/teachPage/teachPage";

const publicRouter = [
    {
        path: '/banner', component: bannerPage, auth: true
    }, {
        path: '/hotVideo', component: hotVideoPage, auth: true
    }, {
        path: '/teachVideo', component: teachPage, auth: true
    }, {
        path: '/privateVideo', component: privatePage, auth: true
    }, {
        path: '/researchVideo', component: researchPage, auth: true
    }, {
        path: '/contentVideo', component: contentPage, auth: true
    }, {
        path: '/followVideo', component: followPage, auth: true
    }, {
        path: '/matchVideo', component: matchPage, auth: true
    }, {
        path: '/adPage', component: adPage, auth: true
    }
];

export default publicRouter;