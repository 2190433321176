import React, { Component } from 'react';
import './homeView.scss';
import { Layout, Menu, Icon, Button, Modal, Avatar, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import config from './../config/mainConfig';

const { Header, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;
const { confirm } = Modal;

const menuData = (
    <Menu
        items={[
            {
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                        1st menu item
                    </a>
                ),
            },
            {
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                        2nd menu item
                    </a>
                ),
            },
            {
                label: (
                    <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                        3rd menu item
                    </a>
                ),
            },
        ]}
    />
);



class homeView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            avatar: "",
            nickName: ""
        }
    }
    componentDidMount = () => {
        this.getUserInfo();
        console.log(menuData);
    }

    getUserInfo = () => {
        const { avatar, nickname } = JSON.parse(window.sessionStorage.getItem('userData'));
        this.setState({ avatar, nickName: nickname });

    }
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    loginOut = () => {
        confirm({
            title: '退出登录',
            content: '是否退出登录?',
            okText: '退出',
            cancelText: '取消',
            onOk: () => {
                window.sessionStorage.clear();
                window.location.reload()
            }
        })
    }

    render() {
        const mainConfig = config.mainConfig;
        const { avatar, nickName } = this.state;
        return (
            <Layout >
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                >
                    <div className="logo">
                        <span>{this.state.collapsed ? '' : 'MUHO后台管理系统'}</span>
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                        {
                            // 主标题渲染
                            mainConfig ?
                                mainConfig.map((item, index) => {
                                    return (
                                        <SubMenu
                                            style={{ display: item.hidden ? 'none' : 'block' }}
                                            key={`sub${item.id}`}
                                            title={<span><Icon type={item.icon} /><span>{item.title}</span></span>}>
                                            {
                                                // 副标题渲染
                                                item.component ?
                                                    item.component.map((item, index) => {
                                                        return (
                                                            <Menu.Item key={item.id}>
                                                                <Link to={`/${item.path}`}>
                                                                    <span>{item.title}</span>
                                                                </Link>
                                                            </Menu.Item>
                                                        )
                                                    }) : null
                                            }
                                        </SubMenu>
                                    )
                                }) : null
                        }
                    </Menu>
                </Sider>
                <Layout>
                    <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="div">
                            <Icon
                                className="trigger"
                                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                onClick={this.toggle}
                            />
                        </div>
                        <div className='home_info'>
                            <Avatar style={{width:'50px',height:'50px'}} src={avatar} size="large" />
                            <div className="home_info_nick">
                                <div className="home_info_nick_text">
                                    <p>管理员:{nickName}</p>
                                </div>
                                <div className="home_info_nick_btn" onClick={()=> this.loginOut()}>
                                    <span>退出登录</span>
                                </div>
                            </div>
                        </div >

                    </Header>
                    <Content style={{
                        margin: '24px 16px', padding: 24, background: '#fff', minHeight: 280,
                    }}
                    >
                        {/* 路由界面显示组件 */}
                        {this.props.children}
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default homeView;