export const returnData = (type) =>{
    let 
        sType = '',
        firstUrl = '',
        firstParams = '',
        twoUrl = '',
        twoParams = '',
        threeUrl = '',
        threeParams = '',
        fVal = '',
        sVal = '',
        tVal = '';
    switch(type){
        case 'teach':
            sType = 'three';
            firstUrl = 'getSubjectType';
            firstParams = 'type';
            twoUrl = 'getSubjectByType';
            twoParams = 'type';
            threeUrl = 'getSubjectBySid';
            threeParams = 'sid';
            fVal = '教学类型';
            sVal = '专辑';
            tVal = '集数';
                break;
        case 'paid':
            firstUrl = 'getPaidSubjectList';
            firstParams = 'sid';
            sType = 'two';
            twoUrl = 'getPaidSubjectcid';
            twoParams = 'sid';
            fVal = '付费专辑';
            sVal = '集数';
                break;
        case 'hot':
            firstUrl = 'getHotTag';
            firstParams = 'cid';
            sType = 'one';
            fVal = '热点标题';
                break;
        case 'star':
            sType = 'three';
            firstUrl = 'getPlayerListTag';
            firstParams = 'playerid';
            threeUrl = 'getPlayerContentCid';
            threeParams = 'playerid';
            fVal = '球星';
            tVal = '集数';
                break;
        default:
            return false;
    }
    return {
        sType,
        firstUrl,
        firstParams,
        twoUrl,
        twoParams,
        threeUrl,
        threeParams,
        fVal,
        sVal,
        tVal
    }
}