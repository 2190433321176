// 球星后台
// const getVipPlayer = "/admin/getVipPlayer"; //获取球星后台
const getVipPlayer = "/admin/getVipPlayer2"; //获取球星后台
const addVipPlayer = "/admin/addVipPlayer"; //添加球星数据
const editorPlayer = "/admin/editorPlayer"; //修改球星数据
const changePyState = "/admin/changePyState"; //改变状态
const tranplayer = "/admin/tranplayer"; //变换位置
const getPlayerColumn = "/admin/getPlayerColumn"; //获取球星总栏目
const addPlayerColumn = "/admin/addPlayerColumn"; //添加球星栏目
const revisePlayerColumn = "/admin/revisePlayerColumn"; //修改球星栏目
const getPlayerData = "/admin/getPlayerData"; //获取单个球星栏目
const addPlayerData = "/admin/addPlayerData"; //添加单个球星栏目
const revisePlayerData ="/admin/revisePlayerData"; //修改单个球星栏目

// 球星内容后台
const getPlayersContent = "/admin/getPlayersContent"; //获取单个栏目球星的内容
const addPlayersContent = "/admin/addPlayersContent"; //添加单个栏目球星的内容
const revisePlayersContent = "/admin/revisePlayersContent"; //修改单个栏目球星的内容


const getClubPlayerContent = "/admin/getClubPlayerContent";  //球星内容接口
const addClubContent= "/admin/addClubContent"; //添加球星内容
const changePyCState = "/admin/changePyCState"; //球星内容上线下线
const changePyCFree = "/admin/changePyCFree"; //设置球星内容是否是预告
const deleteClubPlayercontent = "/admin/deleteClubPlayercontent"; //删除球星内容
const changeClubPContent = "/admin/changeClubPContent"; //修改球星内容
const getPlayerListTag = "/admin/getPlayerListTag"; //获取球星列表
const tranClubContent = "/admin/tranClubContent"; //切换位置

// 球星后台(旧)
const getPlayerMedia  = "/admin/getPlayerMedia"; 
const addPlayerMedias  = "/admin/addPlayerMedias";

export default{
    // 球星后台
    getVipPlayer,
    addVipPlayer,
    editorPlayer,
    changePyState,
    tranplayer,
    getPlayerColumn,
    addPlayerColumn,
    getPlayerData,
    addPlayerData,
    revisePlayerData,
    revisePlayerColumn,
    //球星内容后台
    getPlayersContent,
    addPlayersContent,
    revisePlayersContent,
    getClubPlayerContent,
    addClubContent,
    changePyCState,
    changePyCFree,
    deleteClubPlayercontent,
    changeClubPContent,
    getPlayerListTag,
    tranClubContent,
    getPlayerMedia,
    addPlayerMedias 
}