import React, { useState, useEffect } from 'react';
import './VipModalComp.scss';
import { Modal, DatePicker, Radio } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import { VipOrderGid, VipOrderOidType, VipOrderType } from './../config';

const { RangePicker } = DatePicker;

const VipModalComp = (Props) => {
    const { visible, onGoSearch, onCancelModal } = Props;
    const [types, setTypes] = useState(-1);
    const [oidTypes, setOidTypes] = useState(-1);
    const [gid, setGid] = useState(1);
    const [timeE, setTimeE] = useState([]);
    const [time, setTime] = useState(null);

    useEffect(() => {
        if (timeE.length !== 0) {
            const json = {};
            for (let i = 0; i < timeE.length; i++) {
                json[i === 0 ? 'star' : 'end'] = timeE[i].unix();
            };
            setTime(json);
        }
    }, [timeE])

    return (
        <Modal
            title="条件筛选"
            centered={true}
            visible={visible}
            onOk={() => onGoSearch(types, oidTypes, gid, time)}
            onCancel={onCancelModal}
            okText="查询"
            cancelText="取消"
        >
            <section className='vipModal_item'>
                <p className='vipModal_title'>时间选择:</p>
                <RangePicker locale={locale} style={{ width: '100%' }} showTime={{
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                }} onOk={e => setTimeE(e)} />
            </section>
            <section className='vipModal_item'>
                <p className="vipModal_title">支付状态</p>
                <Radio.Group
                    buttonStyle="solid"
                    onChange={e => setTypes(e.target.value)}
                >
                    {VipOrderType.map(item => <Radio.Button key={item.id} value={item.value}>{item.name}</Radio.Button>)}
                </Radio.Group>
            </section>
            <section className='vipModal_item'>
                <p className="vipModal_title">订单类型</p>
                <Radio.Group
                    buttonStyle="solid"
                    onChange={e => setOidTypes(e.target.value)}
                >
                    {VipOrderOidType.map(item => <Radio.Button key={item.id} value={item.value}>{item.name}</Radio.Button>)}
                </Radio.Group>
            </section>
            {
                oidTypes === 1 ?
                    <section className='vipModal_item'>
                        <p className="vipModal_title">会员类型</p>
                        <Radio.Group
                            buttonStyle="solid"
                            onChange={e => setGid(e.target.value)}
                            defaultValue={1}
                        >
                            {VipOrderGid.map(item => <Radio.Button value={item.value} key={item.id}>{item.name}</Radio.Button>)}
                        </Radio.Group>
                    </section> : null
            }
        </Modal>
    )
};

export default VipModalComp;