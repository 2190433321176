import React, { Component } from 'react';
import './index.scss';
import { Input, Select, message, Result, Icon } from 'antd';
import { request } from '../../../static/js/utils';
import apis from './../../../static/js/apis';
import UserCardItem from './itmes/UserCardItem';
import UserPermissionsItem from './itmes/UserPermissionsItem';
import VipUserOrderComp from './components/VipUserOrderComp';

const api = apis.vipApis;
const wxApi = apis.weChatApis;
const { Option } = Select;
const { Search } = Input;
const msg = message;


export default class VipSearchPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "1",
            st: '',
            // userMid: 0,
            userMid: 133498,
            userData: [],
            userOrderData: [],
            chooseData: null,
            modalVisible: false,
            orderVisible: false,
            isFirst: true
        }
    }

    selectConfig = (
        <Select defaultValue="1" style={{ width: '100px' }} onChange={e => this.changetype(e)}>
            <Option value="1">用户名</Option>
            <Option value="2">手机号</Option>
            <Option value="3">mid</Option>
        </Select>
    )

    /**
     * 切换用户搜索类型
     * 1 --> 用户名
     * 2 --> 手机号
     * 3 --> mid
     */
    changetype = (type) => this.setState({ type });

    // 点击搜索
    clickGoSearch = async (st) => {
        const { type } = this.state;
        if (st) {
            const res = await request(api.searchMB, { type, st }, "get");
            const rData = res.data;
            const { message, stateCode, data } = rData;
            if (message === 'SUCCESS' && stateCode === 200) {
                this.setState({ userData: data, isFirst: false, st });
            };
        } else
            msg.warning('请输入要查询的内容');
    }

    /**
     * 提交会员权限
     * @param {Number} types
     * @param {Number} mb
     * @param {Number} mid 
     */
    submitUserPermissions = async (types, mb, mid) => {
        const { st } = this.state;
        if (types === 0 || mb === 0 || mid === 0) {
            msg.warning("请选择需要开通的会员");
        } else {
            const res = await request(api.vipfun, { types, mb, mid }, "get");
            const rData = res.data;
            const { message, stateCode, data } = rData;
            if (message === 'SUCCESS' && stateCode === 200) {
                this.setState({
                    modalVisible: false,
                    chooseData: null
                });
                msg.success(data.msg);
                this.clickGoSearch(st);
            };
        }
    }
    /**
     * 获取用户订单信息
     * @param {Number}} mid 
     */
    getUserOrder = async (mid) => {
        const res = await request(api.scrachOid, { userid: mid }, 'get');
        const rData = res.data;
        const { message, stateCode, data } = rData;
        if (message === "SUCCESS" && stateCode === 200) {
            this.setState({
                orderVisible: true,
                userOrderData: data
            })
        }
    }
    /**
     * 发送会员到期提醒推送
     * @param {Number} id 1 --> 球星会员 2 --> 教学会员
     */
    sendMessage = async (id) => {
        // ovAZ45ASDwkshySiZw0HE1IQy6Hc
        try {
            const vipJson = {
                name1: {
                    value: "球星季度会员"
                },
                date2: {
                    value: "2022-9-21"
                }
            };
            const vipData = {
                openid: "ovAZ45ASDwkshySiZw0HE1IQy6Hc",
                template_id: "xSxxMkxc7_CoKz1n_-qhIsET9DfsU7_J9pUlMZM9iUQ",
                page: "/pages/personalPage/vipPayPage/vipPayPage",
                type: "formal",
                data_json: JSON.stringify(vipJson)
            };
            const res = await request(wxApi.ssWXMessage, vipData, "get");

        } catch (error) {

        }
    }
    sendVipMessage = async () => {

    }
    render() {
        const { userData, chooseData, modalVisible, isFirst, orderVisible, userOrderData } = this.state;
        return (
            <div>
                <h1>会员用户查询</h1>
                <Search addonBefore={this.selectConfig} enterButton="搜索" onSearch={e => this.clickGoSearch(e)} />
                {
                    isFirst ? <Result icon={<Icon type="search" />} title="请输入要查询的内容" /> :
                        <main className="vipSearch_main">
                            {
                                userData.length > 0 ?
                                    userData.map((item, index) => <UserCardItem data={item} key={index} clickVipPermissions={(data) => this.setState({ chooseData: data, modalVisible: true })} onClickUserOrder={this.getUserOrder} />) : <Result title="查询的内容为空,请重新查询" />
                            }
                            {
                                chooseData ?
                                    <UserPermissionsItem data={chooseData} visible={modalVisible} onCancleModal={() => this.setState({ modalVisible: false, chooseData: null })} onSubmit={this.submitUserPermissions} onSendMessage={this.sendMessage} /> : null
                            }
                            <VipUserOrderComp visible={orderVisible} userOrderData={userOrderData} onCancelModal={() => this.setState({ orderVisible: false, userOrderData: [] })} />

                        </main>
                }
            </div>
        )
    }
}