import React, {Component} from 'react';
import {request, publicGetData, clearState, setData, getVideoThumb} from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import { Table ,Modal,Button,Input ,Tag,Select,message, Popconfirm} from "antd";
import AploadImg from '../../components/AploadImg/AploadImg';
import AploadVideo from '../../components/AploadVideo/AploadVideo';

const {TextArea} = Input;
const Option = Select.Option;
const api = apis.publicApis;

class privatePage extends Component{

    constructor(props){
        super(props);
        this.state={
            isloading:false,
            tagVisible:false,
            modelTitle:'',
            title:'',
            skillName:'',
            skill:0,
            sid:0,
            tags:'',
            thumbImgUrl:'',
            thumbFile:'',
            videoUrl:'',
            videoFileUrl:'',
            newTags:'',
            priTags:'',
            visible:false,
            skillData:[],
            priTagsData:[],
            cid:0,
            pindex:-1
        }
    }
    columes = [
        {
            title:'cid',
            dataIndex:'cid',
            width:100
        },{
            title:'标题',
            dataIndex:'title',
            width:400,
            render(text,record){
                return(
                    <a href={record.video} target="_blank">{text}</a>
                )
            }
        },{

        },{
            title:'封面图',
            dataIndex:'thumb',
            render:text=><img src={text} style={{"width":"200px"}} key={text}></img>
        },{
            title:'标签',
            dataIndex:'tags',
            width: 180, 
            render(text,record){
                const list = text.split(',')
                return(
                    <div>
                        {
                            list.map((item,index)=>{
                                return <Tag key={index} color="purple">{item}</Tag>
                            })
                        }
                    </div>
                )
            } 
        },{
            title:'操作',
            dataIndex:'operation',
            width: 100,
            render:(text, record, index)=>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button 
                            type='primary' 
                            btype='1' 
                            style={{"display":"block"}} 
                            sid={record.sid}
                            skill={record.skill}
                            onClick={this.showModal.bind(this, 1, record, index)}>修改</Button>
                        <Popconfirm
                            title="您确定要删除这条数据嘛?"
                            onConfirm={this.deletData.bind(this, record.cid)}
                            okText="确定"
                            cancelText="取消">
                            <Button type='danger' style={{"display":"block"}}>删除</Button>
                        </Popconfirm>
                    </div>
                ) : null
        }
    ]
    componentDidMount=()=>{
        // 获取初始数据
        this.getData();
        this.getSkAndTags();

    }
    // 获取数据
    getData= async (l)=>{
        publicGetData(l, this, api.getPrivateWorkouts);
    }
    // 获取私人训练教练标签和技术分类
    getSkAndTags= async ()=>{
        const p1 = request(api.getSkill, {}, 'get');
        const p2 = request(api.getPrivateWorkoutTags, {}, 'get');
        const [r1, r2] = await Promise.all([p1, p2]);
        this.setState({
            skillData:r1.data.data,
            priTagsData:r2.data.data
        })
    }
    // 打开修改/新增弹窗
    showModal=(bType, record, index)=>{
        let {modelTitle} = this.state;
        if(bType === 1){ 
            const state = setData(this, record);
            modelTitle = '修改私人训练';
            this.setState({
                ...state,
                priTags:record.tags.split(',')[0],
                pindex:index
            },()=> this.getEducationName());
        }
        else{ 
            modelTitle = '新增私人训练';
            this.clearState();
        };
        this.setState({
            visible : true,
            modelTitle:modelTitle,
            bType:bType
        })
    }
    okBtn=()=>{
        const {bType} = this.state;
        if(bType === 1){ this.upData()}else{this.addData()};
    }
    // 添加数据
    addData= async ()=>{
        const {title, tagsArr, thumbFile, videoFileUrl, skill} = this.state;
        const rdata = {
            sid:81,
            skill:skill,
            tags:tagsArr.join(),
            title:title,
            source : '',
            intro : '',
            link : '',
            tid:1,
            thumb:thumbFile,
            photo:thumbFile,
            video:videoFileUrl,
            download:videoFileUrl
        };
        if(videoFileUrl && thumbFile){
            try {
                const {data} = await request(api.uploadEducation, rdata, 'post');
                if(data.data === "添加成功"){
                    message.success('添加成功');
                    this.getData();
                    this.setState({visible:false});
                    this.clearState();
                }else{
                    message.error('上传内容不能为空!');
                }
            } catch (error) {
                message.error('上传失败,请重试!');
            }
        }else{
            message.error('上传内容不能为空!');
        }
    }
    // 修改数据
    upData= async ()=>{
        const {cid, pindex, dataArr, title, tagsArr, thumbFile, videoFileUrl,videoUrl, skill, thumbImgUrl} = this.state;
        let rdata = {
            cid:cid,
            sid:81,
            skill:skill,
            tags:tagsArr.join(),
            title:title,
            tid:1,
            thumb:thumbFile,
            photo:thumbFile
        };
        if(videoFileUrl){
            rdata['video'] = videoFileUrl;
            rdata['download'] = videoFileUrl;
        }
        const {data} = await request(api.reviseEducation, rdata, 'post');
        if(data.data === "修改成功"){
            message.success('修改成功!');
            dataArr[pindex]['title'] = title;
            dataArr[pindex]['tags'] = tagsArr.join();
            dataArr[pindex]['skill'] = skill;
            if(videoFileUrl){
                dataArr[pindex]['video'] = videoUrl;
            }
            this.setState({ visible:false, dataArr:dataArr});
            this.clearState();
        }
    }
    // 添加新的私人教练标签
    getNewTags= async (e)=>{
        const tagName = e.target.value;
        let {priTagsData} = this.state;
        let arr = [];
        for(let i in priTagsData){
            const val = priTagsData[i].tagName;
            arr.push(val);
        }
        if(tagName && arr.indexOf(tagName) === -1){
            const {data} = await request(api.addPrivateWorkoutsTags, {name:tagName}, 'post');
            if(data.message === "SUCCESS"){
                message.success('添加成功!');
                priTagsData = [...priTagsData, {tagId:data.data.tag_id,tagName:tagName}];
                this.setState({
                    priTagsData:priTagsData,
                    newTags:''
                })
            }
        }else{
            message.error('请不要重复添加!');
            this.setState({
                newTags:''
            })
        }
    }
    // 删除新私人训练标签
    delePriTag= async (id)=>{
        const {data} = await request(api.deletePrivateWorkoutsTags, {tag_id:id}, 'post');
        if(data.data === '删除成功'){
            message.success('删除成功!');
            this.getSkAndTags();
        }
    }
    // 删除数据
    deletData= async (cid)=>{
        const {data} = await request(api.deleteEducation, {cid:cid}, 'post');
        if(data.data === '删除成功'){
            message.success('删除成功!');
            this.setState({dataArr:this.state.dataArr.filter(item => item.cid !== cid)});
        }else{
            message.error('删除失败,请重试!');
        }
    }
    // 获取标题内容
    titleChange=(e)=>{
        this.setState({ title:e.target.value });
    }
    // 获取季度和技巧名字
    getEducationName=async()=>{
        const {sid, skill} = this.state;
        const {data} = await request(api.getEducationName,{sid:sid, skill:skill}, 'post');
        this.setState({
            sidName:data.data.sidName,
            skillName:data.data.skillName,
        })
    }
     // 获取skill
     getSkillSid=(e)=>{
        const {skillData} = this.state;
        this.setState({ 
            skillName:skillData[e].name,
            skill :skillData[e].sid
        });
    }
    // 获取私人教练标签
    getPriTags=(e)=>{
        const {priTagsData, tagsArr} = this.state;
        const tag = priTagsData[e].tagName;
        tagsArr[0] = tag;
        this.setState({
            priTags:tag,
            tagsArr:tagsArr
        })
    }
    // 添加标签
    addTags=(e)=>{
        let {tagsArr} = this.state;
        const tag = e.target.value;
        if(tag && tagsArr.indexOf(tag) === -1){
            tagsArr = tagsArr.concat(tag);
        };
        this.setState({ tagsArr:tagsArr, tags:''});
    }
    // 删除标签
    deleTags=(e)=>{
        this.setState({ tagsArr:this.state.tagsArr.filter(item => item !== e) });
    }
    // 获取上传图片数据
    getImgUrl=(file, url)=>{
        this.setState({thumbFile:file, thumbImgUrl:url});
    }
     // 删除图片
     deleImg=()=>{
        this.setState({thumbImgUrl:'', thumbFile:''});
    }
    // 获取视频地址
    getVidUrl=(file, url)=>{
        this.setState({
            videoUrl:url,
            videoFileUrl:file
        });
        getVideoThumb(file, this);
    }
    // 删除视频
    deleVideo=()=>{
        this.setState({
            videoUrl:'',
            videoFileUrl:''
        })
    }
    // 关闭弹窗
    closeModal=()=>{
        this.setState({
            visible:false,
        })
        this.clearState();
    }
     // 数据初始化
    clearState=()=>{
        clearState(this, ['title', 'tags', 'skillName', 'videoUrl', 'videoFileUrl', 'thumbImgUrl', 'thumbFile', 'priTags','newTags'], 'str');
        clearState(this, ['cid', 'skill'], 'num');
        clearState(this, ['tagsArr'], 'arr');
    }
    render(){
        const {isloading, dataArr, total, tagVisible, skillData, priTagsData, visible, modelTitle, bType, newTags} = this.state;
        const {cid,  title, tagsArr, skillName, priTags, tags, videoUrl, complete} = this.state;
        let {thumbImgUrl} = this.state;
        if(thumbImgUrl){  thumbImgUrl = thumbImgUrl.split(','); }else{ thumbImgUrl = []};
        return(
            <div>
                <h1 style={{display:'inline-block'}}>私人训练后台</h1>
                <Button type='primary' btype="2" style={{"display":"block",'float':'right','zIndex':'999'}} onClick={this.showModal}>新增</Button>
                <Button type='primary' style={{"display":"block",'float':'right','zIndex':'999',marginRight:"10px"}} onClick={()=>this.setState({tagVisible:true})}>新增标签</Button>
                <Table
                     loading={isloading}
                     rowKey={record=>record.cid}
                     columns={this.columes}
                     dataSource={dataArr}
                     scroll={{x:1000}}
                     pagination={{
                         total:total,
                         defaultPageSize:10,
                         onChange:this.getData
                     }}
                ></Table>
                <Modal
                    title='新增标签'
                    visible={tagVisible}
                    centered={true}
                    onOk={()=>this.setState({ tagVisible:false})}
                    onCancel={()=>this.setState({ tagVisible:false, newTags:''})}
                >
                    <div style={{"display":"block"}}>
                        <Input 
                            value={newTags}
                            type="text" 
                            style={{display:'block',marginBottom:"10px"}}
                            placeholder="输入标签并回车确认"
                            onChange={(e)=> this.setState({ newTags:e.target.value})}
                            onPressEnter={this.getNewTags} />
                        {
                            priTagsData.length > 0 ? 
                            priTagsData.map((item,index)=>{
                                return(
                                    <Tag 
                                        color="purple" 
                                        closable 
                                        key={index}
                                        onClose={(e)=>{e.preventDefault();this.delePriTag(item.tagId);}}>{item.tagName}</Tag>
                                )
                            }) : null
                        }
                    </div>
                </Modal>
                <Modal
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    {
                        bType == 1 ?
                        <div className="inputBox clearfix">
                            <div className="inputName">cid</div>
                            <Input id="cid" style={{width:'20%'}} type="text" value={cid} disabled/>
                        </div> : ''
                    }
                    <div className="inputBox clearfix">
                        <div className="inputName">标题</div>
                        <TextArea style={{ width: '70%' }} id="title" type="text" placeholder="请选择标题" value={title} onChange={this.titleChange}/>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">技巧类别</div>
                        <Select value={skillName} id="skill" style={{ width: '30%' }} onChange={this.getSkillSid}>
                            {
                                skillData.map((item, index)=>{
                                    return(
                                        <Option title={item.name} key={index}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">私人教练标签</div>
                        <Select value={priTags} style={{width:'40%'}} onChange={this.getPriTags}>
                            {
                                priTagsData.map((item, index)=>{
                                    return <Option title={item.tagName} key={index}>{item.tagName}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">标签</div>
                        <div style={{width:'40%'}}>
                            <Input  
                                style={{display:'block'}}
                                id="tags" 
                                type="text" 
                                autoComplete="off"
                                value={tags}
                                placeholder="请选择标签确认则回车"
                                onChange={(e)=>this.setState({tags:e.target.value})}
                                onPressEnter={this.addTags} />
                            <div>
                                {
                                    tagsArr ? 
                                    tagsArr.map((item, index)=>{
                                        return(
                                            <Tag key={index} closable onClose={(e)=>{e.preventDefault();this.deleTags(item);}}>{item}</Tag>
                                        )
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">视频</div>
                        <AploadVideo
                            videoUrl = {videoUrl}
                            deleVideo = {this.deleVideo}
                            getVidUrl={this.getVidUrl}
                        ></AploadVideo>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">
                            <p> 封面 </p>
                            <p> (自动截取视频封面,可自行选择) </p>
                        </div>
                        <AploadImg 
                            deleImg = {this.deleImg}
                            imgUrl={thumbImgUrl} 
                            mulType={false}
                            getImgUrl={this.getImgUrl}
                            completet = {complete}  />
                    </div> 
                    
                </Modal>
            </div>
        )
    }
}

export default privatePage;