import React, { Component } from 'react';
import './loginPage.scss';
import { Input, Button, message } from 'antd';
import apis from './../../static/js/apis';
import { request } from '../../static/js/utils';
const api = apis.publicApis;
const Msg = message;

class loginPage extends Component {
    enter = (e) => {
        const { keyCode } = e;
        const name = this.refs.name.input.value;
        const pass = this.refs.pass.input.value;
        if (keyCode === 13) {
            this.login(name, pass)
        }
    }

    goLogin = () => {
        const name = this.refs.name.input.value;
        const pass = this.refs.pass.input.value;
        this.login(name, pass)
    }

    login = async (name, pass) => {
        if (!name && !pass) {
            Msg.error('请输入账号密码');
        } else {
            window.sessionStorage.clear();
            const res = await request(api.login, {
                account: name,
                pw: pass
            }, 'post');
            const rData = res.data;
            const { data, message, stateCode } = rData;
            if (message === 'SUCCESS' && stateCode === 200) {
                window.sessionStorage.setItem('userData', JSON.stringify(data));
                window.sessionStorage.setItem('loginCode', 202169 + 38);
                this.props.history.push('/banner');
            } else {
                Msg.error(`账号密码错误,请重新确认账号密码`);
            }
        }
    }
    render() {
        return (
            <div className="login_Container">
                <div className="login_Wrap">
                    <h1>MUHO后台管理</h1>
                    <Input
                        ref="name"
                        type="text"
                        placeholder="用户名"
                        onKeyDown={this.enter} />
                    <Input
                        ref="pass"
                        type="password"
                        placeholder="密码"
                        onKeyDown={this.enter} />
                    <Button type="primary" onClick={this.goLogin}>
                        <span>登陆</span>
                    </Button>
                </div>
            </div>
        )
    }
}

export default loginPage