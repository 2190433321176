const VipOrderType = [
    {
        id: 1, value: 11, name: '全部'
    }, {
        id: 2, value: 0, name: '未支付'
    }, {
        id: 3, value: 1, name: '已支付'
    }, {
        id: 4, value: 4, name: '已取消'
    }
];
const VipOrderOidType = [
    {
        id: 3, value: 3, name: '全部'
    },{
        id: 1, value: 1, name: '会员'
    }, {
        id: 2, value: 2, name: '专辑'
    }
];
const VipOrderGid = [
    {
        id: 1, value: 1, name: '月费球星'
    }, {
        id: 2, value: 2, name: '年费球星'
    }, {
        id: 3, value: 3, name: '年费教学'
    }, {
        id: 4, value: 4, name: '季度教学'
    },
];

export {
    VipOrderGid,
    VipOrderOidType,
    VipOrderType
}