/* eslint-disable no-dupe-keys */
import React, { Component } from 'react'
import { Button, Table, Popconfirm, Modal, Input, message, Select, Icon } from 'antd';
import { WechatOutlined } from "@ant-design/icons";
import { request, publicGetData, setData, clearState, addData, deletData, getVideoThumb } from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import AploadImg from '../../components/AploadImg/AploadImg';
import AploadVideo from '../../components/AploadVideo/AploadVideo';
import Editor from './../../components/Editor/Editor';
const api = apis.teachApis;
const wxApi = apis.weChatApis;
const Option = Select.Option;

const string2Unicode = (name) => encodeURIComponent(name);


class albumPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cocahData: null,
            info_state: '',
            intro_state: '',
            isloading: false,
            visible: false,
            infoEditShow: false,
            introEditShow: false,
            modelTitle: '',
            complete: 0,
            name: '',
            intro: '',
            thumbImgUrl: '',
            thumbFile: '',
            price: '',
            priceX: '',
            SinPrice: '',
            cons: '',
            coachName: '请选择专辑教练'
        }
    }

    columes = [
        {
            title: '专辑名称(宣传视频)',
            dataIndex: 'name',
            render(text, record) {
                return (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={record.video} target="_blank">{text}</a>
                )
            }
        }, {
            title: '专辑教练',
            dataIndex: 'albumName',
            width: 100
        }, {
            title: '总集数',
            dataIndex: 'cons',
            width: 80
        }, {
            title: '封面图',
            dataIndex: 'thumb',
            width: 180,
            render: text => <img src={text} style={{ "width": "150px" }} alt="thumb" key={text}></img>
        }, {
            title: '总价',
            dataIndex: 'price',
            width: 80
        }, {
            title: '优惠价',
            dataIndex: 'priceX',
            width: 80
        }, {
            title: '单集价格',
            dataIndex: 'SinPrice',
            width: 80
        }, {
            title: '操作',
            dataIndex: 'operation',
            width: 100,
            render: (text, record, index) =>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button
                            type='primary'
                            style={{ "display": "block" }}
                            onClick={this.showModal.bind(this, '1', record, index)}>修改</Button>
                        <Popconfirm
                            title="您确定要删除这条数据嘛?"
                            onConfirm={this.deletData.bind(this, record.id)}
                            okText="确定"
                            cancelText="取消">
                            <Button type='danger' style={{ "display": "block" }}>删除</Button>
                        </Popconfirm>
                    </div>
                ) : null
        }, {
            title: '专辑内容',
            dataIndex: 'detail',
            render: (text, record, index) =>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button
                            type='primary'
                            onClick={() => this.props.history.push(`albumDetail?id=${record.id}&name=${string2Unicode(record.name)}`)}
                            style={{ "display": "block" }} >进入专辑内容</Button>
                    </div>
                ) : null
        }, {
            title: '推送',
            dataIndex: 'push',
            width: 200,
            render: (text, item) => <Button style={{ background: '#62b900', color: '#fff' }} onClick={this.pushWxMessage.bind(this, item)}><WechatOutlined />微信消息推送</Button>
        }

    ]

    componentDidMount = () => {
        // 获取初始数据
        this.getData();
        // 获取教练数据
        this.getCoach();
    }
    getData = (l) => {
        publicGetData(l, this, api.getTeachingAlbum, {});
    }
    getCoach = async (l) => {
        const { data } = await request(api.getCoachList, {}, 'get');
        if (data.message === "SUCCESS") {
            this.setState({ cocahData: data.data });
        };
    }
    // 打开修改/新增弹窗
    showModal = (bType, record, index) => {
        let { modelTitle } = this.state;
        if (bType === '1') {
            modelTitle = '修改专辑数据';
            const state = setData(this, record);
            this.setState({
                ...state,
                pindex: index
            });
            this.getAlbumCoach(record.coid);
        }
        else {
            modelTitle = '新增专辑数据';
            this.clearState();
        };
        this.setState({
            visible: true,
            modelTitle: modelTitle,
            bType: bType
        })
    }
    // 关闭弹窗
    closeModal = () => {
        this.setState({
            visible: false,
        })
        this.clearState();
    }
    okBtn = () => {
        const { bType } = this.state;
        if (bType === '1') { this.upData() } else { this.addData() };
    }
    // 新增专辑数据
    addData = () => {
        const { name, price, priceX, SinPrice, thumbFile, videoFileUrl, cons, coid, info, info_b, intro, intro_b } = this.state;
        let rdata = {
            name: name,
            coid: coid,
            thumb: thumbFile,
            intro: '',
            info: '',
            cons: cons,
            video: videoFileUrl,
            price: parseFloat(price).toFixed(2),
            priceX: parseFloat(priceX).toFixed(2),
            SinPrice: parseFloat(SinPrice).toFixed(2),
            info: info,
            info_b: JSON.stringify(info_b.toRAW(true)),
            intro: intro,
            intro_b: JSON.stringify(intro_b.toRAW(true))
        };
        addData(this, api.addTeachingAlbum, rdata);
    }
    // 修改专辑数据
    upData = async () => {
        const { id, name, price, priceX, SinPrice, thumbFile, videoFileUrl, cons, info, intro } = this.state;
        let { info_b, intro_b } = this.state;
        let rdata = {
            id: id,
            name: name,
            thumb: thumbFile,
            intro: '',
            info: '',
            cons: cons,
            price: parseFloat(price).toFixed(2),
            priceX: parseFloat(priceX).toFixed(2),
            SinPrice: parseFloat(SinPrice).toFixed(2),
            intro: intro,
            intro_b: intro_b
        };
        if (info_b) {
            if (typeof info_b !== 'string') {
                info_b = JSON.stringify(info_b.toRAW(true))
            }
            rdata['info'] = info;
            rdata['info_b'] = info_b
        }
        if (intro_b) {
            if (typeof intro_b !== 'string') {
                intro_b = JSON.stringify(intro_b.toRAW(true))
            }
            rdata['intro'] = intro;
            rdata['intro_b'] = intro_b
        }
        if (videoFileUrl) {
            rdata['video'] = videoFileUrl;
        }
        const { data } = await request(api.reviseTeachingAlbum, rdata, 'post');
        if (data.data === "修改成功") {
            message.success('修改成功!');
            this.setState({ visible: false });
            this.getData();
        }
    }
    //  删除数据
    deletData = async (id) => {
        deletData(this, { id: id }, api.deleteTeachingAlbum, 'id');
    }

    // 获取专辑教练
    getAlbumCoach = (coid) => {
        const { cocahData } = this.state;
        for (let i in cocahData) {
            if (coid === cocahData[i]['coid']) {
                this.setState({ coachName: cocahData[i]['name'], coid: coid })
            }
        }
    }
    /**
     * 微信订阅消息推送
     * @param {Object} item 内容
     */
    pushWxMessage = async (item) => {
        console.log(item);
        let { name, cons, id, albumName, price, priceX } = item;;
        name = JSON.stringify(name).replace(/\s*/g, "");
        name = name.slice(1, name.length - 1);
        const json = {
            thing7: {
                value: name.length > 20 ? `${name.slice(0, 17)}...` : name,
            }, thing15: {
                value: albumName,
            }, number16: {
                value: cons,
            }, thing9: {
                value: priceX < price ? `原价${price}元,限时优惠价${priceX}元` : `共${price}元`
            }
        };
        const data = {
            template_id: "ezJIXwvFl3tlJxzE1FimN4QjxZYHgKbbpHaOETqDYxU",
            page: `/pages/video/views/vipVideo/vipVideo?cid=0&sid=${id}`,
            type: "formal",
            data_json: JSON.stringify(json)
        };
        try {
            const res = await request(wxApi.sendWxMessages, data, "post");
            const { error, success } = res.data.data;
            message.success(`共推送成功${success}条,推送失败${error}条`);
        } catch (error) {
            message.error(`推送失败,${error}`);
        }
    }
    // 获取上传图片数据
    getImgUrl = (file, url) => {
        this.setState({ thumbFile: file, thumbImgUrl: url });
    }
    // 删除图片
    deleThumbImg = () => {
        this.setState({ thumbImgUrl: '', thumbFile: '' });
    }
    // 获取视频地址
    getVidUrl = (file, url) => {
        this.setState({
            videoUrl: url,
            videoFileUrl: file
        });
        // console.log(file);
        getVideoThumb(file, this);
    }
    // 删除视频
    deleVideo = () => {
        this.setState({
            videoUrl: '',
            videoFileUrl: ''
        })
    }
    clearState = () => {
        clearState(this, ['name', 'price', 'priceX', 'SinPrice', 'cons', 'coachName', 'videoUrl', 'videoFileUrl', 'thumbImgUrl', 'thumbFile'], 'str');
        clearState(this, ['info_b', 'info', 'intro', 'intro_b'], 'str');
        this.setState({ coachName: '请选择专辑教练' });
    }
    render() {
        const { isloading, total, dataArr, modelTitle, visible, cocahData, infoEditShow, introEditShow, bType } = this.state;
        const { name, price, priceX, SinPrice, cons, coachName, videoUrl, complete, info, info_b, intro, intro_b } = this.state;
        let { thumbImgUrl } = this.state;
        if (thumbImgUrl) { thumbImgUrl = thumbImgUrl.split(','); } else { thumbImgUrl = [] };
        return (
            <div>
                <h1 style={{ display: 'inline-block' }}>专辑后台</h1>
                <Button type='primary' style={{ "display": "block", 'float': 'right', 'zIndex': '999' }} onClick={this.showModal.bind(this, 2)}>新增</Button>
                <Table
                    isloading={isloading}
                    rowKey={record => record.id}
                    columns={this.columes}
                    dataSource={dataArr}
                    scroll={{ x: 1200 }}
                    pagination={{
                        total: total,
                        defaultPageSize: 8,
                        onChange: this.getData
                    }}
                />

                <Modal
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">专辑名字</div>
                        <Input style={{ width: '70%' }} id="title" type="text" placeholder="请输入专辑名字" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </div>
                    {
                        bType === 2 ?
                            <div className="inputBox clearfix">
                                <div className="inputName">专辑教练</div>
                                <Select value={coachName} style={{ width: '70%' }} onChange={(e) => this.getAlbumCoach(e)} onPopupScroll={(e) => console.log(e)}>
                                    {
                                        cocahData ?
                                            cocahData.map((item, index) => {
                                                return (
                                                    <Option key={item.coid} value={item.coid}>{item.name}</Option>
                                                )
                                            }) : null
                                    }
                                </Select>
                            </div> : null
                    }
                    <div className="inputBox clearfix">
                        <div className="inputName">总集数</div>
                        <Input style={{ width: '70%' }} id="cons" type="text" placeholder="请输入总集数" value={cons} onChange={(e) => this.setState({ cons: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">总价</div>
                        <Input style={{ width: '70%' }} id="price" type="text" placeholder="请输入总价" value={price} onChange={(e) => this.setState({ price: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">优惠价</div>
                        <Input style={{ width: '70%' }} id="priceX" type="text" placeholder="请输入优惠价" value={priceX} onChange={(e) => this.setState({ priceX: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">单集价格</div>
                        <Input style={{ width: '70%' }} id="SinPrice" type="text" placeholder="请输入单集价格" value={SinPrice} onChange={(e) => this.setState({ SinPrice: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">宣传视频</div>
                        <AploadVideo
                            videoUrl={videoUrl}
                            deleVideo={this.deleVideo}
                            getVidUrl={this.getVidUrl}
                        ></AploadVideo>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">
                            <p> 视频封面 </p>
                            <p> (自动截取视频封面,可自行选择) </p>
                        </div>
                        <AploadImg
                            deleImg={this.deleThumbImg}
                            imgUrl={thumbImgUrl}
                            mulType={false}
                            getImgUrl={this.getImgUrl}
                            completet={complete}
                        />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">专辑信息</div>
                        <Button type="primary" onClick={() => this.setState({ infoEditShow: true })}>编辑</Button>
                        {
                            info ?
                                <Icon style={{ lineHeight: '4vh', color: '#ff6700', marginLeft: '10px' }} type="check-circle" /> : null
                        }
                        <Editor
                            show={infoEditShow}
                            onCancel={() => this.setState({ infoEditShow: false })}
                            onChange={(e) => this.setState({ info_b: e })}
                            onGet={(data, json) => this.setState({ info_b: data, info: json })}
                            editorState={info_b}
                            phoneData={info}
                        />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">专辑短评</div>
                        <Button type="primary" onClick={() => this.setState({ introEditShow: true })}>编辑</Button>
                        {
                            intro ?
                                <Icon style={{ lineHeight: '4vh', color: '#ff6700', marginLeft: '10px' }} type="check-circle" /> : null
                        }
                        <Editor
                            show={introEditShow}
                            onCancel={() => this.setState({ introEditShow: false })}
                            onChange={(e) => this.setState({ intro_b: e })}
                            onGet={(data, json) => this.setState({ intro_b: data, intro: json })}
                            editorState={intro_b}
                            phoneData={intro}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

export default albumPage;