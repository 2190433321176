import React, { Component } from 'react'
import {Button, Table, Popconfirm, Modal, Input, message} from 'antd';
import {request, publicGetData, setData, clearState, addData, deletData} from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import AploadImg from '../../components/AploadImg/AploadImg';
const api = apis.teachApis;

class coachPage extends Component{
    constructor(props){
        super(props);
        this.state={
            isloading:false,
            visible:false,
            modelTitle:'',
            complete:0,
            name:'',
            intro:'',
            thumbImgUrl:'',
            thumbFile:''
        }
    }
    columes=[
        {
            title:'教练名字',
            dataIndex:'name'
        },{
            title:'教练介绍',
            dataIndex:'intro'
        },{
            title:'教练头像',
            dataIndex:'thumb',
            render:text=><img src={text} style={{"width":"100px"}} key={text} alt=""></img>
        },{
            title:'操作',
            dataIndex:'operation',
            width: 100,
            render:(text, record, index)=>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button 
                            type='primary' 
                            style={{"display":"block"}} 
                            onClick={this.showModal.bind(this, '1', record, index)}>修改</Button>
                        <Popconfirm
                            title="您确定要删除这条数据嘛?"
                            onConfirm={this.deletData.bind(this, record.coid)}
                            okText="确定"
                            cancelText="取消">
                            <Button type='danger' style={{"display":"block"}}>删除</Button>
                        </Popconfirm>
                    </div>
                ) : null
        }
        
    ]

    componentDidMount=()=>{
        // 获取初始数据
        this.getData();
    }
    getData=(l)=>{
        publicGetData(l, this, api.getCoach, {});
    }
     // 打开修改/新增弹窗
     showModal=(bType, record, index)=>{
        let {modelTitle} = this.state;
        if(bType === '1'){ 
            modelTitle = '修改教练数据';
            const state = setData(this, record);
            this.setState({
                ...state,
                pindex:index
            });
        }
        else{ 
            modelTitle = '新增教练数据';
            this.clearState();
        };
        this.setState({
            visible : true,
            modelTitle:modelTitle,
            bType:bType
        })
     }
     // 关闭弹窗
    closeModal=()=>{
        this.setState({
            visible:false,
        })
        this.clearState();
    }
    okBtn=()=>{
        const {bType} = this.state;
        if(bType === '1'){ this.upData()}else{this.addData()};
    }
    // 新增热点视频
    addData = ()=>{
        const {name, intro, thumbFile} = this.state;
        const rdata = {
            name:name,
            intro:intro,
            thumb:thumbFile
        };
        if(name && intro && thumbFile){
            addData(this, api.addCoach, rdata);
        }else{
            message.error('输入内容不能为空,请重试!');
        }
    }
    // 修改数据
    upData= async ()=>{
        const {dataArr ,coid, name, thumbFile, thumbImgUrl, intro, pindex} = this.state;
        let rdata = {
            coid:coid,
            name:name,
            intro:intro,
            thumb:thumbFile
        };
        const {data} = await request(api.reviseCoach, rdata, 'post');
        if(data.data === "修改成功"){
            message.success('修改成功!');
            dataArr[pindex]['name'] = name;
            dataArr[pindex]['intro'] = intro;
            dataArr[pindex]['thumb'] = thumbImgUrl;
            this.setState({ visible:false, dataArr:dataArr });
            this.clearState();
        }
    }
    //  删除数据
    deletData=async(coid)=>{
        deletData(this, {coid:coid}, api.deleteCoach, 'coid');
    }
    // 获取上传图片数据
    getImgUrl=(file, url)=>{
        this.setState({thumbFile:file, thumbImgUrl:url});
    }
     // 删除图片
     deleImg=()=>{
        this.setState({thumbImgUrl:'', thumbFile:''});
    }

    clearState=()=>{
        clearState(this, ['name', 'intro', 'thumbImgUrl', 'thumbFile'], 'str');
    }

    render(){
        const {isloading, total, dataArr, modelTitle, visible,} = this.state;
        const {name, intro} = this.state;
        let {thumbImgUrl} = this.state;
        if(thumbImgUrl){  thumbImgUrl = thumbImgUrl.split(','); }else{ thumbImgUrl = []};
        return(
            <div>
                <h1 style={{display:'inline-block'}}>教练后台</h1>
                <Button type='primary' style={{"display":"block",'float':'right','zIndex':'999'}} onClick={this.showModal.bind(this, 2)}>新增</Button>
                <Table
                    isloading={isloading}
                    rowKey={record=>record.coid}
                    columns={this.columes}
                    dataSource={dataArr}
                    scroll={{x:1000}}
                    pagination={{
                        total:total,
                        defaultPageSize:8,
                        onChange:this.getData
                    }}
                />
                <Modal
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">教练名字</div>
                        <Input style={{ width: '70%' }} id="title" type="text" placeholder="请输入教练名字" value={name} onChange={(e)=>this.setState({ name: e.target.value })}/>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">教练简介</div>
                        <Input style={{ width: '70%' }} id="intro" type="text" placeholder="请输入教练简介" value={intro} onChange={(e)=>this.setState({ intro: e.target.value })}/>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">
                            <p>教练头像</p>
                        </div>
                        <AploadImg 
                            deleImg = {this.deleImg}
                            imgUrl={thumbImgUrl} 
                            mulType={false}
                            getImgUrl={this.getImgUrl}  />
                    </div> 
                </Modal>
            </div>
        )
    }
}

export default coachPage;