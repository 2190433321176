import React, {Component} from 'react';
import {request, publicGetData, clearState, setData, getVideoThumb} from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import { Table ,Modal,Button,Input ,Tag,Select,message, Popconfirm} from "antd";
import AploadImg from '../../components/AploadImg/AploadImg';
import AploadVideo from '../../components/AploadVideo/AploadVideo';

const {TextArea} = Input;
const Option = Select.Option;
const api = apis.publicApis;

class teachPage extends Component{
    
    constructor(props){
        super(props)
        this.state={
            isloading:false,
            isPri:false,
            modelTitle:'',
            sidName:'',
            skillName:'',
            title:'',
            tags:'',
            thumbImgUrl:'',
            thumbFile:'',
            videoUrl:'',
            videoFileUrl:'',
            priTags:'',
            visible:false,
            skillData:[],
            seasonData:[],
            priTagsData:[],
            cid:0,
            sid:0,
            skill:0,
            pindex:-1
        }
    }

    componentDidMount=()=>{
        // 获取初始数据
        this.getData();
        this.getSkAndSea();
    }
    columes = [
        {
            title:'cid',
            dataIndex:'cid',
            width: 100, 
        },{
            title:'标题',
            dataIndex:'title',
            width:400,
            render(text,record){
                return(
                    <a href={record.video} target="_blank">{text}</a>
                )
            }
        },{
            title:'季度',
            dataIndex:'name',
            width: 100, 
        },{
            title:'标签',
            dataIndex:'tags',
            width: 180, 
            render(text,record){
                const list = text.split(',')
                return(
                    <div>
                        {
                            list.map((item,index)=>{
                                return <Tag key={index} color="purple">{item}</Tag>
                            })
                        }
                    </div>
                )
            }
        },{
            title:'封面图',
            dataIndex:'thumb',
            render:text=><img src={text} style={{"width":"200px"}} key={text}></img>
        },{
            title:'上传时间',
            dataIndex:'update_time',
            width:200
        },{
            title:'操作',
            dataIndex:'operation',
            width: 100,
            render:(text, record, index)=>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button 
                            type='primary'  
                            style={{"display":"block"}} 
                            onClick={this.showModal.bind(this, 1, record, index)}>修改</Button>
                        <Popconfirm
                            title="您确定要删除这条数据嘛?"
                            onConfirm={this.deletData.bind(this, record.cid)}
                            okText="确定"
                            cancelText="取消">
                            <Button type='danger' style={{"display":"block"}}>删除</Button>
                        </Popconfirm>
                    </div>
                ) : null
        }
    ]
    
    getData= async (l)=>{
        publicGetData(l, this, api.getEducation,{type:1});
    }
    // 获取技术分类和季度分类和私人教练标签
    getSkAndSea = async ()=>{
        const p1 =  request(api.getSeason,{},'get');
        const p2 =  request(api.getSkill,{},'get');
        const p3 = request(api.getPrivateWorkoutTags, {}, 'get');
        const [r1, r2, r3] = await Promise.all([p1, p2, p3]);
        this.setState({
            seasonData:r1.data.data,
            skillData:r2.data.data,
            priTagsData:r3.data.data
        })
    }

    // 打开修改/新增弹窗
    showModal=(bType, record, index)=>{
        let {modelTitle} = this.state;
        if(bType === 1){ 
            const state = setData(this, record);
            modelTitle = '修改教学';
            this.setState({
                ...state,
                priTags:record.tags.split(',')[0],
                pindex:index
            },()=> this.getEducationName());
        }
        else{ 
            modelTitle = '新增教学';
            this.clearState();
        };
        this.setState({
            visible : true,
            modelTitle:modelTitle,
            bType:bType
        })
    }
    okBtn=()=>{
        const {bType} = this.state;
        if(bType === 1){ this.upData()}else{this.addData()};
    }
    // 新增数据
    addData= async ()=>{
        const {title, tagsArr, thumbFile, videoFileUrl, sid, skill} = this.state;
        const rdata = {
            sid:sid,
            skill:skill,
            tags:tagsArr.join(),
            title:title,
            tid:1,
            thumb:thumbFile,
            photo:thumbFile,
            video:videoFileUrl,
        };
        if(videoFileUrl && thumbFile){
            try {
                const {data} = await request(api.uploadEducation, rdata, 'post');
                if(data.data === "添加成功"){
                    message.success('添加成功');
                    this.getData();
                    this.setState({visible:false});
                    this.clearState();
                }else{
                    message.error('上传内容不能为空!');
                }
            } catch (error) {
                message.error('上传失败,请重试!');
            }
        }else{
            message.error('上传内容不能为空!');
        }
        
    }
    // 修改数据
    upData= async ()=>{
        const {cid, pindex, dataArr, title, tagsArr, thumbFile, videoFileUrl,videoUrl, sid, skill, thumbImgUrl, sidName} = this.state;
        let rdata = {
            cid:cid,
            sid:sid,
            skill:skill,
            tags:tagsArr.join(),
            title:title,
            tid:1,
            thumb:thumbFile,
            photo:thumbFile
        };
        console.log(pindex);
        if(videoFileUrl){
            rdata['video'] = videoFileUrl;
            rdata['download'] = videoFileUrl;
        }
        const {data} = await request(api.reviseEducation, rdata, 'post');
        if(data.data === "修改成功"){
            message.success('修改成功!');
            dataArr[pindex]['title'] = title;
            dataArr[pindex]['tags'] = tagsArr.join();
            dataArr[pindex]['name'] = sidName;
            dataArr[pindex]['sid'] = sid;
            dataArr[pindex]['skill'] = skill;
            if(videoFileUrl){
                dataArr[pindex]['video'] = videoUrl;
            }
            this.setState({ visible:false, dataArr:dataArr});
            this.clearState();
        }

    }
    // 删除数据
    deletData= async (cid)=>{
        const {data} = await request(api.deleteEducation, {cid:cid}, 'post');
        if(data.data === '删除成功'){
            message.success('删除成功!');
            this.setState({dataArr:this.state.dataArr.filter(item => item.cid !== cid)});
        }else{
            message.error('删除失败,请重试!');
        }
    }
    // 获取季度和技巧名字
    getEducationName=async()=>{
        const {sid, skill} = this.state;
        const {data} = await request(api.getEducationName,{sid:sid, skill:skill}, 'post');
        let {isPri} = this.state;
        if(sid == 81){
            isPri = true;
        }else { isPri = false };
        this.setState({
            sidName:data.data.sidName,
            skillName:data.data.skillName,
            isPri:isPri
        })
    }
    // 关闭弹窗
    closeModal=()=>{
        this.setState({
            visible:false,
        })
        this.clearState();
    }
    // 获取sid
    getSeaSid=(e)=>{
        let {seasonData, isPri, priTags} = this.state;
        if(seasonData[e].sid == 81){
            isPri = true;
            priTags = '请选择私人教练';
        }else { isPri = false}
        this.setState({ 
            sidName:seasonData[e].name,
            sid :seasonData[e].sid,
            isPri:isPri,
            priTags:priTags
        });
    }
    // 获取skill
    getSkillSid=(e)=>{
        const {skillData} = this.state;
        this.setState({ 
            skillName:skillData[e].name,
            skill :skillData[e].sid
        });
    }
    // 获取标题内容
    titleChange=(e)=>{
        this.setState({ title:e.target.value });
    }
    // 获取私人教练标签
    getPriTags=(e)=>{
        const {priTagsData, tagsArr} = this.state;
        const tag = priTagsData[e].tagName;
        tagsArr[0] = tag;
        this.setState({
            priTags:tag,
            tagsArr:tagsArr
        })
    }
    // 添加标签
    addTags=(e)=>{
        let {tagsArr} = this.state;
        const tag = e.target.value;
        if(tag && tagsArr.indexOf(tag) === -1){
            tagsArr = tagsArr.concat(tag);
        };
        this.setState({ tagsArr:tagsArr, tags:''});
    }
    // 删除标签
    deleTags=(e)=>{
        this.setState({ tagsArr:this.state.tagsArr.filter(item => item !== e) });
    }
    // 获取上传图片数据
    getImgUrl=(file, url)=>{
        this.setState({thumbFile:file, thumbImgUrl:url});
    }
     // 删除图片
     deleImg=()=>{
        this.setState({thumbImgUrl:'', thumbFile:''});
    }
    // 获取视频地址
    getVidUrl=(file, url)=>{
        this.setState({
            videoUrl:url,
            videoFileUrl:file
        });
        getVideoThumb(file, this);
    }
    // 删除视频
    deleVideo=()=>{
        this.setState({
            videoUrl:'',
            videoFileUrl:''
        })
    }
    // 数据初始化
    clearState=()=>{
        this.setState({ isPri:false });
        clearState(this, ['title', 'tags','sidName', 'skillName', 'videoUrl', 'videoFileUrl', 'thumbImgUrl', 'thumbFile'], 'str');
        clearState(this, ['cid', 'sid', 'skill'], 'num');
        clearState(this, ['tagsArr'], 'arr');
    }
    render(){
        const {isloading, dataArr, total, modelTitle, visible, seasonData, skillData, isPri, priTagsData} = this.state;
        const {cid, sidName, skillName, title, tagsArr, tags, videoUrl, priTags, complete} = this.state;
        let {thumbImgUrl} = this.state;
        if(thumbImgUrl){  thumbImgUrl = thumbImgUrl.split(','); }else{ thumbImgUrl = []};
        return(
            <div>
                <h1 style={{display:'inline-block'}}>教学列表后台</h1>
                <Button type='primary' btype="2" style={{"display":"block",'float':'right','zIndex':'999'}} onClick={this.showModal}>新增</Button>
                <Table
                    loading={isloading}
                    rowKey={record=>record.cid}
                    columns={this.columes}
                    dataSource={dataArr}
                    scroll={{x:1200}}
                    pagination={{
                        total:total,
                        defaultPageSize:8,
                        onChange:this.getData
                    }}
                ></Table>
                <Modal
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">标题</div>
                        <TextArea style={{ width: '70%' }} id="title" type="text" placeholder="请选择标题" value={title} onChange={this.titleChange}/>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">季度</div>
                        <Select value={sidName} id="season" style={{ width: '30%' }} onChange={this.getSeaSid}>
                            {
                                seasonData.map((item, index)=>{
                                    return(
                                        <Option title={item.name} key={index}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">技巧类别</div>
                        <Select value={skillName} id="skill" style={{ width: '30%' }} onChange={this.getSkillSid}>
                            {
                                skillData.map((item, index)=>{
                                    return(
                                        <Option title={item.name} key={index}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    {
                        isPri ? 
                        <div className="inputBox clearfix">
                            <div className="inputName">私人教练标签</div>
                            <Select value={priTags} style={{width:'40%'}} onChange={this.getPriTags}>
                                {
                                    priTagsData.map((item, index)=>{
                                        return <Option title={item.tagName} key={index}>{item.tagName}</Option>
                                    })
                                }
                            </Select>
                        </div> : null
                    }
                    <div className="inputBox clearfix">
                        <div className="inputName">标签</div>
                        <div style={{width:'40%'}}>
                            <Input  
                                style={{display:'block'}}
                                id="tags" 
                                type="text" 
                                autoComplete="off"
                                value={tags}
                                placeholder="请选择标签确认则回车"
                                onChange={(e)=>this.setState({tags:e.target.value})}
                                onPressEnter={this.addTags} />
                            <div>
                                {
                                    tagsArr ? 
                                    tagsArr.map((item, index)=>{
                                        return(
                                            <Tag key={index} closable onClose={(e)=>{e.preventDefault();this.deleTags(item);}}>{item}</Tag>
                                        )
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">视频</div>
                        <AploadVideo
                            videoUrl = {videoUrl}
                            deleVideo = {this.deleVideo}
                            getVidUrl={this.getVidUrl}
                        ></AploadVideo>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">
                            <p> 封面 </p>
                            <p> (自动截取视频封面,可自行选择) </p>
                        </div>
                        <AploadImg 
                            deleImg = {this.deleImg}
                            imgUrl={thumbImgUrl} 
                            mulType={false}
                            getImgUrl={this.getImgUrl}
                            completet = {complete}  />
                    </div> 
                </Modal>
            </div>
        )
    }
}
export default teachPage;