import React from 'react';
import './VipOrderCountComp.scss';

/**
    "pmoid": 月费球星
    "pyoid": 年费球星,
    "stuffoid": 教学专辑,
    "tjoid": 季度教学,
    "total": 总共,
    "totalmeoney": 金额,
    "tyoid" 年费教学
 */

const VipOrderCountComp = (props) => {
    const { countData } = props;
    const { pmoid, pyoid, stuffoid, tjoid, total, totalmoney, tyoid } = countData;
    return (
        <section className='vipOrderCount_wrap'>
            <div className='vipOrderCount_item'>
                <p>{pmoid}</p>
                <p>月费球星</p>
            </div>
            <div className='vipOrderCount_item'>
                <p>{pyoid}</p>
                <p>年费球星</p>
            </div>
            <div className='vipOrderCount_item'>
                <p>{tjoid}</p>
                <p>季度教学</p>
            </div>
            <div className='vipOrderCount_item'>
                <p>{tyoid}</p>
                <p>年费教学</p>
            </div>
            <div className='vipOrderCount_item'>
                <p>{stuffoid}</p>
                <p>教学专辑</p>
            </div>
            <div className='vipOrderCount_item'>
                <p>{total}</p>
                <p>总订单数量</p>
            </div>
            <div className='vipOrderCount_item'>
                <p>{`${totalmoney}元`}</p>
                <p>总金额</p>
            </div>

        </section>
    )
};

export default VipOrderCountComp;