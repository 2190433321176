import React, { Component } from 'react';
import { Result, Icon, Button, Table, message } from 'antd';
import apis from './../../../static/js/apis';
import VipModalComp from './components/VipModalComp';
import { request, timestampToTime } from '../../../static/js/utils';
import VipCancelModal from './components/VipCancelModal';
import VipOrderCountComp from './components/VipOrderCountComp';

const api = apis.vipApis;
const Msg = message;
export default class VipOrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            cancelVisible: false,
            isFirst: true,
            chooseTime: '',
            l: 1,
            total: 0,
            index: 0,
            listData: [],
            reqData: null,
            userData: null,
            orderCount: null
        }
    }
    columes = [
        {
            title: '添加时间',
            dataIndex: 'addtime'
        }, {
            title: '订单名称',
            dataIndex: 'goods_name',
        }, {
            title: 'mid',
            dataIndex: 'mid'
        }, {
            title: '订单号',
            dataIndex: 'oid'
        }, {
            title: '订单类型',
            dataIndex: 'oid_type',
            render: text => <p>{text === 1 ? '会员' : '专辑'}</p>
        },
        {
            title: '支付方式',
            dataIndex: 'payway',
            render: text => <p>{text === 1 ? '支付宝' : '微信'}</p>
        }, {
            title: '价格',
            dataIndex: 'price'
        }, {
            title: '支付状态',
            dataIndex: 'status',
            render: text => <p>{text === 0 ? '未支付' : text === 1 ? '已支付' : text === 4 ? '已取消' : '未知状态'}</p>
        }, {
            title: '操作',
            dataIndex: 'operation',
            render: (text, record, index) =>
                record.status !== 4 ?
                    <Button type='primary' style={{ "display": "block" }} onClick={this.clickCancelOrderBtn.bind(this, record, index)}>取消订单</Button> : null
        }
    ]
    /**
     * 点击开始筛选
     * @param {Number} types 
     * @param {Number} oidTypes 
     * @param {Number} gid 
     * @param {Object} time 
     */
    goSearch = async (types, oidTypes, gid, time) => {
        if (types !== -1 && oidTypes !== -1 && time) {
            const { star, end } = time;
            const timestr = `${star},${end}`;
            const reqData = {
                type: types,
                timestr,
                oid_type: oidTypes,
                gid
            };
            await this.setState({ reqData, chooseTime: `${timestampToTime(star)} -- ${timestampToTime(end)}` });
            this.getOidCount(timestr);
            this.getData(1);
        } else {
            Msg.warning('请选择要筛选的内容');
        }
    }

    /**
     * 获取订单统计数量
     * @param {String} timestr 
     */
    getOidCount = async (timestr) => {
        const res = await request(api.getOidCount, { timestr }, 'get');
        const rData = res.data;
        const { message, stateCode, data } = rData;
        if (message === 'SUCCESS' && stateCode === 200) {
            this.setState({ orderCount: data });
        }
    }
    /**
     * 获取订单列表
     * @param {Number} l 
     */
    getData = async (l) => {
        const { reqData } = this.state;
        const res = await request(api.getOrderList, {
            l,
            ...reqData
        }, 'get');
        const rData = res.data;
        const { message, stateCode, data, data_num } = rData;
        if (message === 'SUCCESS' && stateCode === 200) {
            this.setState({
                listData: data,
                total: data_num,
                isFirst: false,
                modalVisible: false
            });
        };
    }

    /**
     * 打开取消订单modal
     * @returns 
     */
    clickCancelOrderBtn = (data, index) => {
        this.setState({ userData: data, cancelVisible: true, index });
    }
    cancelOrder = async (oid, index) => {
        const { listData } = this.state;
        if (oid) {
            const res = await request(api.cancelorder, { oid }, 'post');
            const rData = res.data;
            const { data, message, stateCode } = rData;
            if (message === "SUCCESS" && stateCode === 200) {
                Msg.success(data);
                listData[index].status = 4;
                this.setState({
                    cancelVisible: false,
                    userData: null,
                    listData
                });
            } else {
                Msg.warning(data);
            }
        }
    }

    render() {
        const { modalVisible, listData, total, isFirst, userData, cancelVisible, index, orderCount, chooseTime } = this.state;
        return (
            <div>
                {
                    isFirst ?
                        <Result
                            icon={<Icon type="search" />}
                            title="请筛选需要查询的内容"
                            extra={<Button type="primary" onClick={() => this.setState({ modalVisible: true })}>开始筛选</Button>}
                        /> :
                        <div>
                            {
                                orderCount ?
                                    <VipOrderCountComp countData={orderCount} /> : null
                            }
                            <p style={{ margin: '10px 0' }}>{`当前筛选的日期: ${chooseTime}`}</p>
                            <Button type='primary' onClick={() => this.setState({ modalVisible: true })}>筛选</Button>
                            <Table
                                rowKey={(record, index) => index}
                                columns={this.columes}
                                dataSource={listData}
                                scroll={{ x: 1200 }}
                                pagination={{
                                    total: total,
                                    defaultPageSize: 8,
                                    onChange: this.getData
                                }}
                            />
                        </div>
                }
                <VipModalComp visible={modalVisible} onGoSearch={this.goSearch} onCancelModal={() => this.setState({ modalVisible: false })} />
                {
                    userData ?
                        <VipCancelModal userData={userData} index={index} visible={cancelVisible} onCancelOrder={this.cancelOrder} onCancelModal={() => this.setState({ userData: null, cancelVisible: false })} /> : null
                }
            </div >
        )
    }
};
