import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Auth from './auth';
import routerConfig from './routerConfig';

export default class RouterMap extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Auth config={routerConfig} />
                </Switch>
            </BrowserRouter>
        )
    }
};