import React, { Component } from 'react';
import { Redirect, Route } from 'react-router';
import HomeView from '../../view/homeView/homeView';

export default class Auth extends Component {
    render() {
        const { location, config } = this.props;
        const { pathname } = location;
        //循环获取匹配当前路径的路由
        const targetRouterConfig = config.find(v => v.path === pathname);
        //获取登陆状态
        const loginCode = parseInt(window.sessionStorage.getItem('loginCode')) - 38;
        console.log(loginCode);
        if (targetRouterConfig && !targetRouterConfig.auth && loginCode !== 202169) {
            const { component } = targetRouterConfig;
            return <Route exact path={pathname} component={component} />
        }
        /**
         * 如果已经登陆了
         */
        if (loginCode === 202169) {
            /**
             * 判断路由中是否有这个页面
             */
            if (targetRouterConfig) {
                // 如果地址输入的是login 则直接跳转到banner页面
                if (pathname === '/login') {
                    return <Redirect to="/banner" />
                } else {
                    // 正常跳转
                    return <HomeView><Route exact pathname={pathname} component={targetRouterConfig.component} /></HomeView>
                }
            } else {
                /**
                * 如果路由中没有这个页面 则跳转到首页banner图
                */
                return <Redirect to="/banner" />
            }
        } else { //没有登陆则跳转到登陆界面
            return <Redirect to="/login" />
        }
    }
}