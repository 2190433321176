import React, { Component } from 'react';
import './App.scss';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import HomeView from './view/homeView/homeView'
import LoginPage from './../src/pages/loginPage/loginPage';
import RouterMap from './static/router';

class App extends Component {

  componentDidMount = () => {
    console.log('object');

  }

  render() {
    return (
      <RouterMap />
      // <BrowserRouter>
      //   <Switch>
      //     <Route path="/loginPage" component={LoginPage} />
      //     {
      //       parseInt(atob(window.sessionStorage.getItem('loginCode'))) - 38 === 202169 ?
      //         <Route path="/admin" component={HomeView} /> :
      //         <Redirect to='/loginPage' />
      //     }
      //     <Redirect from='/' to='/loginPage'></Redirect>
      //   </Switch>
      // </BrowserRouter>
    )
  }
}

export default App;
