import starDetailPage from "../../../pages/starPage/starDetailPage/starDetailPage";
import starPage from "../../../pages/starPage/starPage/starPage";

const starRouter = [
    {
        path: '/star', component: starPage, auth: true
    }, {
        path: '/starDetail', component: starDetailPage, auth: true
    }
];

export default starRouter;