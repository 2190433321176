/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
import React, { Component } from 'react'
import {Button, Table, Popconfirm, Modal, Input, message, Select, Tag, Radio} from 'antd';
import {request, publicGetData, setData, clearState, addData, deletData, getVideoThumb, getParams} from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import AploadImg from '../../components/AploadImg/AploadImg';
import AploadVideo from '../../components/AploadVideo/AploadVideo';
import Editor from './../../components/Editor/Editor';
const api = apis.teachApis;
const Option = Select.Option;
const {TextArea} = Input;

class albumDetailPage extends Component{
    constructor(props){
        super(props);
        this.state={
            isloading:false,
            visible:false,
            modelTitle:'',
            complete:0,
            title:'',
            intro:'',
            thumbImgUrl:'',
            thumbFile:'',
            albumName:'',
            isfree:1
        }
    }
    columes=[
        {
            title:'标题',
            dataIndex:'title',
            render(text,record){
                return(
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={record.video} target="_blank">{text}</a>
                )
            }
        },{
            title:'封面',
            dataIndex:'thumb',
            render:text=><img src={text} style={{"width":"150px"}} alt="thumb" key={text}></img>
        },{
            title:'标签',
            dataIndex:'tags',
            render(text,record){
                const list = text.split(',');
                return(
                    <div>
                        {
                            list.map((item,index)=>{
                                return <Tag key={index} color="purple">{item}</Tag>
                            })
                        }
                    </div>
                )
            }
        },{
            title:'状态',
            dataIndex:'isfree',
            render(text,record){
                let type = '';
                if(text == 1){
                    type='试看';
                }else{
                    type='会员'
                }
                return(
                    <Tag color={text == 1 ?'#2db7f5' : 'gold'}>{type}</Tag>
                )
            }
        },{
            title:'状态',
            dataIndex:'status',
            render:(text,record, index)=>
                <div>
                    <Button
                        type={text == 1 ? 'primary' : 'danger'}
                        onClick={this.changeStatus.bind(this, record.cid, index, record.status)}
                    >{text == 1 ? '在线' : '下线'}</Button>
                </div>

        },{
            title:'操作',
            dataIndex:'operation',
            render:(text, record, index)=>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button type='primary'  style={{"display":"block"}} onClick={this.showModal.bind(this, '1', record, index)}>修改</Button>
                    </div>
                ) : null
        }
    ]
    componentDidMount=()=>{
        const {id, name} = getParams(this);
        if(id){
            this.setState({
                sid:id,
                albumName:decodeURIComponent(name)
            },()=>this.getData())
        }else{
            this.props.history.push('album');
        }
    }
    getData=(l)=>{
        const {sid} = this.state;
        publicGetData(l, this, api.getTeachingAlbumDetail, {sid:sid});
    }
     // 打开修改/新增弹窗
     showModal=(bType, record, index)=>{
        let {modelTitle} = this.state;
        if(bType === '1'){ 
            modelTitle = '修改视频数据';
            const state = setData(this, record);
            state['thumbFile'] = '';
            this.setState({
                ...state,
                pindex:index
            });
        }
        else{ 
            modelTitle = '新增视频数据';
            this.clearState();
        };
        this.setState({
            visible : true,
            modelTitle:modelTitle,
            bType:bType
        })
    }
    // 关闭弹窗
    closeModal=()=>{
        this.setState({
            visible:false,
        })
        this.clearState();
    }
    okBtn=()=>{
        const {bType} = this.state;
        if(bType === '1'){ this.upData()}else{this.addData()};
    }
    // 新增专辑数据
    addData=()=>{
        const {sid, title, tagsArr, isfree, thumbFile, videoFileUrl} = this.state;
        const rdata = {
            sid:sid,
            tags:tagsArr.join(),
            title:title,
            thumb:thumbFile,
            video:videoFileUrl,
            intro:'123',
            isfree:isfree
        };
        addData(this,api.addTeachingAlbumDetail,rdata);
    }
    // 修改数据
    upData= async ()=>{
        const {cid, title, tagsArr, isfree, thumbFile, videoFileUrl, pindex, dataArr} = this.state;
        const rdata = {
            cid:cid,
            title:title,
            tags:tagsArr.join(),
            isfree:isfree,
        };
        if(thumbFile){
            rdata['thumb'] = thumbFile
        };
        if(videoFileUrl){
            rdata['video'] = videoFileUrl;
        }
        const {data} = await request(api.reviseTeachingAlbumDetail, rdata, 'post');
        if(data.data === '修改成功'){
            for(let i in rdata){
                if(i === 'thumb' || i === 'video'){
                    dataArr[pindex][i] = `https://m.muho.tv/${rdata[i]}`
                }else{
                    dataArr[pindex][i] = rdata[i]
                }
            }
            this.setState({ visible:false, dataArr:dataArr });
            this.clearState();
        }
    }
    // 改变状态
    changeStatus= async (cid, index, status)=>{
        const {dataArr} = this.state;
        const rdata = {
            cid:cid
        };
        if(status == 1){
            rdata['status'] = 2;
        }else{
            rdata['status'] = 1;
        };
        await request(api.reviseTeachingAlbumDetail, rdata, 'post');
        message.success('修改成功!');
        dataArr[index]['status'] = rdata['status'];
        this.setState({ dataArr: dataArr});
    }
    //  删除数据
    deletData=async(cid)=>{
        deletData(this, {cid:cid}, api.deleteTeachingAlbumDetail, 'cid');
    }
    // 添加标签
    addTags=(e)=>{
        let {tagsArr} = this.state;
        const tag = e.target.value;
        if(tag && tagsArr.indexOf(tag) === -1){
            tagsArr = tagsArr.concat(tag);
        };
        this.setState({ tagsArr:tagsArr, tags:''});
    }
    // 删除标签
    deleTags=(e)=>{
        this.setState({ tagsArr:this.state.tagsArr.filter(item => item !== e) });
    }
    // 获取上传图片数据
    getImgUrl=(file, url)=>{
        this.setState({thumbFile:file, thumbImgUrl:url});
    }
     // 删除图片
    deleThumbImg=()=>{
        this.setState({thumbImgUrl:'', thumbFile:''});
    }
    // 获取视频地址
    getVidUrl=(file, url)=>{
        this.setState({
            videoUrl:url,
            videoFileUrl:file
        })
        getVideoThumb(file, this);
    }
    // 删除视频
    deleVideo=()=>{
        this.setState({
            videoUrl:'',
            videoFileUrl:''
        })
    }
    clearState=()=>{
        clearState(this, ['title', 'tags', 'videoUrl', 'videoFileUrl', 'thumbImgUrl', 'thumbFile'], 'str');
        clearState(this, ['cid'], 'num');
        clearState(this, ['tagsArr'], 'arr');
        this.setState({ isfree:1 });
    }
    render(){
        const {isloading, total, dataArr, modelTitle, visible, albumName} = this.state;
        const {title, tags, tagsArr, isfree, videoUrl, complete} = this.state;
        let {thumbImgUrl} = this.state;
        if(thumbImgUrl){  thumbImgUrl = thumbImgUrl.split(','); }else{ thumbImgUrl = []};
        return(
            <div>
                <h1 style={{display:'inline-block'}}>{`专辑名:${albumName}`}</h1>
                <Button type='primary' style={{"display":"block",'float':'right','zIndex':'999'}} onClick={this.showModal.bind(this, 2)}>新增</Button>
                <Table
                    isloading={isloading}
                    rowKey={record=>record.cid}
                    columns={this.columes}
                    dataSource={dataArr}
                    scroll={{x:1000}}
                    pagination={{
                        total:total,
                        defaultPageSize:8,
                        onChange:this.getData
                    }}
                />
                <Modal
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">标题</div>
                        <TextArea style={{ width: '70%' }} id="title" type="text" placeholder="请选择标题" value={title} onChange={(e)=>this.setState({ title: e.target.value})}/>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">标签</div>
                        <div style={{width:'40%'}}>
                            <Input  
                                style={{display:'block'}}
                                id="tags" 
                                type="text" 
                                autoComplete="off"
                                value={tags}
                                placeholder="请选择标签确认则回车"
                                onChange={(e)=>this.setState({tags:e.target.value})}
                                onPressEnter={this.addTags} />
                            <div>
                                {
                                    tagsArr ? 
                                    tagsArr.map((item, index)=>{
                                        return(
                                            <Tag key={index} closable onClose={(e)=>{e.preventDefault();this.deleTags(item);}}>{item}</Tag>
                                        )
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">状态</div>
                        <Radio.Group buttonStyle="solid" value={isfree} onChange={(e)=>this.setState({ isfree : e.target.value })}>
                            <Radio.Button value={1}>试看</Radio.Button>
                            <Radio.Button value={0}>会员</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">宣传视频</div>
                        <AploadVideo
                            videoUrl = {videoUrl}
                            deleVideo = {this.deleVideo}
                            getVidUrl={this.getVidUrl}
                        ></AploadVideo>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">
                            <p> 视频封面 </p>
                            <p> (自动截取视频封面,可自行选择) </p>
                        </div>
                        <AploadImg 
                            deleImg = {this.deleThumbImg}
                            imgUrl={thumbImgUrl} 
                            mulType={false}
                            getImgUrl={this.getImgUrl} 
                            completet = {complete}
                            />
                    </div> 
                </Modal>
            </div>
        )
    }
}

export default albumDetailPage;