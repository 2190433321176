import React, {Component} from 'react';
import * as qiniu from 'qiniu-js';
import './AploadVideo.scss';
import { Progress, Button,message, Spin, Icon } from 'antd';
import axios from 'axios';
import {request} from './../../../static/js/utils';
import apis from './../../../static/js/apis'
import qs  from 'qs';
const api = apis.utilApis

class AploadVideo extends Component{

    constructor(props){
        super(props)
        this.state={
            complete:0,
            isReq:false,
            s:''
        }
    }

    submitFile= async ()=>{
        this.qiniuUpload();
    }

    qiniuUpload= async ()=>{
        const files = this.uploadfile.files[0];
        const {size} = files;
        const s = (size / (1024*1024)).toFixed(2);
        const {data} = await request(api.getqiniuToken, {filename:files.name}, 'post');
        this.setState({ isReq:true, s:s });
        const {filename, token} = data.data;
        const blob = new Blob([files]);
        const observable = qiniu.upload(blob,filename,token,{fname:files.name,params:{},mimeType:null}, {useCdnDomain:true});
        this.subscription = observable.subscribe({
            next:(res)=>{
                const {percent} = res.total;
                this.setState({
                    complete:parseInt(percent)
                })
            },
            error:(res)=>{
            },
            complete:(res)=>{
                this.props.getVidUrl(res.key, 'http://m.muho.tv/'+res.key);
                this.setState({
                    isReq:false
                })
            }
        });
    }

    stopUpload=()=>{
       this.subscription.unsubscribe();
       this.setState({ isReq:false, complete:0 })
    }
    render(){
        const {videoUrl} = this.props;
        const {complete, isReq, s} = this.state;
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        return(
            <div className="upload_Container">
                {
                    videoUrl ? 
                    <div style={{width:'300px',position:'relative'}}>
                        <span className="deletStyle" onClick={()=>this.props.deleVideo()}>删除</span>
                        <video 
                            src={videoUrl} 
                            width="100%" 
                            height="200px" 
                            controls={true}
                            autoPlay={false}></video>
                        {
                            s ? 
                            <div className="imgSize">视频大小:{s}m</div> : null
                        }
                    </div> : null
                }
               {
                   videoUrl ? null :
                    <div>
                        {
                            isReq ? 
                            <div className="loadVideo">
                                <Button 
                                    type='danger'
                                    className="upload-btn"
                                    onClick={this.stopUpload}>
                                        停止上传
                                </Button>
                            </div> : 
                            <div className="loadVideo">
                                <input  className="fileVideo"
                                        type="file"
                                        id="file"
                                        onChange={this.submitFile}
                                        ref={el => this.uploadfile = el}
                                        accept="video/*" />
                                <Button 
                                    type='primary'
                                    className="upload-btn">
                                        上传视频
                                </Button>
                            </div>
                        }
                    </div>
                    
               }
                {
                    isReq ? 
                    <div>
                        <Progress percent={complete}></Progress> 
                        <Spin indicator={antIcon} />
                    </div> : null
                }
                
            </div>
        )
    }
}

export default AploadVideo;