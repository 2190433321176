const base = "/admin";

// 教学标签
const getSubjectType = base + "/getSubjectType"; //获取教学类型
const getSubjectByType = base + "/getSubjectByType"; //获取类型获取数据
const getSubjectBySid = base + "/getSubjectBySid"; //根据sid获取cid

// 付费教学
const getPaidSubjectList = base + "/getPaidSubjectList"; //获取付费教学列表
const getPaidSubjectcid = base + "/getPaidSubjectcid"; //根据sid获取cid

//球星
const getPlayerListTag = base + "/getPlayerListTag"; //获取球星列表
const getPlayerContentCid = base + "/getPlayerContentCid"; //获取球星内容

// 热点
const getHotTag = base + "/getHotTag"; //获取热点列表

export default {
    getSubjectType,
    getSubjectByType,
    getSubjectBySid,
    getPaidSubjectList,
    getPaidSubjectcid,
    getPlayerContentCid,
    getPlayerListTag,
    getHotTag
}
