import {request} from './../../../static/js/utils';
import * as qiniu from 'qiniu-js';
import apis from './../../../static/js/apis';
import React, {Component} from 'react';
import './AploadImg.scss';
import {
    Progress, Button,message
  } from 'antd';
const api = apis.utilApis;

class AploadImg extends Component{

    constructor(props){
        super(props)
        this.state={
            complete:0,
            s:'',
            isReq:false
        }
    }

    submitFile=()=>{
        const {imgUrl} = this.props;
        const uploadfile = this.uploadfile.files;
        const length = parseInt(imgUrl.length);
        if(uploadfile.length === 1){
            this.qiniuUpload(uploadfile[0]);
        }else{
            if(length + uploadfile.length > 9){
                message.error('上传图片数量不能大于9张,请重试!');
            }else{
                for(let i=0;i<uploadfile.length;i++){
                    setTimeout(()=>{
                        this.qiniuUpload(uploadfile[i])
                    },1000 * i)
                    
                }
            }
        }
    }

    qiniuUpload = async (files)=>{
        const {size} = files;
        const s = (size / (1024*1024)).toFixed(2);
        const {data} = await request(api.getqiniuToken, {filename:files.name}, 'post');
        this.setState({ isReq:true,s:s });
        const {filename, token} = data.data;
        const blob = new Blob([files]);
        const observable = qiniu.upload(blob,filename,token,{fname:files.name,params:{},mimeType:null}, {useCdnDomain:true});
        this.subscription = observable.subscribe({
            next:(res)=>{
                const {percent} = res.total;
                this.setState({ complete:parseInt(percent) });
            },
            error:(res)=>{
                
            },
            complete:(res)=>{
                this.props.getImgUrl(res.key, 'https://m.muho.tv/'+res.key);
                this.setState({
                    isReq:false
                });
            }
        });
    }

    deleImg=(index)=>{
       this.props.deleImg(index);
    }

    render(){
        const {imgUrl, mulType, completet} = this.props;
        const {complete, isReq, s} = this.state;
        return(
            <div className="upload_Container">
                <div style={{display:'flex',width:'100%',flexWrap:'wrap'}}>
                {
                    imgUrl.length > 0 ? 
                    imgUrl.map((item, index)=>{
                        return(
                            <div  key={index} style={{width:'130px',position:'relative'}}>
                                <span className="deletStyle" onClick={this.deleImg.bind(this,index)}>删除</span>
                                <img src={item} width="100%" alt=""/>
                                {
                                    s ? 
                                    <div className="imgSize">图片大小:{s}m</div> : null
                                }
                            </div>
                        )
                    }) : null
                } 
                </div>
                {
                    imgUrl.length >= 9 ? null : 
                    <div className="loadVideo">
                        <input  className="fileVideo"
                                type="file"
                                id="file"
                                onChange={this.submitFile}
                                multiple={mulType}
                                ref={el => this.uploadfile = el}
                                accept="image/*" />
                        <Button 
                            type='primary'
                            className="upload-btn">
                                上传图片
                        </Button>
                    </div>
                }
                {
                    isReq ? <Progress percent={complete}></Progress> : null
                }
                {
                    completet ? <Progress percent={completet}></Progress>:null
                }
            </div>
        )
    }
}

export default AploadImg;