const getShoppingTag = "/shop/getShoppingTag"; //获取商品类目
const getShoppingListBytid = "/admin/getShoppingListBytid";  //获取商品列表


const addCommodity = "/admin/addCommodity"; //增加商品
const deleteCommodity = "/admin/deleteCommodity"; //删除商品
const reviseCommodity = "/admin/reviseCommodity"; //修改商品


const getShopsParams = "/admin/getShopsParams"; //获取商品规格
const addShopParams = "/admin/addShopParams"; //添加商品规格
const reviseShopsParams = "/admin/reviseShopsParams"; //修改商品规格
const deleteShopParams = "/admin/deleteShopParams"; //删除商品规格

const addShopTags = "/admin/addShopTags"; //增加商品分类
const deleteShopTag = "/admin/deleteShopTag"; //删除商品分类
const reviseShopTag = "/admin/reviseShopTag"; //修改商品分类



// 订单列表
const getOrderList = "/admin/getOrderList"; //获取订单列表
const getOrderTypes = "/admin/getOrderTypes"; //获取订单类型
const sendExpress = "/admin/sendExpress"; //发货
export default {
    getShoppingTag,
    getShoppingListBytid,
    addShopTags,
    deleteShopTag,
    reviseShopTag,
    deleteCommodity,
    addCommodity,
    reviseCommodity,
    getShopsParams,
    addShopParams,
    deleteShopParams,
    reviseShopsParams,
    getOrderList,
    getOrderTypes,
    sendExpress
}