import VipOrderPage from "../../../pages/vipPage/vipOrderPage/VipOrderPage";
import VipSearchPage from "../../../pages/vipPage/vipSearchPage/VipSearchPage";

const vipRouter = [
    {
        path: '/vipSearch', component: VipSearchPage, auth: true
    }, {
        path: '/vipOrderList', component: VipOrderPage, auth: true
    },
];

export default vipRouter;