import React from 'react';
import { Card, Icon, Tag } from 'antd';

const UserCardItem = (props) => {
    const { data, clickVipPermissions, onClickUserOrder } = props;
    const { avatar, login_type, mid, mobile, nickname, playerVip, subjectVip } = data;
    return (
        <Card
            hoverable
            style={{ width: 220, float: 'left', marginRight: 10, marginBottom: 10 }}
            cover={
                <img src={avatar} alt="" />
            }
            actions={[
                <Icon type="setting" key="setting" onClick={() => clickVipPermissions(data)} />,
                <Icon type="search" key="search" onClick={() => onClickUserOrder(mid)} />
            ]}
        >
            <Tag style={{ marginBottom: 10 }} color="volcano">{login_type === 1 ? '手机号' : login_type === 2 ? "微博" : login_type === 3 ? "微信登陆" : login_type === 4 ? 'qq登陆' : login_type === 5 ? "账号登陆" : '未知登录'}</Tag>
            <p><Tag color="geekblue">mid:</Tag> {mid}</p>
            <p><Tag color="geekblue">姓名:</Tag> {nickname}</p>
            <p><Tag color="geekblue">手机号:</Tag> {mobile ? mobile : '暂无手机号'}</p>
            <p><Tag color="geekblue">球星会员:</Tag> {playerVip.status ? playerVip.data.expirty_datetime : playerVip.msg}</p>
            <p><Tag color="geekblue">教学会员:</Tag> {subjectVip.status ? subjectVip.data.expirty_datetime : subjectVip.msg}</p>

        </Card>
    )
};


export default UserCardItem;