/**
 * 群发推送消息
 */
const sendWxMessages = "/sendWXMessages";

/**
 * 单推推送消息
 */
const ssWXMessage = "/ssWXMessage";

export default {
    sendWxMessages,
    ssWXMessage
}