/**
 * 用户搜索
 */
const searchMB = "/admin/searchMB";
/**
 * 用户会员权限操作
 */
const vipfun = "/admin/vipfun";
/**
 * 查询会员订单
 */
const getOrderList = "/admin/getOrderList";

/**
 * 取消订单
 */
const cancelorder = "/admin/cancelorder";

/**
 * 获取订单总数(统计)
 */
const getOidCount = "/admin/getOidCount"

/**
 * 获取用户订单信息
 */
const scrachOid = '/admin/scrachOid';

export default {
    searchMB,
    vipfun,
    getOrderList,
    cancelorder,
    getOidCount,
    scrachOid
}