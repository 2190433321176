import apis from './apis';
import axios from 'axios';
import qs from 'qs';
import { message } from 'antd';
// const BASE_API = "http://192.168.2.4:3002/api";
// const BASE_API = "http://192.168.3.4:3002/api";
// const BASE_API = "http://192.168.1.101:3002";
// const BASE_API = "https://test.muho.tv/api";
// const BASE_API = "https://x.muho.tv/api"
const BASE_API = "https://www.muho.tv/api";
const uapi = apis.utilApis;

export const publicData = {
    isloading: false,
    visible: false,
    bType: 0,
    modelTitle: '',
    complete: 0,
    name: '',
    thumbImgUrl: '',
    thumbFile: '',
    videoUrl: '',
    videoFileUrl: '',
    pindex: -1,
    l: 1
}


// 封装请求
export const request = (url, data, method, headData) => {
    let rdata = data;
    let userData = null;
    const seesData = window.sessionStorage.getItem('userData');
    if (seesData) {
        userData = JSON.parse(window.sessionStorage.getItem('userData'));
    };
    if (method === 'post') {
        rdata = qs.stringify(data);
        const promise = axios[method](
            BASE_API + url,
            rdata,
            {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    ...headData,
                    "token": userData !== null ? userData.token : 0,
                    "user-id": userData !== null ? userData.mid : 0
                }
            }
        );
        return promise;
    } else if (method === 'get') {
        const promise = axios[method](
            BASE_API + url,
            {
                params: rdata,
                headers: {
                    ...headData,
                    "token": userData !== null ? userData.token : 0,
                    "user-id": userData !== null ? userData.mid : 0
                }
            }
        );
        return promise;
    };
}

// 公共获取初始数据和翻页数据
export const publicGetData = async (l, _this, api, rdata) => {
    let pdata = {}
    if (!l) { l = 1 };
    if (rdata) {
        pdata = { l: l, ...rdata }
    } else {
        pdata = { l: l }
    }
    _this.setState({ isloading: true });
    const { data } = await request(api, pdata, 'get');
    if (data.msg === "SUCCESS" || data.message === "SUCCESS") {
        let setData = { dataArr: data.data, isloading: false };
        if (data.datas_num || data.data_num) { setData['total'] = data.datas_num || data.data_num };
        _this.setState(setData);
    };
}

// 循环清空state(string类型)
export const clearState = (_this, data, type) => {
    let rdata = {};
    let content;
    if (type === 'str') {
        content = '';
    } else if (type === 'num') {
        content = 0;
    };
    for (let i in data) {
        let title = data[i];
        if (type === 'arr') {
            rdata[title] = [];
        } else {
            rdata[title] = content
        };
        _this.setState(rdata);
    }
}


// 循环保存state
export const setData = (self, data) => {
    let state = {};
    // eslint-disable-next-line no-unused-vars
    for (let i in data) {
        if (i === 'tags') {
            state['tagsArr'] = data[i].split(',');
        } else if (i === 'thumb') {
            state['thumbImgUrl'] = data[i];
            state['thumbFile'] = data[i].split('/')[3];
        } else if (i === 'video') {
            state['videoUrl'] = data[i];
        } else if (i === 'pagedata' || i === 'pagedata2') {
            state[i] = JSON.stringify(data[i]);
        }
        else {
            state[i] = data[i];
        }
    };
    return state;
};

// 返回需要修改的参数
/**
 * 
 * @param {object} info 需要对比的数据
 * @param {object} mustParams 必须传的参数
 * @param {object} params 需要修改的数据
 */
export const returnUpdateParams = (info, mustParams, params) => {
    let obj = {};
    for (let i in params) {
        if (params[i]) {
            if (params[i] !== info[i]) {
                obj[i] = params[i];
                for (let j in mustParams) {
                    obj[j] = mustParams[j];
                }
            }
        }
    }
    return obj;
}

// 上传数据
export const addData = async (_this, api, rdata, hidenInfo) => {
    try {
        const { data } = await request(api, rdata, 'post');
        if (!hidenInfo) {
            if (data.stateCode === 200) {
                message.success('添加成功');
                _this.getData();
                _this.setState({ visible: false });
                _this.clearState();
            } else {
                message.error('上传内容不能为空!');
            }
        }
    } catch (error) {
        message.error('上传失败,请重试!');
    }
}
// 删除数据
export const deletData = async (_this, rdata, api, id) => {
    const { data } = await request(api, rdata, 'post');
    if (data.data === '删除成功') {
        message.success('删除成功!');
        _this.setState({ dataArr: _this.state.dataArr.filter(item => item[id] !== rdata[id]) });
    } else {
        message.error('删除失败,请重试!');
    }
}


// 获取视频封面
export const getVideoThumb = async (file, _this) => {
    _this.setState({ completet: 0 });
    let userData = null;
    const seesData = window.sessionStorage.getItem('userData');
    if (seesData) {
        userData = JSON.parse(atob(window.sessionStorage.getItem('userData')));
    };
    let config = {
        onUploadProgress: progressEvent => {
            const complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
            _this.setState({ complete: complete });
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            "token": userData !== null ? userData.token : 0,
            "user-id": userData !== null ? userData.mid : 0
        }
    }
    const { data } = await axios.post(BASE_API + uapi.cutVideoThumb, qs.stringify({ filename: file }), config);
    const { filename, url } = data.data;
    _this.setState({ thumbImgUrl: url, thumbFile: filename, complete: null });
}

// 获取地址参数
// export const getParams = (_this) =>{
//     const search = _this.props.history.location.search;
//     const length = search.length - 1;
//     const stra = search.substr(1, length);
//     const arr = stra.split('&');
//     let paramData = {};
//     for(let i=0;i<arr.length;i++){
//         const a = arr[i].indexOf('=');
//         const key = arr[i].substr(0,a);
//         const value = arr[i].substr(a+1, arr[i].length);
//         paramData[key]=value
//     }
//     return paramData;
// }

// 获取地址参数
export const getParams = (_this) => {
    const search = _this.props.history.location.search;
    const length = search.length - 1;
    const stra = search.substr(1, length);
    const arr = stra.split('&');
    let paramData = {};
    for (let i = 0; i < arr.length; i++) {
        const a = arr[i].indexOf('=');
        const key = arr[i].substr(0, a);
        const value = arr[i].substr(a + 1, arr[i].length);
        paramData[key] = value
    }
    return paramData;
}

// 循环替换图片前缀
export const forReplaceName = (data, type) => {
    if (type === 'single') {
        let str = data[0].replace("https://m.muho.tv/", '');
        return str;
    } else {
        let strArr = '';
        for (let i in data) {
            if (i !== '0') {
                strArr += ',' + data[i].replace("https://m.muho.tv/", '');
            } else {
                strArr += data[i].replace("https://m.muho.tv/", '');
            }
        }
        return strArr;
    }
}

/** 根据pageType返回类型 */
export const returnPageType = pageType => {
    let type = '';
    type = pageType === 0 ? 'no' :
        pageType === 1 ? 'star' :
            pageType === 2 ? 'paid' :
                pageType === 3 ? 'hot' :
                    pageType === 4 ? 'teach' : 'web';
    return type;

}


export const setCookie = () => {
    document.cookie = "name = xjx";
}
export const getCookie = () => {
    return document.cookie;
}

export const timestampToTime = (timestamp) => {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var h = date.getHours() + ':';
    var m = date.getMinutes() + ':';
    var s = date.getSeconds();
    return Y + M + D + h + m + s;
}