const base = "/admin";

// 教练后台
const getCoach = base + "/getCoach";// 获取教练列表
const getCoachList = base + '/getCoachList'; //获取教练列表(一次性返回)
const addCoach = base + "/addCoach";// 新增教练
const reviseCoach = base + "/reviseCoach";// 修改教练
const deleteCoach = base + "/deleteCoach";// 删除教练

// 专辑后台
const getTeachingAlbum = base + "/getTeachingAlbum"; //获取教学专辑
const addTeachingAlbum = base + "/addTeachingAlbum"; //增加教学专辑
const reviseTeachingAlbum = base + "/reviseTeachingAlbum"; //修改教学专辑
const deleteTeachingAlbum = base + "/deleteTeachingAlbum"; //删除教学专辑

// 专辑内容后台
const getTeachingAlbumDetail = base + "/getTeachingAlbumDetail"; //获取教学专辑内容
const addTeachingAlbumDetail = base + "/addTeachingAlbumDetail"; //增加教学专辑内容
const reviseTeachingAlbumDetail = base + "/reviseTeachingAlbumDetail"; //修改教学专辑内容
const deleteTeachingAlbumDetail = base + "/deleteTeachingAlbumDetail"; //删除教学专辑内容



export default {
    // 教练后台
    getCoach,addCoach,reviseCoach,deleteCoach,getCoachList,
    // 专辑后台
    getTeachingAlbum,addTeachingAlbum,reviseTeachingAlbum,deleteTeachingAlbum,
    // 专辑内容后台
    getTeachingAlbumDetail,addTeachingAlbumDetail,reviseTeachingAlbumDetail,deleteTeachingAlbumDetail
};


