import React, { Component } from 'react';
import { request, publicGetData, clearState, setData, getVideoThumb, returnPageType } from './../../../static/js/utils';
import { WechatOutlined } from "@ant-design/icons";
import apis from './../../../static/js/apis';
import { Table, Modal, Button, Input, Tag, Select, message, Popconfirm, Radio } from "antd";
import AploadImg from '../../components/AploadImg/AploadImg';
import AploadVideo from '../../components/AploadVideo/AploadVideo';
import CascaderList from '../../../components/CascaderList/CascaderList';

const { TextArea } = Input;
const Option = Select.Option;
const api = apis.publicApis;
const wxApi = apis.weChatApis;

class hotVideoPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataArr: [],
            tagsArr: [],
            imgUrl: [],
            uploadImgArr: [],
            isloading: true,
            visible: false,
            isWxMessageShow: false,
            total: 0,
            pindex: -1,
            l: 1,
            pagetype: 0,
            typeVal: '单图',
            cascaderType: 'no',
            pagetext: 'muho',
            videoFileUrl: '',
            videoUrl: '',
            modelTitle: '',
            tags: '',
            wxMessageTitle: '',
            wxMessageInfo: '',
            wxMessageCid: '',
            wxMessageDAte: ''
        }
    }
    // table数据渲染
    columes = [
        {
            title: 'cid',
            dataIndex: 'cid',
            width: 80
        }, {
            title: '标题',
            dataIndex: 'title',
            width: 500,
            render: (text, record) => {
                let type = record.c_type,
                    ctype = '';
                if (type == 1) {
                    ctype = '单图';
                } else {
                    ctype = '多图';
                }
                return (
                    <div>
                        {
                            type == 3 ? <div><a href={record.video} target="_blank">{text}</a><Tag color="magenta">视频</Tag></div> :
                                <div>{text}<Tag color="blue">{ctype}</Tag></div>
                        }
                    </div>
                )
            }
        }, {
            title: '封面图',
            dataIndex: 'thumb',
            render: text => <img src={text} style={{ "width": "150px" }} key={text}></img>
        }, {
            title: '标签',
            dataIndex: 'tags',
            width: 180,
            render(text, record) {
                const list = text.split(',');
                return (
                    <div>
                        {
                            list.map((item, index) => {
                                return <Tag key={index} color="purple">{item}</Tag>
                            })
                        }
                    </div>
                )
            }
        }, {
            title: '上传时间',
            dataIndex: 'update_time',
            width:120
        }, {
            title: '操作',
            dataIndex: 'operation',
            width:120,
            render: (text, record, index) =>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button type='primary' style={{ "display": "block" }} onClick={this.showModal.bind(this, '1', record, index)}>修改</Button>
                        <Popconfirm
                            title="您确定要删除这条数据嘛?"
                            onConfirm={this.deleteBanner.bind(this, record.cid)}
                            okText="确定"
                            cancelText="取消">
                            <Button type='danger' style={{ "display": "block" }}>删除</Button>
                        </Popconfirm>
                    </div>
                ) : null
        }, {
            title: '推送',
            dataIndex: 'push',
            width: 200,
            render: (text, item) => <Button style={{ background: '#62b900', color: '#fff' }} onClick={this.pushWxMessage.bind(this, item)}><WechatOutlined /> 微信消息推送</Button>
        }
    ]

    componentDidMount = () => {
        // 获取初始数据
        this.getData();
    }
    // 获取数据
    getData = () => publicGetData(this.state.l, this, api.getHotspot)
    // 分页
    changePage = async (l) => {
        await this.setState({ l });
        this.getData();
    }
    // 打开修改/新增弹窗
    showModal = (bType, record, index) => {
        let { modelTitle } = this.state;
        if (bType === '1') {
            modelTitle = '修改热点';
            const state = setData(this, record);
            this.setState({
                ...state,
                imgUrl: record.photo,
                pindex: index,
                cascaderType: returnPageType(state['pagetype'])
            });
        }
        else {
            modelTitle = '新增热点';
            this.clearState();
        };
        this.setState({
            visible: true,
            modelTitle: modelTitle,
            bType: bType,
            isClear: false
        })
    }
    okBtn = () => {
        const { bType } = this.state;
        if (bType === '1') { this.upHotData() } else { this.addHot() };
    }
    // 新增热点视频
    addHot = () => {
        const { title, tagsArr, uploadImgArr, videoFileUrl, typeVal, thumbFile, pagedata, cascaderType, pagetype, pagetext } = this.state;
        let cType;
        let rdata = {
            sub_type: 0,
            tags: tagsArr.join(),
            title: title,
            video: videoFileUrl,
            photo: uploadImgArr.join(),
            thumb: thumbFile,
            pagedata: cascaderType === 'no' ? '{}' : pagedata,
            pagedata2: cascaderType === 'no' ? '{}' : pagedata,
            pagetype,
            pagetext: cascaderType === 'no' ? 'muho' : pagetext
        };
        if (typeVal === '单图') {
            rdata['c_type'] = 1;
        } else if (typeVal === '多图') {
            rdata['c_type'] = 2;
        } else {
            rdata['c_type'] = 3;
            rdata['photo'] = thumbFile;
        };

        if (title && tagsArr.length > 0 && typeVal) {
            if (cType == 3) {
                if (videoFileUrl) {
                    this.addEvent(rdata);
                } else {
                    message.error('上传内容不能为空!');
                }
            } else {
                this.addEvent(rdata);
            }
        } else {
            message.error('上传内容不能为空!');
        }
    }
    addEvent = async (rdata) => {
        const { data } = await request(api.uploadHotspot, rdata, 'post');
        if (data.data === "上传成功") {
            message.success('上传成功!');
            this.getData();
            this.setState({ visible: false });
            this.clearState();
        }
    }
    // 更新当前数据
    upHotData = async () => {
        const { cid, dataArr, title, tagsArr, uploadImgArr, videoFileUrl, typeVal, thumbFile, pagedata, cascaderType, pagetype, pagetext } = this.state;
        let cType;
        if (typeVal === '单图') {
            cType = 1;
        } else if (typeVal === '多图') {
            cType = 2;
        } else { cType = 3 };
        const rdata = {
            cid: cid,
            c_type: cType,
            tags: tagsArr.join(),
            title: title,
            thumb: thumbFile,
            photo: uploadImgArr.join(),
            pagedata: cascaderType === 'no' ? '{}' : pagedata,
            pagedata2: cascaderType === 'no' ? '{}' : pagedata,
            pagetype,
            pagetext: cascaderType === 'no' ? 'muho' : pagetext,
        };
        if (videoFileUrl) {
            rdata['video'] = videoFileUrl;
        }
        const { data } = await request(api.reviseHotspot, rdata, 'post');
        if (data.data === "修改成功") {
            this.setState({ visible: false, dataArr: dataArr });
            this.getData();
            this.clearState();
            message.success('修改成功!');
        }
    }
    // 删除当前数据
    deleteBanner = async (cid) => {
        const { data } = await request(api.deleteHotspot, { cid: cid }, 'post');
        if (data.data === '删除成功') {
            message.success('删除成功!');
            this.setState({ dataArr: this.state.dataArr.filter(item => item.cid !== cid) });
        } else {
            message.error('删除失败,请重试!');
        }
    }
    // 关闭弹窗
    closeModal = () => {
        this.setState({
            visible: false,
        })
        this.clearState();
    }

    /**
     * 打开微信推送弹框
     * @param {Object} item 内容
     */
    pushWxMessage = async (item) => {
        let { title, update_time, cid } = item;
        title = JSON.stringify(title).replace(/\s*/g, "");
        title = title.slice(1, title.length - 1);
        this.setState({
            wxMessageTitle: title.length > 20 ? `${title.slice(0, 17)}...` : title,
            wxMessageCid: cid,
            wxMessageDAte: update_time,
            isWxMessageShow: true
        });
    }

    /**
     * 微信订阅消息推送
     */
    sendWxMessage = async () => {
        const { wxMessageInfo, wxMessageTitle, wxMessageCid, wxMessageDAte } = this.state;
        const json = {
            thing1: {
                value: wxMessageTitle,
            }, thing2: {
                value: wxMessageInfo ? wxMessageInfo.replace(/\s*/g, "") : wxMessageTitle
            }, time3: {
                value: wxMessageDAte
            }
        };
        const data = {
            template_id: "WbfOMC69bISkCrb7bno_KzFUOmyVoWQdmyLyXoPAD_s",
            page: `/pages/hot/hotMain/hotMain?cid=${wxMessageCid}`,
            type: "formal",
            data_json: JSON.stringify(json)
        };
        try {
            const res = await request(wxApi.sendWxMessages, data, "post");
            const { error, success } = res.data.data;
            message.success(`共推送成功${success}条,推送失败${error}条`);
            this.setState({ isWxMessageShow: false, wxMessageCid: '', wxMessageDAte: '', wxMessageInfo: '', wxMessageTitle: '' });
        } catch (error) {
            message.error(`推送失败,${error}`);
        }
    }
    // 获取热点类型
    getCtype = (e) => {
        this.setState({ typeVal: e });
        const { imgUrl } = this.state;
        if (imgUrl.length > 0) {
            if (e === '单图' || e === '视频') {
                this.setState({
                    imgUrl: this.state.imgUrl.shift().split(',')
                });
            } else {
                this.setState({
                    imgUrl: this.state.imgUrl
                });
            };
        }
    }
    // 获取标题内容
    titleChange = (e) => {
        this.setState({ title: e.target.value });
    }
    // 添加标签
    addTags = (e) => {
        let { tagsArr } = this.state;
        const tag = e.target.value;
        if (tag && tagsArr.indexOf(tag) === -1) {
            tagsArr = tagsArr.concat(tag);
        };
        this.setState({ tagsArr: tagsArr, tags: '' });
    }
    // 删除标签
    deleTags = (e) => {
        this.setState({ tagsArr: this.state.tagsArr.filter(item => item !== e) });
    }
    // 获取图片地址
    getImgUrl = (file, url) => {
        let { uploadImgArr, imgUrl, typeVal, thumbFile } = this.state;
        if (typeVal === '多图') {
            imgUrl.push(url);
            uploadImgArr.push(file);
            thumbFile = [file][0]
        } else {
            imgUrl = [url]
            this.setState({ imgUrl: url.split(',') });
            uploadImgArr = [file];
            thumbFile = [file][0]
        }
        this.setState({ uploadImgArr: uploadImgArr, imgUrl: imgUrl, thumbFile: thumbFile })
    }
    // 删除图片
    deleImg = (index) => {
        let { imgUrl, uploadImgArr } = this.state;
        imgUrl.splice(index, 1);
        uploadImgArr.splice(index, 1);
        this.setState({ imgUrl: imgUrl, uploadImgArr: uploadImgArr });
    }

    // 获取封面图片
    getThumbUrl = (file, url) => {
        this.setState({ thumbFile: file, thumbImgUrl: url });
    }
    // 删除封面图片
    deleThumbImg = () => {
        this.setState({ thumbFile: '', thumbImgUrl: '' });
    }
    // 获取视频地址
    getVidUrl = (file, url) => {
        this.setState({
            videoUrl: url,
            videoFileUrl: file
        })
        getVideoThumb(file, this);
    }
    // 删除视频
    deleVideo = () => {
        this.setState({
            videoUrl: '',
            videoFileUrl: ''
        })
    }

    // 数据初始化
    clearState = () => {
        this.setState({
            typeVal: '单图',
            cascaderType: 'no',
            isClear: true,
            pagetype: 0,
            pagetext: 'muho'
        })
        clearState(this, ['title', 'tags', 'videoUrl', 'videoFileUrl', 'thumbImgUrl', 'thumbFile'], 'str');
        clearState(this, ['cid'], 'num');
        clearState(this, ['tagsArr', 'imgUrl', 'uploadImgArr'], 'arr');
    }
    render() {
        const { dataArr, total, isloading, modelTitle, visible, bType, isWxMessageShow, wxMessageInfo, wxMessageTitle } = this.state;
        const { cid, typeVal, title, tagsArr, tags, imgUrl, videoUrl, complete, pagetype, cascaderType, isClear, data, pagetext, l } = this.state;
        let { thumbImgUrl } = this.state;
        if (thumbImgUrl) { thumbImgUrl = thumbImgUrl.split(','); } else { thumbImgUrl = [] };
        return (
            <div>
                <h1 style={{ display: 'inline-block' }}>热点列表后台</h1>
                <Button type='primary' btype="2" style={{ "display": "block", 'float': 'right', 'zIndex': '999' }} onClick={this.showModal}>新增</Button>
                <Table
                    loading={isloading}
                    rowKey={record => record.cid}
                    columns={this.columes}
                    dataSource={dataArr}
                    scroll={{ x: 1400 }}
                    pagination={{
                        total: total,
                        defaultPageSize: 8,
                        onChange: this.changePage,
                        current: l
                    }}
                    onRow={(record, index) => {
                        const cType = record.c_type;
                        let typeVal;
                        let imgUrl = [];
                        let uploadImgArr = [];
                        Object.assign(imgUrl, record.photo);
                        if (cType == 1) { typeVal = '单图' } else if (cType == 2) { typeVal = '多图' } else { typeVal = '视频' }
                        for (let i in imgUrl) {
                            uploadImgArr.push(imgUrl[i].slice('18'));
                        };
                        return {
                            onClick: () => {
                                this.setState({
                                    typeVal: typeVal,
                                    uploadImgArr: uploadImgArr
                                })
                            }
                        }
                    }}></Table>
                <Modal
                    width={typeVal === '多图' ? '80%' : '35%'}
                    title={modelTitle}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}>
                    {
                        bType === '1' ?
                            <div className="inputBox clearfix">
                                <div className="inputName">cid</div>
                                <Input id="cid" style={{ width: '20%' }} type="text" value={cid} disabled />
                            </div> : null
                    }
                    <div className="inputBox clearfix">
                        <div className="inputName">类别</div>
                        <Select value={typeVal} style={{ width: '30%' }} onChange={this.getCtype}>
                            <Option value='单图'>单图</Option>
                            <Option value='多图'>多图</Option>
                            <Option value='视频'>视频</Option>
                        </Select>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">标题</div>
                        <TextArea style={{ width: '70%' }} id="title" placeholder="请输入热点标题" type="text" value={title} onChange={this.titleChange} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">标签</div>
                        <div style={{ width: '40%' }}>
                            <Input
                                style={{ display: 'block' }}
                                id="tags"
                                type="text"
                                autoComplete="off"
                                value={tags}
                                placeholder="请选择标签确认则回车"
                                onChange={(e) => this.setState({ tags: e.target.value })}
                                onPressEnter={this.addTags} />
                            <div>
                                {
                                    tagsArr ?
                                        tagsArr.map((item, index) => {
                                            return (
                                                <Tag key={index} closable onClose={(e) => { e.preventDefault(); this.deleTags(item); }}>{item}</Tag>
                                            )
                                        }) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="inputBox clearfix">
                        <div className="inputName">页面类型</div>
                        <Radio.Group
                            style={{ width: '60%' }}
                            buttonStyle="solid"
                            value={pagetype}
                            onChange={(e) => this.setState({
                                pagetype: e.target.value,
                                cascaderType: e.target.listType,
                                pagetext: bType !== '1' ? e.target.value === 0 ? 'muho' : '点击查看完整版' : this.state.pagetext,
                                pagedata: '',
                            })}
                        >

                            <Radio.Button value={0} listType='no'>无</Radio.Button>
                            <Radio.Button value={1} listType='star'>球星</Radio.Button>
                            <Radio.Button value={2} listType='paid'>付费教学</Radio.Button>
                            <Radio.Button value={4} listType='teach'>普通教学</Radio.Button>
                            <Radio.Button value={6} listType='web'>网页</Radio.Button>
                        </Radio.Group>
                    </div>
                    {
                        cascaderType === 'no' ? null :
                            cascaderType === 'web' ?
                                <div className="inputBox clearfix">
                                    <div className="inputName">地址参数</div>
                                    <Input style={{ width: '60%' }} placeholder='需要跳转的网页地址的参数' type="text" value={data} onChange={(e) => this.setState({ data: e.target.value })} ></Input>
                                </div>
                                :
                                <div className="inputBox clearfix">
                                    <div className="inputName">
                                        <p> 内容选择 </p>
                                    </div>
                                    <div style={{ width: '70%' }}>
                                        <CascaderList
                                            isfree={0}
                                            isClear={isClear}
                                            listType={cascaderType}
                                            onGetState={e => this.setState({ pagedata: JSON.stringify(e) })}
                                        />
                                    </div>
                                </div>
                    }

                    {
                        cascaderType === 'no' ? null :
                            <div className="inputBox clearfix">
                                <div className="inputName">跳转按钮文案</div>
                                <Input style={{ width: '40%' }} type="text" value={pagetext} onChange={e => this.setState({ pagetext: e.target.value })} />
                            </div>
                    }

                    {
                        typeVal === '视频' ?
                            <div>
                                <div className="inputBox clearfix">
                                    <div className="inputName">视频</div>
                                    <AploadVideo
                                        videoUrl={videoUrl}
                                        deleVideo={this.deleVideo}
                                        getVidUrl={this.getVidUrl}
                                    ></AploadVideo>
                                </div>
                                <div className="inputBox clearfix">
                                    <div className="inputName">
                                        <p> 封面 </p>
                                        <p> (自动截取视频封面,可自行选择) </p>
                                    </div>
                                    <AploadImg
                                        deleImg={this.deleThumbImg}
                                        imgUrl={thumbImgUrl}
                                        mulType={false}
                                        getImgUrl={this.getThumbUrl}
                                        completet={complete}
                                    />
                                </div>

                            </div>
                            : null
                    }
                    {
                        typeVal !== '视频' ?
                            <div className="inputBox clearfix">
                                <div className="inputName">图片</div>
                                <AploadImg
                                    deleImg={this.deleImg}
                                    imgUrl={imgUrl}
                                    mulType={typeVal === '多图' ? true : false}
                                    getImgUrl={this.getImgUrl}></AploadImg>
                            </div> : null

                    }
                </Modal>

                <Modal
                    title="微信推送消息"
                    visible={isWxMessageShow}
                    okText="确认推送"
                    cancelText="取消推送"
                    onOk={this.sendWxMessage}
                    onCancel={() => this.setState({ isWxMessageShow: false, wxMessageInfo: '', wxMessageTitle: '', wxMessageCid: '', wxMessageDAte: '' })}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">视频标题</div>
                        <Input style={{ width: '80%' }} maxLength={20} id="title" placeholder="输入推送内容的标题,最大字数不超过20个" type="text" value={wxMessageTitle} onInput={e => this.setState({ wxMessageTitle: e.target.value })} />
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">视频描述</div>
                        <Input style={{ width: '80%' }} maxLength={20} id="title" placeholder="可输入当前上传的集数与标题或直接复制标题内容" type="text" value={wxMessageInfo} onChange={e => this.setState({ wxMessageInfo: e.target.value })} />
                    </div>
                    <p style={{ color: '#ff4d4f', fontWeight: 'bolder' }}>注:1.最大字数限制20,中间不要留有空格,如字数超过20,则自动获取前17位的字并拼接...</p>
                    <p style={{ color: '#ff4d4f', fontWeight: 'bolder' }}>2.如推送的内容为专辑单集的话,视频描述内容最好是当前单集的集数与标题.</p>
                    <p style={{ color: '#ff4d4f', fontWeight: 'bolder' }}>3.如视频描述为空的话，推送时默认为视频标题的内容。(适用于球星采访类集锦类等视频)</p>

                </Modal>
            </div>
        )
    }
}

export default hotVideoPage;