import albumDetailPage from "../../../pages/teachPage/albumDetailPage/albumDetailPage";
import albumPage from "../../../pages/teachPage/albumPage/albumPage";
import coachPage from "../../../pages/teachPage/coachPage/coachPage";

const teachRouter = [
    {
        path: '/coach', component: coachPage, auth: true
    }, {
        path: '/album', component: albumPage, auth: true
    }, {
        path: '/albumDetail', component: albumDetailPage, auth: true
    },
];

export default teachRouter;