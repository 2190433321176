import React, {Component} from 'react';
import {request, publicGetData, clearState, setData, getVideoThumb, returnPageType} from './../../../static/js/utils';
import apis from './../../../static/js/apis';
import { Table ,Modal,Button,Input ,Tag,message, Popconfirm, Radio} from "antd";
import AploadImg from '../../components/AploadImg/AploadImg';
import AploadVideo from '../../components/AploadVideo/AploadVideo';
import CascaderList from '../../../components/CascaderList/CascaderList';
const {TextArea} = Input;
const api = apis.publicApis;

class followPage extends Component{

    constructor(props){
        super(props);
        this.state={
            isloading:false,
            visible:false,
            modelTitle:'',
            title:'',
            tags:'',
            thumbImgUrl:'',
            thumbFile:'',
            videoUrl:'',
            videoFileUrl:'',
            tagsArr:[],
            cid:0,
            pindex:-1,
            cascaderType:'no',
            xurl:'/starPages/pages/starViews/starViews',
            page_type:0,
            l:1,
            pagedata:'',
            pagetext:'点击查看完整版',
            isClear:false
        }
    }

    columes = [
        {
            title:'cid',
            dataIndex:'cid',
            width: 100, 
        },{
            title:'标题',
            dataIndex:'title',
            width:400,
            render(text,record){
                return(
                    <a href={record.video} target="_blank">{text}</a>
                )
            }
        },{
            title:'标签',
            dataIndex:'tags',
            width: 180, 
            render(text,record){
                const list = text.split(',')
                return(
                    <div>
                        {
                            list.map((item,index)=>{
                                return <Tag key={index} color="purple">{item}</Tag>
                            })
                        }
                    </div>
                )
            }
        },{
            title:'封面图',
            dataIndex:'thumb',
            render:text=><img src={text} style={{"width":"200px"}} key={text}></img>
        },{
            title:'操作',
            dataIndex:'operation',
            width: 100,
            render:(text, record, index)=>
                this.state.dataArr.length >= 1 ? (
                    <div>
                        <Button 
                            type='primary' 
                            style={{"display":"block"}} 
                            onClick={this.showModal.bind(this, '1', record, index)}>修改</Button>
                        <Button type='danger' style={{"display":"block"}} onClick={this.deletData.bind(this, record.cid)}>删除</Button> 
                    </div>
                ) : null
        }
    ]
    componentDidMount=()=>{
        // 获取初始数据
        this.getData();
    }
    getData= async ()=>{
        const {l} = this.state;
        publicGetData(l, this, api.getFollowOrEvents, {type:1});
    }
    // 分页
    changePage = async (l) =>{
        await this.setState({ l });
        this.getData();
    }
    // 打开修改/新增弹窗
    showModal=(bType, record, index )=>{
        let {modelTitle} = this.state;
        if(bType === '1'){ 
            modelTitle = '修改跟拍视频数据';
            const state = setData(this, record);
            this.setState({
                ...state,
                pindex:index,
                cascaderType:returnPageType(state['page_type'])
            });
        }
        else{ 
            modelTitle = '新增跟拍数据';
            this.clearState();
        };
        this.setState({
            visible : true,
            modelTitle:modelTitle,
            bType:bType,
            isClear:false
        })
    }
    // 关闭弹窗
    closeModal=()=>{
        this.setState({
            visible:false,
        })
        this.clearState();
    }
    okBtn=()=>{
        const {bType} = this.state;
        if(bType === '1'){ this.upData()}else{this.addData()};
    }
    // 新增热点视频
    addData = async ()=>{
        const {title, tagsArr, thumbFile, videoFileUrl, pagedata, pagetext, page_type, cascaderType} = this.state;
        const rdata = {
            c_type:3,
            sub_type: 1,
            pagedata : cascaderType === 'no' ? '{}' : pagedata,
            pagedata2 : cascaderType === 'no' ? '{}' : pagedata,
            pagetype:page_type,
            pagetext : cascaderType === 'no' ? 'muho' : pagetext,
            tags:tagsArr.join(),
            title:title,
            video:videoFileUrl,
            download:videoFileUrl,
            photo:thumbFile,
            thumb:thumbFile,
        };
        if(videoFileUrl && thumbFile){
            try {
                const {data} = await request(api.uploadHotspot, rdata, 'post');
                if(data.data === "上传成功"){
                    message.success('添加成功');
                    this.getData();
                    this.setState({visible:false});
                    this.clearState();
                }else{
                    message.error('上传内容不能为空!');
                }
            } catch (error) {
                message.error('上传失败,请重试!');
            }
        }else{
            message.error('上传内容不能为空!');
        }

    }
    // 修改数据
    upData= async ()=>{
        const {cid, title, tagsArr, thumbFile, videoFileUrl, pagedata, pagetext, page_type, cascaderType} = this.state;
        let rdata = {
            c_type:3,
            cid:cid,
            tags:tagsArr.join(),
            title:title,
            photo:thumbFile,
            thumb:thumbFile,
            pagedata : cascaderType === 'no' ? '{}' : pagedata,
            pagedata2 : cascaderType === 'no' ? '{}' : pagedata,
            pagetype:page_type,
            pagetext : cascaderType === 'no' ? 'muho' : pagetext,
        };
        if(videoFileUrl){
            rdata['video'] = videoFileUrl;
            rdata['download'] = videoFileUrl;
        }
        const {data} = await request(api.reviseHotspot, rdata, 'post');
        if(data.data === "修改成功"){
            message.success('修改成功!');
            this.getData();
            this.setState({ visible:false});
            this.clearState();
        }
    }
    // 删除数据
    deletData= async (cid)=>{
        const {data} = await request(api.deleteHotspot, {cid:cid}, 'post');
        if(data.data === '删除成功'){
            message.success('删除成功!');
            this.setState({dataArr:this.state.dataArr.filter(item => item.cid !== cid)});
        }else{
            message.error('删除失败,请重试!');
        }
    }
    // 获取标题内容
    titleChange=(e)=>{
        this.setState({ title:e.target.value });
    }
    // 添加标签
    addTags=(e)=>{
        let {tagsArr} = this.state;
        const tag = e.target.value;
        if(tag && tagsArr.indexOf(tag) === -1){
            tagsArr = tagsArr.concat(tag);
        };
        this.setState({ tagsArr:tagsArr, tags:''});
    }
    // 删除标签
    deleTags=(e)=>{
        this.setState({ tagsArr:this.state.tagsArr.filter(item => item !== e) });
    }
    // 获取上传图片数据
    getImgUrl=(file, url)=>{
        this.setState({thumbFile:file, thumbImgUrl:url});
    }
     // 删除图片
     deleImg=()=>{
        this.setState({thumbImgUrl:'', thumbFile:''});
    }
    // 获取视频地址
    getVidUrl=(file, url)=>{
        this.setState({
            videoUrl:url,
            videoFileUrl:file
        })
        getVideoThumb(file, this);
    }
    // 删除视频
    deleVideo=()=>{
        this.setState({
            videoUrl:'',
            videoFileUrl:''
        })
    }
    // 数据初始化
    clearState=()=>{
        this.setState({
            cascaderType:'no',
            isClear:true,
            pagetype:0,
            pagetext:'点击查看完整版'
        })
        clearState(this, ['title', 'tags', 'videoUrl', 'videoFileUrl', 'thumbImgUrl', 'thumbFile'], 'str');
        clearState(this, ['cid'], 'num');
        clearState(this, ['tagsArr'], 'arr');
    }
    render(){
        const {isloading, total, dataArr, modelTitle, visible, cascaderType, isClear, l, bType } = this.state;
        const { tags, tagsArr, videoUrl, title, complete, pagedata, page_type, pagetext } = this.state;
        let {thumbImgUrl} = this.state;
        if(thumbImgUrl){  thumbImgUrl = thumbImgUrl.split(','); }else{ thumbImgUrl = []};
        return(
            <div>
                <h1 style={{display:'inline-block'}}>跟拍视频列表后台</h1>
                <Button type='primary' btype="2" style={{"display":"block",'float':'right','zIndex':'999'}} onClick={this.showModal}>新增</Button>
                <Table
                    isloading={isloading}
                    rowKey={record=>record.cid}
                    columns={this.columes}
                    dataSource={dataArr}
                    scroll={{x:1200}}
                    pagination={{
                        current:l,
                        total:total,
                        defaultPageSize:8,
                        onChange:this.changePage
                    }}
                ></Table>
                <Modal
                    width='35%'
                    title={modelTitle}
                    centered={true}
                    visible={visible}
                    onOk={this.okBtn}
                    onCancel={this.closeModal}
                >
                    <div className="inputBox clearfix">
                        <div className="inputName">标题</div>
                        <TextArea style={{ width: '70%' }} id="title" type="text" placeholder="请选择标题" value={title} onChange={this.titleChange}/>
                    </div>

                    <div className="inputBox clearfix">
                        <div className="inputName">标签</div>
                        <div style={{width:'40%'}}>
                            <Input  
                                style={{display:'block'}}
                                id="tags" 
                                type="text" 
                                autoComplete="off"
                                value={tags}
                                placeholder="请选择标签确认则回车"
                                onChange={(e)=>this.setState({tags:e.target.value})}
                                onPressEnter={this.addTags} />
                            <div>
                                {
                                    tagsArr ? 
                                    tagsArr.map((item, index)=>{
                                        return(
                                            <Tag key={index} closable onClose={(e)=>{e.preventDefault();this.deleTags(item);}}>{item}</Tag>
                                        )
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="inputBox clearfix">
                            <div className="inputName">页面类型</div>
                            <Radio.Group 
                                style={{width:'60%'}} 
                                buttonStyle="solid" 
                                value={page_type} 
                                onChange={(e)=>this.setState({ 
                                    page_type : e.target.value, 
                                    cascaderType:e.target.listType,
                                    pagetext: bType !== '1' ? e.target.value === 0 ? 'muho' : '点击查看完整版' : this.state.pagetext ,
                                    pagedata:'',
                                })}
                            >
                                <Radio.Button value={0} listType='no'>无</Radio.Button>
                                <Radio.Button value={1} listType='star'>球星</Radio.Button>
                                <Radio.Button value={2} listType='paid'>付费教学</Radio.Button>
                                <Radio.Button value={4} listType='teach'>普通教学</Radio.Button>
                                <Radio.Button value={6} listType='web'>网页</Radio.Button>
                            </Radio.Group>
                        </div>
                        {
                            cascaderType === 'no' ? null :
                            cascaderType === 'web' ? 
                            <div className="inputBox clearfix">
                                <div className="inputName">地址参数</div>
                                <Input style={{width:'60%'}} type="text" value={pagedata} onChange={(e)=>this.setState({data:e.target.value})} ></Input>
                            </div>
                            : 
                            <div className="inputBox clearfix">
                            <div className="inputName">
                                    <p> 内容选择 </p>
                                    <p> (如果选择的是球星类型,请选择一个会员视频,一个预告视频) </p>
                                </div>
                                <div style={{width:'70%'}}>
                                    <CascaderList
                                        isfree = {0}
                                        isClear={isClear}
                                        listType={cascaderType}
                                        onGetState={e=>this.setState({ pagedata:JSON.stringify(e)})}
                                    />
                                </div>
                            </div>
                        }
                        {
                            cascaderType === 'no' ? null : 
                            <div className="inputBox clearfix">
                                <div className="inputName">跳转按钮文案</div>
                                <Input style={{width:'40%'}} type="text" value={pagetext} onChange={e=>this.setState({ pagetext : e.target.value})} />
                            </div>
                        }
                
                    <div className="inputBox clearfix">
                        <div className="inputName">视频</div>
                        <AploadVideo
                            videoUrl = {videoUrl}
                            deleVideo = {this.deleVideo}
                            getVidUrl={this.getVidUrl}
                        ></AploadVideo>
                    </div>
                    <div className="inputBox clearfix">
                        <div className="inputName">
                            <p> 封面 </p>
                            <p> (自动截取视频封面,可自行选择) </p>
                        </div>
                        <AploadImg 
                            deleImg = {this.deleImg}
                            imgUrl={thumbImgUrl} 
                            mulType={false}
                            getImgUrl={this.getImgUrl}
                            completet = {complete}  />
                    </div> 
                    

                </Modal>
            </div>
        )
    }
}
export default followPage;